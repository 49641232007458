import { PrivacyDto } from './privacy.dto';
import { PrivacyPolicyTypes, SET_PRIVACY_DATA } from './privacy.types';

const initialState: PrivacyDto = {};

export default (state = initialState, action: PrivacyPolicyTypes): PrivacyDto => {
  switch (action.type) {
    case SET_PRIVACY_DATA: {
      return { ...state, description: action.payload };
    }
    default: {
      return state;
    }
  }
};
