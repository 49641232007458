import { RankingDTO, RankingTimelineStepDTO } from './ranking-profile.dto';

export const LOAD_RANKING = 'LOAD_RANKING';
export const CLEAR_CURRENT_RANKING = 'CLEAR_CURRENT_RANKING';
export const SET_RANKING = 'SET_RANKING';
export const LOAD_RANKING_PARTICIPATION_CRITERIA = 'LOAD_RANKING_PARTICIPATION_CRITERIA';
export const SET_RANKING_PARTICIPATION_CRITERIA = 'SET_RANKING_PARTICIPATION_CRITERIA';
export const LOAD_RANKING_INSTRUCTIONS = 'LOAD_RANKING_INSTRUCTIONS';
export const SET_RANKING_INSTRUCTIONS = 'SET_RANKING_INSTRUCTIONS';
export const LOAD_RANKING_TIMELINE = 'LOAD_RANKING_TIMELINE';
export const SET_RANKING_TIMELINE = 'SET_RANKING_TIMELINE';

export interface RankingProfileRouteParams {
  id: string;
}

export interface LoadRankingAction {
  type: typeof LOAD_RANKING;
  payload: { id: number };
}

export interface ClearCurrentRankingAction {
  type: typeof CLEAR_CURRENT_RANKING;
}

export interface SetRankingAction {
  type: typeof SET_RANKING;
  payload: { data: RankingDTO };
}

export interface LoadRankingParticipationCriteriaAction {
  type: typeof LOAD_RANKING_PARTICIPATION_CRITERIA;
  payload: { id: number };
}

export interface SetRankingParticipationCriteriaAction {
  type: typeof SET_RANKING_PARTICIPATION_CRITERIA;
  payload: { data: string };
}

export interface LoadRankingInstructionsAction {
  type: typeof LOAD_RANKING_INSTRUCTIONS;
  payload: { id: number };
}

export interface SetRankingInstructionsAction {
  type: typeof SET_RANKING_INSTRUCTIONS;
  payload: { data: string };
}

export interface LoadRankingTimelineAction {
  type: typeof LOAD_RANKING_TIMELINE;
  payload: { id: number };
}

export interface SetRankingTimelineAction {
  type: typeof SET_RANKING_TIMELINE;
  payload: { data: RankingTimelineStepDTO[] };
}

export type RankingActionTypes =
  | ClearCurrentRankingAction
  | LoadRankingAction
  | SetRankingAction
  | LoadRankingParticipationCriteriaAction
  | SetRankingParticipationCriteriaAction
  | LoadRankingInstructionsAction
  | SetRankingInstructionsAction
  | LoadRankingTimelineAction
  | SetRankingTimelineAction;
