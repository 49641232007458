import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';

import { CHANGE_FORGOTTEN_PASSWORD_FORM_CONFIG } from './ChangeForgottenPassword.constants';
import { changeForgottenPasswordAction } from '../../auth.actions';
import { ChangeForgottenPasswordForm } from '../../auth.dto';
import { CHANGE_FORGOTTEN_PASSWORD } from '../../auth.types';
import Button, { ButtonSizes, ButtonTypes } from '../../../../../components/Button';
import Grid from '../../../../../components/Grid';
import Form, { FormErrors } from '../../../../../components/Form';
import Typography, { TypographyVariants } from '../../../../../components/Typography';
import { selectRequestFormErrors, selectRequestIsLoading } from '../../../../../shared/state/global-request';

const ChangeForgottenPassword: FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const formErrors = useSelector(selectRequestFormErrors(CHANGE_FORGOTTEN_PASSWORD));
  const isLoading = useSelector(selectRequestIsLoading(CHANGE_FORGOTTEN_PASSWORD));
  const [isRequestSent, setIsRequestSent] = useState(false);

  const [formData] = useState({});

  const { token }: { token: string } = useParams();

  useEffect(() => {
    if (isRequestSent && !isLoading) {
      if (Object.keys(formErrors).length === 0) {
        history.push('/auth/login');
      }
    }
  }, [formErrors, isRequestSent, isLoading, history]);

  const handleFormSubmit = (values: ChangeForgottenPasswordForm): void => {
    const data = {
      ...values,
      token,
    };

    dispatch(changeForgottenPasswordAction(data));
    setIsRequestSent(true);
  };

  const formatValidationErrors = (formErrors: FormErrors): FormErrors => {
    const formattedErrors = { ...formErrors };
    if (formattedErrors && formattedErrors.confirmPassword) {
      formattedErrors.confirmPassword = ['Confirm password does not match the password!'];
    }
    return formattedErrors;
  };

  return (
    <Grid container className="mba-auth-form-wrapper">
      <Grid item xs={11} sm={9} md={7} lg={5} center className="mba-auth-form">
        <Typography component="p" variant={TypographyVariants.h3} className="mba-auth-heading">
          Change your password
        </Typography>
        <Form
          id="forgotten-password-change-form"
          config={CHANGE_FORGOTTEN_PASSWORD_FORM_CONFIG}
          errors={formatValidationErrors(formErrors)}
          onSubmit={handleFormSubmit}
          initialValues={formData}
          isLoading={isLoading}
          customActions={
            <div className="mba-auth-submit-button">
              <Button primary fullWidth type={ButtonTypes.submit} size={ButtonSizes.big} text="Submit" />
            </div>
          }
        />
      </Grid>
    </Grid>
  );
};

export default ChangeForgottenPassword;
