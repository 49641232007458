import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import TimelineIndicator, { TimelineStepStatus } from './TimelineIndicator';
import TimelineStepDates from './TimelineStepDates';
import {
  ParticipationStatus,
  RankingTimelineStepBaseProps,
  TimelineExpandStepStatus,
} from '../RankingTimeline.constants';
import { updateParticipationAction } from '../ranking-timeline.actions';
import Button from '../../../../components/Button';
import Grid from '../../../../components/Grid';
import Label, { LabelStatus } from '../../../../components/Label';

const ParticipationStep: FunctionComponent<RankingTimelineStepBaseProps> = ({
  endDate,
  isCompleted,
  name,
  participationStatus,
  rankingId,
  schoolId,
  status,
}) => {
  const dispatch = useDispatch();
  const [expand, setExpand] = useState<boolean>(false);
  let timelineStatus: TimelineStepStatus = status;

  useEffect(() => {
    setExpand(TimelineExpandStepStatus[status]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  if (status === TimelineStepStatus.completed && !isCompleted) {
    timelineStatus = TimelineStepStatus.skipped;
  }

  const changeParticipationStatus = (newStatus: ParticipationStatus): void => {
    dispatch(updateParticipationAction(rankingId, schoolId, newStatus));
  };

  let participationAction: ReactNode;
  const isActionDisabled = status === TimelineStepStatus.completed || status === TimelineStepStatus.upcoming;

  switch (participationStatus?.key) {
    case ParticipationStatus.confirmed: {
      participationAction = (
        <Button
          text="Withdraw participation"
          disabled={isActionDisabled}
          onClick={(): void => changeParticipationStatus(ParticipationStatus.declined)}
        />
      );
      break;
    }
    case ParticipationStatus.declined: {
      participationAction = (
        <Button
          text="Confirm participation"
          disabled={isActionDisabled}
          onClick={(): void => changeParticipationStatus(ParticipationStatus.confirmed)}
        />
      );
      break;
    }
    case ParticipationStatus.pending: {
      participationAction = (
        <Button
          primary
          text="Confirm participation"
          disabled={isActionDisabled}
          onClick={(): void => changeParticipationStatus(ParticipationStatus.confirmed)}
        />
      );
      break;
    }
    case ParticipationStatus.rejected: {
      participationAction = (
        <p>
          You have been rejected from the current ranking!
          <br />
          Contact the FT team for more details.
        </p>
      );
      break;
    }
    default: {
      participationAction = null;
    }
  }

  return (
    <Grid container key={name}>
      <Grid item xs={5}>
        <TimelineStepDates endDate={endDate} endDateLabel="Deadline" isEndDateRequired={true} />
      </Grid>
      <Grid item xs={1} className="mba-no-padding">
        <TimelineIndicator timeIndication={timelineStatus} isCompleted={isCompleted} />
      </Grid>
      <Grid item xs={6} className="mba-margin-bottom-20 mba-no-padding">
        <button
          className="mba-text--accent mba-no-margin mba-text--bold mba-timeline-button--link mba-margin-bottom-15"
          onClick={(): void => setExpand(!expand)}
        >
          {name}
          {' - '}
          {participationStatus && (
            <Label color={participationStatus.color} status={LabelStatus.active}>
              {participationStatus.label}
            </Label>
          )}
        </button>
        <br />
        {expand && (
          <>
            <Link to={`/rankings/${rankingId}/instructions`}>
              <span className="mba-font--16">Read instructions</span>
            </Link>
            <br />
            <Link to={`/rankings/${rankingId}/participation-criteria`}>
              <span className="mba-font--16">Read participation criteria</span>
            </Link>
            <br />
            <br />
            <div className="mba-actions">{participationAction}</div>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default ParticipationStep;
