import { ChangeEventHandler, FocusEventHandler, ReactNode } from 'react';
import { InputWrapperProps } from '../InputWrapper';

export enum TextInputTypes {
  text = 'text',
  email = 'email',
  emailPhone = 'email-phone',
  phone = 'phone',
  password = 'password',
  number = 'number',
}

type TextInputSpecificProps = {
  disabled?: boolean;
  error?: string;
  id?: string;
  name?: string;
  onBlur?: FocusEventHandler;
  onChange?: ChangeEventHandler;
  placeholder?: string;
  required?: boolean;
  small?: boolean;
  suffix?: ReactNode;
  touched?: boolean;
  type?: TextInputTypes;
  valid?: boolean;
  value?: string | number;
  pattern?: string;
};

export type TextInputProps = TextInputSpecificProps & InputWrapperProps;
