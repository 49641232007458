import { push } from 'react-router-redux';
import { call, CallEffect, put, PutEffect, select, SelectEffect, takeEvery, takeLatest } from 'redux-saga/effects';

import { setTermsStatusAction, setUserToken } from './auth.actions';
import {
  acceptTermsForSchoolRequest,
  changePasswordRequest,
  updateProfileRequest,
  loginSchoolUserRequest,
  registerSchoolUserRequest,
  resetPasswordChangeRequest,
  resetPasswordRequest,
  refreshTokenRequest,
} from './auth.api';
import { SchoolUserDTO } from './auth.dto';
import {
  ACCEPT_TERMS,
  AcceptTermsForSchoolAction,
  AuthActionTypes,
  CHANGE_FORGOTTEN_PASSWORD,
  CHANGE_PASSWORD,
  ChangeForgottenPasswordAction,
  ChangePasswordAction,
  EDIT_PROFILE,
  EditProfileAction,
  LOG_OUT_USER,
  LOGIN_USER,
  LoginUserAction,
  REGISTER_USER,
  RegisterUserAction,
  RESET_PASSWORD,
  ResetPasswordAction,
  SET_USER_TOKEN,
  SetUserTokenAction,
  VALIDATE_USER_TOKEN,
  ValidateUserTokenAction,
  REFRESH_SCHOOL_USER,
  CHANGE_SCHOOL_ACCOUNT,
  ChangeSchoolAccountAction,
} from './auth.types';
import { selectAuthUser } from './auth.selectors';
import { NavigationConfiguration } from '../Main/components/PortalLayout';
import { SchoolDTO } from '../../SchoolProfile';
import { getSchoolRequest } from '../../SchoolProfile/school-profile.api';
import { updateAxiosBearerToken } from '../../../shared/api';
import {
  BasicResponseDTO,
  CURRENT_SCHOOL_ID,
  DefaultResponseDTO,
  JWT_TOKEN_COOKIE_NAME,
  TERMS_ACCEPTED_STATUS,
} from '../../../shared/constants';
import { deleteCookie, setCookie } from '../../../shared/web-storage';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../../../shared/state/global-request';

function* validateUserToken(
  action: ValidateUserTokenAction,
): Generator<
  | void
  | CallEffect<BasicResponseDTO | DefaultResponseDTO<NavigationConfiguration>>
  | PutEffect<AuthActionTypes | GlobalRequestActions>
  | SelectEffect
> {
  const { token } = action.payload;

  if (token) {
    updateAxiosBearerToken(token);

    try {
      yield put(setRequestStartedAction(VALIDATE_USER_TOKEN));

      const {
        data: { accessToken },
      } = (yield call(refreshTokenRequest)) as { data: { accessToken: string } };
      yield put(setUserToken(accessToken));

      const { schoolId } = (yield select(selectAuthUser)) as SchoolUserDTO;
      const {
        data: { termsStatus },
      } = (yield call(getSchoolRequest, schoolId)) as DefaultResponseDTO<SchoolDTO>;
      yield put(setRequestSucceededAction(VALIDATE_USER_TOKEN));
      yield put(setTermsStatusAction(termsStatus.key === TERMS_ACCEPTED_STATUS));
    } catch (error: any) {
      yield put(setRequestFailedAction(VALIDATE_USER_TOKEN, error));
    }
  }
}

function* setUserTokenInCookie(action: SetUserTokenAction): Generator<void> {
  const token = action.payload.token;

  if (token) {
    setCookie(JWT_TOKEN_COOKIE_NAME, token);
    updateAxiosBearerToken(token as string);
  }
}

function* resetUserPasswordSaga(
  action: ResetPasswordAction,
): Generator<CallEffect<BasicResponseDTO> | PutEffect<GlobalRequestActions>> {
  const {
    type: actionType,
    payload: { email },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(resetPasswordRequest, email);
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* changeForgottenPasswordSaga(
  action: ChangeForgottenPasswordAction,
): Generator<CallEffect<BasicResponseDTO> | PutEffect<GlobalRequestActions>> {
  const { type: actionType, payload: requestData } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(resetPasswordChangeRequest, requestData);
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* changePasswordSaga(
  action: ChangePasswordAction,
): Generator<CallEffect<BasicResponseDTO> | PutEffect<GlobalRequestActions>> {
  const { type: actionType, payload: requestData } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(changePasswordRequest, requestData);
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* updateProfileSaga(
  action: EditProfileAction,
): Generator<CallEffect<BasicResponseDTO> | PutEffect<GlobalRequestActions | AuthActionTypes> | SelectEffect> {
  const { type: actionType, payload: requestData } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(updateProfileRequest, requestData);
    const { data } = (yield call(refreshTokenRequest)) as { data: { accessToken: string } };
    const { accessToken } = data;
    yield put(setUserToken(accessToken));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* deleteUserToken(): Generator<void | PutEffect> {
  deleteCookie(JWT_TOKEN_COOKIE_NAME);
  updateAxiosBearerToken('');
  yield put(push('/auth/login'));
}

function* loginUser(
  action: LoginUserAction,
): Generator<CallEffect<BasicResponseDTO> | PutEffect<GlobalRequestActions | AuthActionTypes> | SelectEffect> {
  const { type: actionType, payload: loginData } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { data } = (yield call(loginSchoolUserRequest, loginData)) as { data: { accessToken: string } };
    const { accessToken } = data;
    yield put(setUserToken(accessToken));
    const { schoolId } = (yield select(selectAuthUser)) as SchoolUserDTO;
    const {
      data: { termsStatus },
    } = (yield call(getSchoolRequest, schoolId)) as DefaultResponseDTO<SchoolDTO>;
    yield put(setTermsStatusAction(termsStatus.key === TERMS_ACCEPTED_STATUS));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* registerUser(
  action: RegisterUserAction,
): Generator<CallEffect<BasicResponseDTO> | PutEffect<GlobalRequestActions>> {
  const { type: actionType, payload: registerData } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(registerSchoolUserRequest, registerData);
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* refreshSchoolUserSaga(
  action: RegisterUserAction,
): Generator<CallEffect<BasicResponseDTO> | PutEffect<GlobalRequestActions | AuthActionTypes> | SelectEffect> {
  const { type: actionType } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const {
      data: { accessToken },
    } = (yield call(refreshTokenRequest)) as { data: { accessToken: string } };
    yield put(setUserToken(accessToken));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* acceptTermsSaga(
  action: AcceptTermsForSchoolAction,
): Generator<CallEffect<BasicResponseDTO> | PutEffect<GlobalRequestActions | AuthActionTypes>> {
  const {
    type: actionType,
    payload: { schoolId },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(acceptTermsForSchoolRequest, schoolId);
    const {
      data: { termsStatus },
    } = (yield call(getSchoolRequest, schoolId)) as DefaultResponseDTO<SchoolDTO>;
    yield put(setTermsStatusAction(termsStatus.key === TERMS_ACCEPTED_STATUS));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* changeSchoolAccountSaga(action: ChangeSchoolAccountAction): Generator<void> {
  const {
    payload: { schoolId },
  } = action;

  setCookie(CURRENT_SCHOOL_ID, schoolId);
}

export default function* authSaga(): Generator {
  yield takeLatest(LOG_OUT_USER, deleteUserToken);
  yield takeLatest(SET_USER_TOKEN, setUserTokenInCookie);
  yield takeLatest(REGISTER_USER, registerUser);
  yield takeLatest(REFRESH_SCHOOL_USER, refreshSchoolUserSaga);
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(VALIDATE_USER_TOKEN, validateUserToken);
  yield takeEvery(RESET_PASSWORD, resetUserPasswordSaga);
  yield takeEvery(CHANGE_FORGOTTEN_PASSWORD, changeForgottenPasswordSaga);
  yield takeEvery(CHANGE_PASSWORD, changePasswordSaga);
  yield takeEvery(EDIT_PROFILE, updateProfileSaga);
  yield takeEvery(ACCEPT_TERMS, acceptTermsSaga);
  yield takeEvery(CHANGE_SCHOOL_ACCOUNT, changeSchoolAccountSaga);
}
