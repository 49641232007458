import React from 'react';
import { FORBIDDEN_MESSAGE, INACTIVE_USER } from '../../../shared/constants';

const Forbidden: React.FunctionComponent = (props) => {
  // @ts-ignore
  const search = props.location.search;

  const forbiddenMessage = search ? INACTIVE_USER : FORBIDDEN_MESSAGE;

  return (
    <div>
      <h2>{forbiddenMessage}</h2>
    </div>
  );
};

export default Forbidden;
