import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import EditableGroup from '../../components/EditableGroup';
import { FormConfig, FormDataValues } from '../../components/Form';
import Typography, { TypographyVariants } from '../../components/Typography';
import { selectRequestFormErrors, setRequestSucceededAction } from '../../shared/state/global-request';
import { EDIT_PROFILE, editProfileAction, SchoolUserDTO, selectAuthUser } from '../App/Auth';
import { getTimezonesRequest } from './settings.api';
import { AUTH_USER_SETTINGS_CONFIG } from './Settings.constants';

const Settings: FunctionComponent = () => {
  const dispatch = useDispatch();
  const currentAuthUser = useSelector(selectAuthUser);
  const formErrors = useSelector(selectRequestFormErrors(EDIT_PROFILE));
  const [timezones, setTimezones] = useState([] as string[]);

  useEffect(() => {
    getTimezonesRequest().then(({ data }) => setTimezones(data));
  }, []);

  const updateSettings = (data: FormDataValues): void => {
    dispatch(editProfileAction({ timezone: data.timezone as string }));
  };

  const resetFormState = (): void => {
    dispatch(setRequestSucceededAction(EDIT_PROFILE));
  };

  const authUserSettingsFormConfig: FormConfig = {
    ...AUTH_USER_SETTINGS_CONFIG,
    timezone: {
      ...AUTH_USER_SETTINGS_CONFIG.timezone,
      options: timezones.map((timezone) => ({ label: timezone, value: timezone })),
    },
  };

  return (
    <>
      <div className="mba-heading--wrapper">
        <Typography component="p" variant={TypographyVariants.h1} className="mba-heading--title">
          Settings
        </Typography>
      </div>
      <div className="mba-background--white">
        <EditableGroup<SchoolUserDTO>
          columnSize={6}
          data={currentAuthUser}
          editButtonLabel="Edit"
          formConfig={authUserSettingsFormConfig}
          formErrors={formErrors}
          heading={
            <div>
              <Typography component="h2" variant={TypographyVariants.h2} className="mba-center--vertically">
                Time zone settings
              </Typography>
            </div>
          }
          onEditSubmit={updateSettings}
          onEditCancel={resetFormState}
        />
      </div>
    </>
  );
};

export default Settings;
