import { RankingCalendarDto, RankingDTO } from '../RankingProfile';
import { get } from '../../shared/api';
import { DefaultResponseDTO, PagedResponseDTO, PageSearchQueryConfig } from '../../shared/constants';
import { generateSearchQuery } from '../../shared/helpers';

export const getRankingsRequest = (
  schoolId: number,
  pageConfig: PageSearchQueryConfig = {},
): Promise<PagedResponseDTO<RankingDTO[]>> => {
  const query = generateSearchQuery(pageConfig);
  return get<PagedResponseDTO<RankingDTO[]>>(`/schools/${schoolId}/rankings-history${query}`);
};

export const getCalendarsRequest = (): Promise<DefaultResponseDTO<RankingCalendarDto[]>> => {
  return get<DefaultResponseDTO<RankingCalendarDto[]>>(`/calendars`);
};
