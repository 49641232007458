import React, { FunctionComponent, useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectAuthUser } from '../../../../../Auth';
import { SchoolUserDTO } from '../../../../../Auth/auth.dto';

import styles from './UserNavigation.module.scss';
import UserNavigationLinks from '../UserNavigationLinks';

type UserNavigationProps = {
  logout: () => void;
};

const UserNavigation: FunctionComponent<UserNavigationProps> = ({ logout }) => {
  const { name } = useSelector(selectAuthUser) as SchoolUserDTO;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownButtonRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = (): void => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event: MouseEvent): void => {
    if (
      isDropdownOpen &&
      dropdownButtonRef &&
      dropdownButtonRef.current &&
      !dropdownButtonRef.current.contains(event.target as Node)
    ) {
      setIsDropdownOpen(false);
    }
  };

  const handleDropdownLeave = (): void => {
    if (isDropdownOpen) {
      setIsDropdownOpen(false);
    }
  };

  useLayoutEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return (): void => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <div className={styles.wrapper}>
      <button className={styles.userIcon} onClick={toggleDropdown}>
        Welcome <strong>{name}</strong> <span>&#9207;</span>
      </button>
      {isDropdownOpen && (
        <div className={styles.dropdown} ref={dropdownButtonRef} onMouseLeave={handleDropdownLeave}>
          <ul className={styles.dropdownContent}>
            <UserNavigationLinks logout={logout} />
          </ul>
        </div>
      )}
    </div>
  );
};

export default UserNavigation;
