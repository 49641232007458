import { CampusDTO, CreateCampusDTO, EditCampusDTO } from './campus-profile.dto';
import { del, get, post, put } from '../../../../shared/api';
import { BasicResponseDTO, DefaultResponseDTO } from '../../../../shared/constants';

export const createCampusRequest = (
  schoolId: number,
  data: CreateCampusDTO,
): Promise<DefaultResponseDTO<CampusDTO>> => {
  return post<DefaultResponseDTO<CampusDTO>, CreateCampusDTO>(`/schools/${schoolId}/campuses`, data);
};

export const getCampusRequest = (schoolId: number, campusId: number): Promise<DefaultResponseDTO<CampusDTO>> => {
  return get<DefaultResponseDTO<CampusDTO>>(`/schools/${schoolId}/campuses/${campusId}`);
};

export const updateCampusRequest = (schoolId: number, data: EditCampusDTO): Promise<BasicResponseDTO> => {
  const { id } = data;
  return put<BasicResponseDTO, EditCampusDTO>(`/schools/${schoolId}/campuses/${id}`, data);
};

export const deleteCampusRequest = (schoolId: number, campusId: number): Promise<BasicResponseDTO> => {
  return del<BasicResponseDTO>(`/schools/${schoolId}/campuses/${campusId}`);
};
