import { CampusDTO, CreateCampusDTO, EditCampusDTO } from './campus-profile.dto';

export const CREATE_CAMPUS = 'CREATE_CAMPUS';
export const LOAD_CAMPUS = 'LOAD_CAMPUS';
export const EDIT_CAMPUS = 'EDIT_CAMPUS';
export const EDIT_CAMPUS_DETAILS = 'EDIT_CAMPUS_DETAILS';
export const DELETE_CAMPUS = 'DELETE_CAMPUS';
export const CLEAR_CURRENT_CAMPUS = 'CLEAR_CURRENT_CAMPUS';
export const SET_CAMPUS = 'SET_CAMPUS';

export type CampusProfileState = CreateCampusDTO | EditCampusDTO | CampusDTO;

export interface CreateCampusAction {
  type: typeof CREATE_CAMPUS;
  payload: {
    data: CreateCampusDTO;
    schoolId: number;
  };
}

export interface LoadCampusAction {
  type: typeof LOAD_CAMPUS;
  payload: {
    campusId: number;
    schoolId: number;
  };
}

export interface EditCampusAction {
  type: typeof EDIT_CAMPUS;
  payload: {
    data: EditCampusDTO;
    schoolId: number;
  };
}

export interface EditCampusDetailsAction {
  type: typeof EDIT_CAMPUS_DETAILS;
  payload: {
    data: EditCampusDTO;
    schoolId: number;
  };
}

export interface DeleteCampusAction {
  type: typeof DELETE_CAMPUS;
  payload: {
    campusId: number;
    schoolId: number;
  };
}

export interface ClearCurrentCampusAction {
  type: typeof CLEAR_CURRENT_CAMPUS;
}

export interface SetCampusAction {
  type: typeof SET_CAMPUS;
  payload: { data: CampusDTO };
}

export type CampusActionTypes =
  | ClearCurrentCampusAction
  | CreateCampusAction
  | EditCampusAction
  | EditCampusDetailsAction
  | DeleteCampusAction
  | LoadCampusAction
  | SetCampusAction;
