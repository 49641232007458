import { RootState } from '../../../store/reducers';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';
import { CountriesState } from './countries.types';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const selectCountries = (state: RootState): CountriesState => state.countries;

export const selectCountriesData = createDeepEqualSelector(selectCountries, (countries) => {
  return countries.data;
});
