import React, { FunctionComponent, useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button, { ButtonSizes } from '../../../../components/Button';
import Typography, { TypographyVariants } from '../../../../components/Typography';
import { SchoolUserRole, selectCurrentSchool } from '../../../App/Auth';

import { DashboardRankingDto } from '../../dahboards.dto';
import { loadDashboardsDataAction } from '../../dashboards.actions';
import { selectActiveRankings, selectUpcomingRankings } from '../../dashboards.selectors';
import styles from '../../dashboards.module.scss';
import Grid from '../../../../components/Grid';
import Modal from '../../../../components/Modal';
import { formatDateString, getHtmlFromJSON } from '../../../../shared/helpers';
import { Link, useHistory } from 'react-router-dom';
import {
  selectRequestErrors,
  selectRequestIsLoading,
  setRequestSucceededAction,
} from '../../../../shared/state/global-request';
import {
  RankingSpreadsheetFileDTO,
  uploadSpreadsheetAction,
  UPLOAD_SPREADSHEET,
} from '../../../RankingProfile/CurrentTimeline';
import FileUpload from '../../../../components/FileUpload';
import { SPREADSHEET_TYPES } from '../../dashboards.constants';
import { requestRankingParticipationRequest } from '../../../RankingProfile/CurrentTimeline/ranking-timeline.api';
import { DEFAULT_SPREADSHEET_UPLOAD_FAILED_ERROR } from '../../../../shared/constants';

const ActiveRankings: FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { schoolId } = useSelector(selectCurrentSchool) as SchoolUserRole;
  const activeRankings = useSelector(selectActiveRankings) as DashboardRankingDto[];
  const upcomingRankings = useSelector(selectUpcomingRankings) as DashboardRankingDto[];

  const errors = useSelector(selectRequestErrors(UPLOAD_SPREADSHEET));
  const isLoading = useSelector(selectRequestIsLoading(UPLOAD_SPREADSHEET));

  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [modalContent, setModelContent] = useState('');
  const [spreadsheetType, setSpreadsheetType] = useState('');
  const [isUploadModalOpen, setIsUploadModalOpen] = useState<boolean>(false);
  const selectedRankingId = useRef<number>(0);
  const [requestedParticipation, setRequestedParticipation] = useState<Set<number>>(new Set());
  const [requestedParticipationErrors, setRequestedParticipationErrors] = useState<{ [id: number]: string }>({});

  useEffect(() => {
    if (schoolId) {
      dispatch(loadDashboardsDataAction(schoolId));
    }
  }, [dispatch, schoolId]);

  const getInstructionsHtml = (instructions: string): string => (instructions ? getHtmlFromJSON(instructions) : '-');

  const openInstructions = (currentInstructions: string): void =>
    setModelContent(getInstructionsHtml(currentInstructions));

  const handleUploadModalOpen = (rankingId: number, stepId: number): void => {
    selectedRankingId.current = rankingId;
    const spreadsheetType = SPREADSHEET_TYPES.get(stepId) ?? '';
    setSpreadsheetType(spreadsheetType);

    setIsUploadModalOpen(true);
  };

  useEffect(() => {
    const hasErrors = Object.keys(errors).length;
    if (isSubmit && !isLoading && !hasErrors) {
      setIsUploadModalOpen(false);
      setIsSubmit(false);
    }
  }, [isSubmit, isLoading, errors]);

  const uploadSpreadsheet = (file: File): void => {
    dispatch(setRequestSucceededAction(UPLOAD_SPREADSHEET));

    const formData = new FormData();
    formData.append('file', file);

    const schoolUpdateData: RankingSpreadsheetFileDTO = {
      rankingId: selectedRankingId.current,
      schoolId,
      spreadsheetType,
      spreadsheet: formData,
    };

    dispatch(uploadSpreadsheetAction(schoolUpdateData));
    setIsSubmit(true);
  };

  const requestParticipation = (id: number, schoolId: number): void => {
    requestRankingParticipationRequest(id, schoolId)
      .then(() => {
        setRequestedParticipation((requestedParticipation) => {
          const newParticipations = new Set(requestedParticipation);
          newParticipations.add(id);

          return newParticipations;
        });
      })
      .catch((error) =>
        setRequestedParticipationErrors((errors) => ({ ...errors, [id]: error.response?.data?.errorMessage })),
      );
  };

  const closeFileUploadModal = (): void => {
    setIsUploadModalOpen(false);
    setSpreadsheetType('');
    setIsSubmit(false);
  };

  const formatedError = useMemo(() => {
    if (!isSubmit || Object.keys(errors).length === 0) return '';

    if (errors.spreadsheet)
      return `The spreadsheet could not be validated, please check the following:\n\n${errors.spreadsheet.join('\n')}`;
    return DEFAULT_SPREADSHEET_UPLOAD_FAILED_ERROR;
  }, [errors]);

  return (
    <>
      <section className="section">
        <Typography component="h3" variant={TypographyVariants.h3} className="mba-section-heading-title">
          Your active rankings
        </Typography>
        {activeRankings && activeRankings.length ? (
          activeRankings.map(({ id, name, timelineSteps }, index) => {
            const timeLineUrl = `/rankings/${id}/timeline`;
            return (
              <div className={`mba-background--white mba-no-margin ${index > 0 && ' mba-margin-top-30'}`} key={id}>
                <div className={styles.rankingHeader}>
                  <Typography component="h5" variant={TypographyVariants.h5} className="title">
                    <Link to={timeLineUrl}>
                      <span className="link-title">{name}</span>
                    </Link>
                  </Typography>
                  <Link to={timeLineUrl}>
                    <Button size={ButtonSizes.normal} text="View" />
                  </Link>
                </div>
                {timelineSteps && timelineSteps.length ? (
                  timelineSteps.map(({ stepId, name, instructions, endDate }, index) => (
                    <Grid container compact key={id + name}>
                      <Grid item xs={12} md={6}>
                        {index === 0 && (
                          <div className="mba-width--full">
                            <Typography component="sup" variant={TypographyVariants.h5} className="subTitle">
                              Next action
                            </Typography>
                          </div>
                        )}
                        <div className={styles.dataWrapper}>
                          <Button
                            primary
                            text={name}
                            onClick={(): void => {
                              if (SPREADSHEET_TYPES.get(stepId)) {
                                handleUploadModalOpen(id, stepId);
                              } else {
                                history.push(timeLineUrl);
                              }
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        {index === 0 && (
                          <div className="mba-width--full">
                            <Typography component="sup" variant={TypographyVariants.h5} className="subTitle">
                              Instructions
                            </Typography>
                          </div>
                        )}
                        <div className={styles.dataWrapper}>
                          {instructions ? (
                            <button className="mba-button--link" onClick={(): void => openInstructions(instructions)}>
                              Read instructions
                            </button>
                          ) : (
                            '-'
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        {index === 0 && (
                          <div className="mba-width--full">
                            <Typography component="sup" variant={TypographyVariants.h5} className="subTitle">
                              Deadline
                            </Typography>
                          </div>
                        )}
                        <div className={styles.dataWrapper}>{formatDateString(endDate, 'MMM DD YYYY')}</div>
                      </Grid>
                    </Grid>
                  ))
                ) : (
                  <Grid container compact key={id + 'empty'}>
                    <Grid item xs={12}>
                      <Link to={`/rankings/${id}/timeline`} className={styles.toTimelineLink}>
                        <Button primary text="Go to timeline"></Button>
                      </Link>
                    </Grid>
                  </Grid>
                )}
              </div>
            );
          })
        ) : (
          <div className="mba-background--white" key="active-dashboard-empty">
            <Typography component="h5" variant={TypographyVariants.h5}>
              There are no active rankings at the moment!
            </Typography>
          </div>
        )}
      </section>

      <section className="section">
        <Typography component="h3" variant={TypographyVariants.h3} className="mba-section-heading-title">
          Upcoming rankings
        </Typography>
        {upcomingRankings && upcomingRankings.length ? (
          upcomingRankings.map(({ id, name, hasParticipationCriteria, timelineSteps }, index) => (
            <div className={`mba-background--white ${index > 0 && ' mba-margin-top-30'}`} key={id}>
              <div className={styles.rankingHeader}>
                <Typography component="h5" variant={TypographyVariants.h5} className="title">
                  {name}
                </Typography>
              </div>
              <Grid container compact>
                <Grid item xs={12} md={6}>
                  {index === 0 && (
                    <div className="mba-width--full">
                      <Typography component="sup" variant={TypographyVariants.h5} className="subTitle">
                        Next action
                      </Typography>
                    </div>
                  )}
                  <div className={styles.dataWrapper}>
                    <Button
                      primary
                      text={requestedParticipation.has(id) ? 'Email Sent' : 'Request participation'}
                      disabled={requestedParticipation.has(id)}
                      onClick={(): void => requestParticipation(id, schoolId)}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={3}>
                  {index === 0 && (
                    <div className="mba-width--full">
                      <Typography component="sup" variant={TypographyVariants.h5} className="subTitle">
                        Instructions
                      </Typography>
                    </div>
                  )}
                  <div className={styles.dataWrapper}>
                    {hasParticipationCriteria ? (
                      <Link to={`/rankings/${id}/participation-criteria`}>
                        <span>Read criteria</span>
                      </Link>
                    ) : (
                      '-'
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} md={3}>
                  {index === 0 && (
                    <div className="mba-width--full">
                      <Typography component="sup" variant={TypographyVariants.h5} className="subTitle">
                        Deadline
                      </Typography>
                    </div>
                  )}
                  <div className={styles.dataWrapper}>
                    {timelineSteps && timelineSteps.length > 0
                      ? formatDateString(timelineSteps[0].endDate, 'MMM DD YYYY')
                      : '-'}
                  </div>
                </Grid>
                <Grid item xs={12} className={styles.colorRed}>
                  {requestedParticipationErrors[id]}
                </Grid>
              </Grid>
            </div>
          ))
        ) : (
          <div className="mba-background--white" key="upcoming-dashboard-empty">
            <Typography component="h5" variant={TypographyVariants.h5}>
              There are no upcoming rankings at the moment!
            </Typography>
          </div>
        )}
      </section>

      {!!modalContent && (
        <Modal title={`Instructions for upload`} isOpen={!!modalContent} onClose={(): void => setModelContent('')}>
          <div dangerouslySetInnerHTML={{ __html: modalContent }} />
        </Modal>
      )}
      {isUploadModalOpen && (
        <FileUpload
          title={`Upload ${spreadsheetType} documents`}
          isOpen={isUploadModalOpen}
          isLoading={isLoading}
          onClose={closeFileUploadModal}
          onUpload={uploadSpreadsheet}
          errorMessage={formatedError}
        />
      )}
    </>
  );
};

export default ActiveRankings;
