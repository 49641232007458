import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { LOGIN_ERROR_MESSAGE, LOGIN_FORM_CONFIG } from './Login.constants';
import Button, { ButtonSizes, ButtonTypes } from '../../../../../components/Button';
import Grid from '../../../../../components/Grid';
import Form from '../../../../../components/Form';
import Typography, { TypographyVariants } from '../../../../../components/Typography';
import { loginUserAction } from '../../auth.actions';
import {
  REQUEST_FAILED_NETWORK,
  selectRequestErrorMessage,
  selectRequestErrors,
  selectRequestIsLoading,
} from '../../../../../shared/state/global-request';
import { LOGIN_USER } from '../../auth.types';
import { selectIsTokenVerified } from '../../auth.selectors';
import { LoginDto } from '../../auth.dto';
import Message from '../../../../../components/Message';
import { MessageType } from '../../../../../components/Message/Message.constants';

import styles from './Login.module.scss';

const Login: FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isAuth = useSelector(selectIsTokenVerified);
  const formErrors = useSelector(selectRequestErrors(LOGIN_USER, 'email'));
  const networkErrorMessage = useSelector(selectRequestErrorMessage(REQUEST_FAILED_NETWORK));
  const isLoading = useSelector(selectRequestIsLoading(LOGIN_USER));

  const errorMessage = useMemo(() => {
    const loginError = networkErrorMessage || (formErrors.email && formErrors.email[0]);

    if (!loginError) return '';

    switch (loginError) {
      case 'Unauthorized':
        return LOGIN_ERROR_MESSAGE;
      case 'Network Error':
      default:
        return 'Network error. Please, try again later or contact the administrator';
    }
  }, [formErrors, networkErrorMessage]);

  const [formData, setFormData] = useState({});

  const handleFormSubmit = (loginData: LoginDto): void => {
    if (document.forms.namedItem('sign-in-form')?.getAttribute('data-error') !== 'true') {
      setFormData(loginData);
      dispatch(loginUserAction(loginData));
    }
  };

  useEffect(() => {
    if (isAuth) {
      history.push('/');
    }
  }, [isAuth, history]);

  return (
    <Grid container className="mba-auth-form-wrapper">
      <Grid item xs={11} sm={9} md={7} lg={5} xl={4} center className="mba-auth-form">
        <Typography component="p" variant={TypographyVariants.h3} className="mba-auth-heading">
          Sign in
        </Typography>
        {errorMessage && (
          <Message type={MessageType.ERROR} content={errorMessage} customClassess={styles.loginErrorMessage} />
        )}
        <Form
          id="sign-in-form"
          config={LOGIN_FORM_CONFIG}
          errors={{}}
          onSubmit={handleFormSubmit}
          initialValues={formData}
          isLoading={isLoading}
          customActions={
            <div className="mba-auth-submit-button">
              <Button primary fullWidth type={ButtonTypes.submit} size={ButtonSizes.big} text="Sign in" />
            </div>
          }
        />
        <Link to="/auth/forgotten-password">
          <Typography component="span" variant={TypographyVariants.link} className="mba-auth-link">
            Forgotten password?
          </Typography>
        </Link>
      </Grid>
    </Grid>
  );
};

export default Login;
