import { call, CallEffect, put, PutEffect, takeEvery } from 'redux-saga/effects';
import { DefaultResponseDTO } from '../../shared/constants';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../../shared/state/global-request';
import { DashboardRankingsDto } from './dahboards.dto';
import { DashboardActionTypes, LoadDashboardDataAction, LOAD_DASHBOARD_DATA } from './dashboards.types';
import { getDashboardDataRequest } from './dashboards.api';
import { setDashboardsDataAction } from './dashboards.actions';

function* loadDashboardsDataSaga(
  action: LoadDashboardDataAction,
): Generator<
  CallEffect<DefaultResponseDTO<DashboardRankingsDto>> | PutEffect<GlobalRequestActions | DashboardActionTypes>
> {
  const {
    type: actionType,
    payload: { schoolId },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { data } = (yield call(getDashboardDataRequest, schoolId)) as DefaultResponseDTO<DashboardRankingsDto>;
    yield put(setDashboardsDataAction(data));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

export function* dashboardsSaga(): Generator {
  yield takeEvery(LOAD_DASHBOARD_DATA, loadDashboardsDataSaga);
}
