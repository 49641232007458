import React, { Fragment, FunctionComponent, MouseEvent, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import Button from '../../../../components/Button';
import FileUpload from '../../../../components/FileUpload';
import Grid from '../../../../components/Grid';
import Modal, { MODAL_ANIMATION_DURATION } from '../../../../components/Modal';
import Typography, { TypographyVariants } from '../../../../components/Typography';
import {
  selectRequestErrors,
  selectRequestIsLoading,
  selectRequestStatus,
  setRequestSucceededAction,
} from '../../../../shared/state/global-request';
import { getHtmlFromJSON } from '../../../../shared/helpers';
import { DOWNLOAD_STEP_ATTACHMENT_ERROR } from '../../RankingProfile.constants';
import {
  ParticipationStatus,
  RankingTimelineStepBaseProps,
  TimelineExpandStepStatus,
} from '../RankingTimeline.constants';
import {
  downloadSpreadsheetAction,
  downloadSpreadsheetTemplateAction,
  downloadStepAttachmentAction,
  uploadSpreadsheetAction,
} from '../ranking-timeline.actions';
import { RankingSpreadsheetFileDTO } from '../ranking-timeline.dto';
import { DOWNLOAD_STEP_ATTACHMENT, UPLOAD_SPREADSHEET } from '../ranking-timeline.types';
import TimelineIndicator, { TimelineStepStatus } from './TimelineIndicator';
import TimelineStepDates from './TimelineStepDates';
import { DEFAULT_SPREADSHEET_UPLOAD_FAILED_ERROR } from '../../../../shared/constants';

interface SpreadsheetStepProps extends RankingTimelineStepBaseProps {
  spreadsheetType: string;
}

const SpreadsheetStep: FunctionComponent<SpreadsheetStepProps> = ({
  id,
  attachment,
  endDate,
  instructions,
  isCompleted,
  name,
  participationStatus,
  rankingId,
  schoolId,
  spreadsheet,
  spreadsheetType,
  status,
  templateId,
}) => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const errors = useSelector(selectRequestErrors(UPLOAD_SPREADSHEET));
  const isLoading = useSelector(selectRequestIsLoading(UPLOAD_SPREADSHEET));
  const downloadAttachmentStatus = useSelector(selectRequestStatus(DOWNLOAD_STEP_ATTACHMENT));
  const [expand, setExpand] = useState<boolean>(false);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [isInstructionsModalOpen, setIsInstructionsModalOpen] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const isActionDisabled = status === TimelineStepStatus.completed || status === TimelineStepStatus.upcoming;
  let timelineStatus: TimelineStepStatus = status;

  if (status === TimelineStepStatus.completed && !isCompleted) {
    timelineStatus = TimelineStepStatus.skipped;
  }

  useEffect(() => {
    if (!downloadAttachmentStatus.isLoading && downloadAttachmentStatus.isSuccess === false) {
      // delete downloadAttachmentStatus.isSuccess;
      addToast(DOWNLOAD_STEP_ATTACHMENT_ERROR, { appearance: 'error' });
    }
  }, [addToast, downloadAttachmentStatus]);

  useEffect(() => {
    const hasErrors = Object.keys(errors).length;
    if (isSubmit && !isLoading && !hasErrors) {
      setIsUploadModalOpen(false);
      setIsSubmit(false);
    }
  }, [isSubmit, isLoading, errors]);

  useEffect(() => {
    setExpand(TimelineExpandStepStatus[status]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const uploadSpreadsheet = (file: File): void => {
    dispatch(setRequestSucceededAction(UPLOAD_SPREADSHEET));

    const formData = new FormData();
    formData.append('file', file);

    const schoolUpdateData: RankingSpreadsheetFileDTO = {
      rankingId,
      schoolId,
      spreadsheetType,
      spreadsheet: formData,
    };

    dispatch(uploadSpreadsheetAction(schoolUpdateData));
    setIsSubmit(true);
  };

  const downloadSpreadsheet = (event: MouseEvent): void => {
    event.preventDefault();
    dispatch(downloadSpreadsheetAction(rankingId, schoolId, spreadsheetType));
  };

  const downloadTemplate = (event: MouseEvent): void => {
    event.preventDefault();
    dispatch(downloadSpreadsheetTemplateAction(rankingId, spreadsheetType));
  };

  const downloadAttachment = (event: MouseEvent): void => {
    event.preventDefault();
    dispatch(downloadStepAttachmentAction({ rankingId, stepId: id, attachmentName: attachment?.name }));
  };

  const closeUploadModal = (): void => {
    setIsUploadModalOpen(false);
    setIsSubmit(false);

    setTimeout(() => {
      dispatch(setRequestSucceededAction(UPLOAD_SPREADSHEET));
    }, MODAL_ANIMATION_DURATION);
  };

  const formatedError = useMemo(() => {
    if (!isSubmit || Object.keys(errors).length === 0) return '';

    if (errors.spreadsheet)
      return `The spreadsheet could not be validated, please check the following:\n\n${errors.spreadsheet.join('\n')}`;
    return DEFAULT_SPREADSHEET_UPLOAD_FAILED_ERROR;
  }, [errors]);

  const __html = instructions ? getHtmlFromJSON(instructions) : '-';

  return (
    <Fragment key={name + spreadsheetType}>
      <Grid container>
        <Grid item xs={5}>
          <TimelineStepDates endDate={endDate} endDateLabel="Deadline" isEndDateRequired={true} />
        </Grid>
        <Grid item xs={1} className="mba-no-padding">
          <TimelineIndicator timeIndication={timelineStatus} isCompleted={isCompleted} />
        </Grid>
        <Grid item xs={6} className="mba-margin-bottom-20 mba-no-padding">
          <button
            className="mba-text--accent mba-no-margin mba-text--bold mba-margin-bottom-15 mba-timeline-button--link"
            onClick={(): void => setExpand(!expand)}
          >
            {name}
          </button>
          {expand && (
            <>
              <Grid container compact>
                <Grid item xs={12} md={4}>
                  <Typography variant={TypographyVariants.link}>
                    <button className="mba-button--link" onClick={(): void => setIsInstructionsModalOpen(true)}>
                      Read instructions
                    </button>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={7}>
                  <span className="mba-font--16" style={{ color: '#afafaf' }}>
                    {spreadsheet?.originalName ? (
                      <Typography variant={TypographyVariants.link}>
                        <button className="mba-button--link mba-button--link-b2b" onClick={downloadSpreadsheet}>
                          {spreadsheet.originalName}
                        </button>
                      </Typography>
                    ) : (
                      '-no file uploaded-'
                    )}
                  </span>
                </Grid>
              </Grid>
              <div className="mba-actions">
                <Button
                  text="Download template"
                  wrapperClass={'mba-margin-bottom-10'}
                  onClick={downloadTemplate}
                  disabled={!templateId}
                />
                {!(isActionDisabled || participationStatus?.key !== ParticipationStatus.confirmed) && (
                  <Button
                    primary
                    text="Upload spreadsheet"
                    wrapperClass={'mba-margin-bottom-10'}
                    onClick={(): void => setIsUploadModalOpen(true)}
                  />
                )}
              </div>
            </>
          )}
        </Grid>
      </Grid>
      {isUploadModalOpen && (
        <FileUpload
          title={`Upload ${spreadsheetType} documents`}
          isOpen={isUploadModalOpen}
          isLoading={isLoading}
          onClose={closeUploadModal}
          onUpload={uploadSpreadsheet}
          errorMessage={formatedError}
        />
      )}

      {isInstructionsModalOpen && (
        <Modal
          title={`Instructions for upload of ${spreadsheetType} documents`}
          isOpen={isInstructionsModalOpen}
          onClose={(): void => setIsInstructionsModalOpen(false)}
        >
          <Typography className="mba-no-margin" component="p" variant={TypographyVariants.h5}>
            Attachment
          </Typography>
          <div className="mba-margin-bottom-15">
            {attachment ? (
              <div className="mba-attachment-item">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <a type="button" className="mba-mr-10" onClick={downloadAttachment}>
                  {attachment.name}
                </a>
              </div>
            ) : (
              '-'
            )}
          </div>
          <Typography className="mba-no-margin" component="p" variant={TypographyVariants.h5}>
            Instructions
          </Typography>
          <div dangerouslySetInnerHTML={{ __html }} />
        </Modal>
      )}
    </Fragment>
  );
};

export default SpreadsheetStep;
