import {
  ACCEPT_TERMS,
  AcceptTermsForSchoolAction,
  CHANGE_FORGOTTEN_PASSWORD,
  CHANGE_PASSWORD,
  CHANGE_SCHOOL_ACCOUNT,
  ChangeForgottenPasswordAction,
  ChangePasswordAction,
  ChangeSchoolAccountAction,
  EDIT_PROFILE,
  EditProfileAction,
  LOG_OUT_USER,
  LOGIN_USER,
  LoginUserAction,
  LogOutUserAction,
  REGISTER_USER,
  RegisterUserAction,
  RESET_PASSWORD,
  ResetPasswordAction,
  SET_TERMS_STATUS,
  SET_USER_TOKEN,
  SetTermsStatusAction,
  SetUserTokenAction,
  VALIDATE_USER_TOKEN,
  ValidateUserTokenAction,
  REFRESH_SCHOOL_USER,
  RefreshSchoolUserAction,
} from './auth.types';
import {
  AccountSetUpDTO,
  ChangeForgottenPasswordDto,
  ChangePasswordDto,
  EditProfileDTO,
  LoginDto,
  SchoolUserRole,
} from './auth.dto';

export const checkForValidToken = (token: string): ValidateUserTokenAction => ({
  type: VALIDATE_USER_TOKEN,
  payload: { token },
});

export const refreshSchoolUserAction = (): RefreshSchoolUserAction => ({
  type: REFRESH_SCHOOL_USER,
});

export const setUserToken = (token: string): SetUserTokenAction => ({
  type: SET_USER_TOKEN,
  payload: { token },
});

export const logOutUserAction = (): LogOutUserAction => ({
  type: LOG_OUT_USER,
});

export const resetPasswordAction = (email: string): ResetPasswordAction => ({
  type: RESET_PASSWORD,
  payload: { email },
});

export const changeForgottenPasswordAction = (payload: ChangeForgottenPasswordDto): ChangeForgottenPasswordAction => ({
  type: CHANGE_FORGOTTEN_PASSWORD,
  payload: payload,
});

export const changePasswordAction = (payload: ChangePasswordDto): ChangePasswordAction => ({
  type: CHANGE_PASSWORD,
  payload: payload,
});

export const editProfileAction = (payload: EditProfileDTO): EditProfileAction => ({
  type: EDIT_PROFILE,
  payload: payload,
});

export const loginUserAction = (payload: LoginDto): LoginUserAction => ({
  type: LOGIN_USER,
  payload: payload,
});

export const registerUserAction = (payload: AccountSetUpDTO): RegisterUserAction => ({
  type: REGISTER_USER,
  payload: payload,
});

export const changeSchoolAccountAction = (payload: SchoolUserRole): ChangeSchoolAccountAction => ({
  type: CHANGE_SCHOOL_ACCOUNT,
  payload: payload,
});

export const acceptTermsAction = (schoolId: number): AcceptTermsForSchoolAction => ({
  type: ACCEPT_TERMS,
  payload: { schoolId },
});

export const setTermsStatusAction = (status: boolean): SetTermsStatusAction => ({
  type: SET_TERMS_STATUS,
  payload: { status },
});
