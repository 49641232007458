import { FormConfig } from '../../components/Form';

export const AUTH_USER_SETTINGS_CONFIG: FormConfig = {
  timezone: {
    fieldType: 'select',
    placeholder: `Enter timezone`,
    required: true,
    title: 'Time zone',
    options: [],
    searchable: true,
    validations: {
      required: {
        errorText: `Please enter user's time zone!`,
      },
    },
  },
};
