export const LEADS_TABLE_FILTERS = {
  countryId: {
    isDropdown: true,
    title: 'Country',
    options: [],
  },
  status: {
    title: 'Status',
    options: [],
  },
  programmeOfInterest: {
    isDropdown: true,
    title: 'Programme of interest',
    options: [],
  },
};

export const leadsStatuses = [
  { label: 'New', key: 0 },
  { label: 'Pending', key: 1 },
  { label: 'Contacted', key: 2 },
  { label: 'Rejected', key: 3 },
];

export const PROGRAMMES_OF_INTEREST = [
  { label: 'Masters in Management', key: 'Masters in Management' },
  { label: 'MBA', key: 'MBA' },
  { label: 'Executive Courses', key: 'Executive Courses' },
  { label: 'Masters in Finance', key: 'Masters in Finance' },
  { label: 'EMBA', key: 'EMBA' },
];

export const PROGRAMME_OF_INTEREST_KEY = 'programmeOfInterest';
