import { FormConfig } from '../../../components/Form';

export const CHANGE_PASSWORD_FORM_CONFIG: FormConfig = {
  password: {
    label: 'Password',
    placeholder: 'Enter your password',
    fieldType: 'password',
    subTitle: 'Use 8 or more characters with a mix of letters, numbers & symbols',
    validations: {
      required: {
        errorText: `Please enter your password!`,
      },
    },
  },
  confirmPassword: {
    label: 'Confirm password',
    placeholder: 'Confirm your password',
    fieldType: 'password',
    validations: {
      required: {
        errorText: 'Please confirm your password!',
      },
    },
  },
};
