import React, { FunctionComponent, ReactNode } from 'react';

import { IconNames } from './Icon.constants';

type IconProps = {
  className?: string;
  customIcon?: ReactNode;
  name?: IconNames;
  size?: number;
};

const Icon: FunctionComponent<IconProps> = ({ className, customIcon, name, size }) => {
  const iconClasses = ['o-icons-icon'];
  let icon: ReactNode = null;

  if (className) {
    iconClasses.push(className);
  }

  if (name) {
    iconClasses.push(`o-icons-icon--${name}`);
  } else if (customIcon) {
    icon = customIcon;
  }

  return (
    <span style={{ width: size, height: size }} className={iconClasses.join(' ')}>
      {icon}
    </span>
  );
};

export default Icon;
