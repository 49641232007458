import React, { ChangeEventHandler, FocusEventHandler, FunctionComponent } from 'react';

import { RadioInputsOption, RadioInputsShape } from './RadioInputs.constants';
import InputWrapper, { InputWrapperProps } from '../InputWrapper';

type RadioInputsProps = {
  disabled?: boolean;
  className?: string;
  error?: string;
  id?: string;
  inline?: boolean;
  name?: string;
  negativeHighlight?: boolean;
  onBlur?: FocusEventHandler;
  onChange?: ChangeEventHandler;
  options: RadioInputsOption[];
  required?: boolean;
  shape?: RadioInputsShape;
  touched?: boolean;
  valid?: boolean;
  value?: string | number;
};

type Props = RadioInputsProps & InputWrapperProps;

const RadioInputs: FunctionComponent<Props> = ({
  disabled = false,
  className,
  error,
  id,
  inline = false,
  name,
  negativeHighlight = false,
  onBlur,
  onChange,
  optional = false,
  options,
  required = false,
  shrunken = false,
  subTitle,
  title,
  shape = RadioInputsShape.circle,
  touched,
  valid,
  value,
  verticallyCentered,
}) => {
  const radioGroupClasses = ['o-forms-input'];
  const radioLabelClasses = ['o-forms-input__label'];

  if (negativeHighlight) {
    radioLabelClasses.push('o-forms-input__label--negative');
  }

  let inputs;
  const content = options.map(({ label, value: radioValue }) => (
    <label key={`${name}-${radioValue}`}>
      <input
        type="radio"
        name={name}
        value={radioValue}
        aria-label={label}
        checked={onChange ? value === radioValue : undefined}
        defaultChecked={!onChange ? value === radioValue : undefined}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
      />
      <span className={radioLabelClasses.join(' ')} aria-hidden="true">
        {label}
      </span>
    </label>
  ));

  if (error || (touched && !valid)) {
    radioGroupClasses.push('o-forms-input--invalid');
  } else if (touched && valid) {
    radioGroupClasses.push('o-forms-input--valid');
  }

  if (shape === RadioInputsShape.box) {
    radioGroupClasses.push('o-forms-input--radio-box');
    inputs = <div className="o-forms-input--radio-box__container">{content}</div>;
  } else {
    radioGroupClasses.push('o-forms-input--radio-round');
    inputs = content;
  }

  if (inline) {
    radioGroupClasses.push('o-forms-input--inline');
  }

  return (
    <InputWrapper
      id={id}
      inline={inline && shrunken}
      className={className}
      groupedElement
      optional={optional}
      required={required}
      shrunken={inline && shrunken}
      subTitle={subTitle}
      title={title}
      verticallyCentered={verticallyCentered}
    >
      <div className={radioGroupClasses.join(' ')}>
        {inputs}
        {error && <span className="o-forms-input__error">{error}</span>}
      </div>
    </InputWrapper>
  );
};

export default RadioInputs;
