import { DEFAULT_SHOW_ALL_FILTER_CONFIG, FiltersConfig } from './FilterGroup.constants';
import { CheckboxInputOption } from '../Checkbox';
import { PageFilterConfig, Status } from '../../shared/constants';
import { capitalizeText } from '../../shared/helpers';

const mapOptionToCheckboxInputOptionConfig = (
  optionData: Status | string | { [key: string]: string },
  initialValues = [] as string[],
  useShortLabel = false,
  rawLabel = false,
): CheckboxInputOption => {
  let name = '';
  let label = '';

  if (typeof optionData === 'string') {
    name = optionData;
    label = optionData;
  } else {
    name = optionData.key.toString();
    label = useShortLabel && optionData.shortLabel ? optionData.shortLabel : optionData.label;
  }

  return {
    name,
    text: `${rawLabel ? '' : 'Show '}${capitalizeText(label)}`,
    value: initialValues.indexOf(name) >= 0,
  };
};

interface FilterData {
  [key: string]: (Status | string | { [key: string]: string })[];
}

const setDefaultFilterValue = (filterName: string, filterValues = [] as string[]): CheckboxInputOption => ({
  ...DEFAULT_SHOW_ALL_FILTER_CONFIG,
  value: !(filterValues.length > 0),
});

export const mapInitialValuesToFiltersConfig = (
  filters: FiltersConfig,
  filtersData: FilterData,
  initialFilters = {} as PageFilterConfig,
  useShortLabel = false,
): FiltersConfig => {
  const newFilters = {} as FiltersConfig;

  Object.entries(filters).forEach(([filterName, filterConfig]) => {
    const options: (Status | string | { [key: string]: string })[] = filtersData.hasOwnProperty(filterName)
      ? filtersData[filterName]
      : [];
    const optionsConfig = [
      ...options?.map((optionData) =>
        mapOptionToCheckboxInputOptionConfig(
          optionData,
          initialFilters[filterName],
          useShortLabel,
          filterConfig.isDropdown,
        ),
      ),
    ];

    if (!filterConfig.isDropdown) {
      optionsConfig.unshift(setDefaultFilterValue(filterName, initialFilters[filterName]));
    }

    newFilters[filterName] = {
      ...filterConfig,
      options: optionsConfig,
    };
  });

  return newFilters;
};
