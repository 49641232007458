/* eslint-disable jsx-a11y/no-static-element-interactions */
import moment from 'moment';
import React, { ChangeEventHandler, FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import { TableAction, TableHeaderColumnTypes } from '../Table.constants';
import Button from '../../Button';
import Checkbox from '../../Checkbox';
import Label, { LabelSize, LabelStatus } from '../../Label';
import RadioInputs from '../../RadioInputs';
import { DEFAULT_DATE_FORMAT, DEFAULT_TIMEZONE } from '../../../shared/constants';
import { capitalizeText } from '../../../shared/helpers';

import styles from './TableCell.module.scss';

interface StatusCell {
  label: string;
  color: string;
}

interface CustomData {
  [key: string]: string | number;
}

export type TableData = CustomData | StatusCell | string | number;

type TableCell = {
  rowId: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rowData: any;
  actions?: TableAction[];
  compact?: boolean;
  columnId?: string;
  columnType?: TableHeaderColumnTypes;
  data?: TableData;
  defaultRadioSelected?: number;
  checked?: boolean;
  path?: string;
  onRowRadioButtonChange?: ChangeEventHandler;
  onRowSelect?: ChangeEventHandler;
};

const TableCell: FunctionComponent<TableCell> = ({
  actions,
  checked = false,
  columnId,
  columnType,
  compact = false,
  data,
  defaultRadioSelected,
  onRowRadioButtonChange,
  onRowSelect,
  path,
  rowId,
  rowData,
}) => {
  const tableDetailClasses = [];
  let cellContent = null;
  let tableDetailDataType = null;

  switch (columnType) {
    case TableHeaderColumnTypes.actions: {
      if (actions) {
        cellContent = (
          <div className={styles.actionsCell}>
            {actions &&
              actions.map(({ handleOnClick, label }, index: number) => {
                const handleRowActionClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
                  event.preventDefault();
                  handleOnClick(rowId);
                };

                return <Button key={`action-${rowId}-${index}`} onClick={handleRowActionClick} text={label} />;
              })}
          </div>
        );
      }
      break;
    }
    case TableHeaderColumnTypes.currency:
    case TableHeaderColumnTypes.numeric: {
      tableDetailDataType = TableHeaderColumnTypes.numeric;
      tableDetailClasses.push('o-table__cell--numeric');
      cellContent = data || '-';
      break;
    }
    case TableHeaderColumnTypes.radio: {
      tableDetailClasses.push(styles.checkboxCell);

      cellContent = (
        <RadioInputs
          value={defaultRadioSelected}
          options={[{ label: '', value: rowId }]}
          onChange={onRowRadioButtonChange}
        />
      );
      break;
    }
    case TableHeaderColumnTypes.selection: {
      tableDetailClasses.push(styles.checkboxCell);

      cellContent = (
        <Checkbox id={`row-select-${rowId}`} name={rowId.toString()} onChange={onRowSelect} value={checked} />
      );
      break;
    }
    case TableHeaderColumnTypes.status: {
      const cellData = { ...(data as StatusCell) };
      if (cellData && cellData.label) {
        const cellSize = compact ? LabelSize.small : LabelSize.standard;

        cellContent = (
          <Label color={cellData.color} status={LabelStatus.active} fullWidth size={cellSize}>
            {cellData.label}
          </Label>
        );
      }
      break;
    }
    case TableHeaderColumnTypes.capitalize: {
      cellContent = capitalizeText(data as string) || '-';
      break;
    }
    case TableHeaderColumnTypes.date: {
      cellContent = moment(data as string).format(DEFAULT_DATE_FORMAT);
      break;
    }
    case TableHeaderColumnTypes.link: {
      cellContent = data || '-';
      if (path) {
        let updatedPath = path;
        if (typeof data === 'string' || typeof data === 'number') {
          updatedPath = path.replace(`:${columnId}`, data.toString());
        }
        cellContent = <Link to={updatedPath.replace(':id', rowId.toString())}>{data}</Link>;
      }
      break;
    }
    case TableHeaderColumnTypes.compositeLink: {
      cellContent = '-';
      if (data && path) {
        let updatedPath = path;
        const cellData: CustomData = { ...(data as CustomData) };
        const replacementKeys = path
          .split('/')
          .filter((routeParam: string) => routeParam.startsWith(':'))
          .map((routeParam: string) => routeParam.slice(1));

        if (columnId && rowData.hasOwnProperty(columnId) && typeof rowData[columnId] === 'string') {
          cellContent = rowData[columnId] || '-';
        } else {
          cellContent = cellData.label || cellData.key || '-';
        }

        replacementKeys.forEach((compositeKey: string) => {
          const [mainKey, ...otherKeys] = compositeKey.split('.');

          if (otherKeys.length > 0) {
            let data = rowData[mainKey];
            let depthLevel = 0;

            while (depthLevel < otherKeys.length) {
              if (data && data.hasOwnProperty(otherKeys[depthLevel])) {
                data = data[otherKeys[depthLevel]];
              } else {
                break;
              }

              depthLevel++;
            }

            updatedPath = updatedPath.replace(`:${compositeKey}`, data ? data.toString() : rowId);
          } else if (rowData.hasOwnProperty(mainKey) && rowData[mainKey]) {
            updatedPath = updatedPath.replace(`:${mainKey}`, rowData[mainKey].toString());
          }
        });

        cellContent = <Link to={updatedPath}>{cellContent}</Link>;
      }
      break;
    }
    default: {
      cellContent = data || '-';
    }
  }

  return (
    <td className={tableDetailClasses.join(' ')} data-o-table-data-type={tableDetailDataType}>
      {cellContent || '-'}
    </td>
  );
};

export default TableCell;
