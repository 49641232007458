import { CreateUserDTO, EditUserDTO, SchoolUserRolesAndPermissionsDTO, UserDTO } from './users.dto';
import {
  CLEAR_CURRENT_USER,
  ClearCurrentUserAction,
  CREATE_USER,
  CreateUserAction,
  EDIT_USER,
  EditUserAction,
  LOAD_USERS,
  LoadUsersAction,
  SET_USER,
  SET_USERS,
  SetUserAction,
  SetUsersAction,
  DeactivateUserAction,
  DEACTIVATE_USER,
  DeleteUserAction,
  DELETE_USER,
  UpdateSchoolUserRolesAndPermissionsAction,
  UPDATE_SCHOOL_USER_ROLES_AND_PERMISSIONS,
  LOAD_SCHOOL_USER,
  LoadSchoolUserAction,
  ACTIVATE_USER,
  ActivateUserAction,
} from './users.types';
import { PageSearchQueryConfig, TableListData } from '../../../shared/constants';

export const createNewUserAction = (data: CreateUserDTO, schoolId: number): CreateUserAction => ({
  type: CREATE_USER,
  payload: { data, schoolId },
});

export const editUserAction = (data: EditUserDTO): EditUserAction => ({
  type: EDIT_USER,
  payload: { data },
});

export const setUserAction = (data: UserDTO): SetUserAction => ({
  type: SET_USER,
  payload: { data },
});

export const clearCurrentUserAction = (): ClearCurrentUserAction => ({
  type: CLEAR_CURRENT_USER,
});

export const loadUsersAction = (schoolId: number, pageConfig?: PageSearchQueryConfig): LoadUsersAction => ({
  type: LOAD_USERS,
  payload: { pageConfig, schoolId },
});

export const setUsersAction = (tableData: TableListData<UserDTO>): SetUsersAction => ({
  type: SET_USERS,
  payload: { tableData },
});

export const deactivateUserAction = (userId: number, schoolId: number): DeactivateUserAction => ({
  type: DEACTIVATE_USER,
  payload: { userId, schoolId },
});

export const activateUserAction = (userId: number, schoolId: number): ActivateUserAction => ({
  type: ACTIVATE_USER,
  payload: { userId, schoolId },
});

export const deleteUserAction = (userId: number, schoolId: number): DeleteUserAction => ({
  type: DELETE_USER,
  payload: { userId, schoolId },
});

export const updateSchoolUserRoleAndPermissionsAction = (
  data: SchoolUserRolesAndPermissionsDTO,
  partnershipId: number,
  schoolId: number,
  userId: number,
): UpdateSchoolUserRolesAndPermissionsAction => ({
  type: UPDATE_SCHOOL_USER_ROLES_AND_PERMISSIONS,
  payload: { data, partnershipId, schoolId, userId },
});

export const loadSchoolUserAction = (id: number, schoolId: number): LoadSchoolUserAction => ({
  type: LOAD_SCHOOL_USER,
  payload: { id, schoolId },
});
