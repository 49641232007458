/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect } from 'react';
import React, { FunctionComponent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormValue } from '../../../../components/Form';
import SelectField from '../../../../components/Form/FormFields/SelectField';
import { editLeadAction } from '../../lead-profile.actions';
import { selectCurrentLead } from '../../lead-profile.selectors';
import { selectControl } from '../LeadProfile.constants';
import styles from './ChangeStatus.module.scss';

const ChangeStatus: FunctionComponent = () => {
  const [isVisible, setIsVisible] = useState(false);
  const currentLead = useSelector(selectCurrentLead);
  const [selectedStatus, setSelectedStatus] = useState(selectControl);
  const dispatch = useDispatch();

  useEffect(() => {
    setSelectedStatus({ ...selectControl, value: String(currentLead?.status) });
  }, [currentLead]);

  const cancel = (): void => {
    setIsVisible(false);
  };

  const onSaveStatus = (): void => {
    dispatch(editLeadAction({ id: currentLead.id, status: Number(selectedStatus.value) }));
    setIsVisible(false);
  };

  const inputChangedHandler = (name: string, value: FormValue): void => {
    setSelectedStatus({ ...selectedStatus, value: String(value) });
  };

  return (
    <div className={styles.container}>
      {!isVisible && (
        <span
          className={styles.text}
          onClick={(): void => setIsVisible(!isVisible)}
          onKeyDown={(): void => setIsVisible(!isVisible)}
        >
          Change status
        </span>
      )}
      {isVisible && (
        <div className={styles.actionBtns}>
          <span onClick={(): void => onSaveStatus()} onKeyDown={(): void => onSaveStatus()}>
            Save
          </span>
          <span onClick={(): void => cancel()} onKeyDown={(): void => cancel()}>
            Cancel
          </span>
        </div>
      )}
      {isVisible && (
        <div className={styles.selectWrapper}>
          <SelectField control={selectedStatus} inputChangedHandler={inputChangedHandler} />
        </div>
      )}
    </div>
  );
};

export default ChangeStatus;
