import React, { FunctionComponent, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { Navigation, NavigationConfiguration, UserNavigation } from '../PortalLayout';
import { selectIsTokenVerified } from '../../../Auth';
import { useWindowSize } from '../../../../../shared/helpers.hooks';
import UserNavigationLinks from '../PortalLayout/Navigation/UserNavigationLinks/UserNavigationLinks';

type HeaderProps = {
  onLogoutClick?: () => void;
  routes?: NavigationConfiguration;
  tagline?: string;
  title?: string;
};

const Header: FunctionComponent<HeaderProps> = ({ onLogoutClick, routes, tagline, title }) => {
  useEffect(() => {
    if (window && (window as any).Origami) {
      (window as any).Origami['o-header-services'].init();
    }
  }, []);

  return (
    <header className="o-header-services" data-o-component="o-header-services">
      <div className="o-header-services__top" style={{ overflow: 'visible', zIndex: '9999' }}>
        <div className="o-header-services__hamburger">
          <a className="o-header-services__hamburger-icon" href="#core-nav-fallback" role="button">
            <span className="o-header-services__visually-hidden">Open primary navigation</span>
          </a>
        </div>
        <div className="o-header-services__logo" />
        <div className="o-header-services__title">
          {title && (
            <a className="o-header-services__product-name" href="/">
              {title}
            </a>
          )}
          {tagline && <span className="o-header-services__product-tagline">{tagline}</span>}
        </div>
      </div>
      {routes && <Navigation routes={routes} onLogoutClick={onLogoutClick} />}
    </header>
  );
};

export default Header;
