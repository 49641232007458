import React, { FunctionComponent, useState } from 'react';

import { getHtmlFromJSON } from '../../shared/helpers';

import styles from './Accordion.module.scss';

interface AccordionContentProps {
  title: string;
  description: string;
  preSelected?: boolean;
  children?: React.ReactNode;
}

export const Accordion: FunctionComponent<AccordionContentProps> = ({
  title,
  description,
  preSelected = false,
  children,
}) => {
  const [selected, setSelected] = useState<boolean>(preSelected);

  const onSelectItem = (): void => {
    setSelected(!selected);
  };

  return (
    <div className={styles.accordionSectionContent}>
      <button className={`${styles.questionBtn} ${selected ? styles.itemSelected : ''}`} onClick={onSelectItem}>
        {title}
      </button>
      {selected &&
        (children ? (
          <div>{children}</div>
        ) : (
          <div
            className={`${styles.accordionSectionDescription}`}
            dangerouslySetInnerHTML={{ __html: getHtmlFromJSON(description) }}
          />
        ))}
    </div>
  );
};
