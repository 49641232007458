import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';

import { setTermsAction } from './terms.actions';
import { getTermsRequest } from './terms.api';
import { LOAD_TERMS, LoadTermsAction, TermsActionTypes } from './terms.types';
import { DefaultResponseDTO } from '../../shared/constants';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../../shared/state/global-request';

function* loadTermsSaga(
  action: LoadTermsAction,
): Generator<CallEffect<DefaultResponseDTO<string>> | PutEffect<GlobalRequestActions | TermsActionTypes>> {
  const { type: actionType } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { data } = (yield call(getTermsRequest)) as DefaultResponseDTO<string>;
    yield put(setTermsAction(data));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

export default function* termsSaga(): Generator {
  yield takeLatest(LOAD_TERMS, loadTermsSaga);
}
