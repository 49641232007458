export const LOAD_ROLES = 'LOAD_ROLES';
export const SET_ROLES = 'SET_ROLES';

export type RolesState = {
  [key: string]: string[];
};

export interface LoadRolesAction {
  type: typeof LOAD_ROLES;
  payload: {
    rolesType: string;
  };
}

export interface SetRolesAction {
  type: typeof SET_ROLES;
  payload: {
    data: string[];
    rolesType: string;
  };
}

export type RolesActionTypes = LoadRolesAction | SetRolesAction;
