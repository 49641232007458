import React, { FunctionComponent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EDIT_PROFILE_FORM_CONFIG } from './EditProfile.constants';
import { EDIT_PROFILE, editProfileAction, EditProfileDTO, SchoolUserDTO, selectAuthUser } from '../../App/Auth';
import Button, { ButtonSizes, ButtonTypes } from '../../../components/Button';
import Form, { FormDataValues } from '../../../components/Form';
import { selectRequestFormErrors, selectRequestIsLoading } from '../../../shared/state/global-request';

const EditProfile: FunctionComponent = () => {
  const dispatch = useDispatch();
  const formErrors = useSelector(selectRequestFormErrors(EDIT_PROFILE));
  const isLoading = useSelector(selectRequestIsLoading(EDIT_PROFILE));
  const { name, phone } = useSelector(selectAuthUser) as SchoolUserDTO;
  const [formData, setFormData] = useState({ name, phone } as FormDataValues);

  const handleChange = (values: FormDataValues): void => {
    setFormData(values);
  };

  const handleFormSubmit = (values: EditProfileDTO): void => {
    dispatch(editProfileAction(values));
  };

  return (
    <Form
      id="profile-edit-form"
      config={EDIT_PROFILE_FORM_CONFIG}
      errors={formErrors}
      onSubmit={handleFormSubmit}
      initialValues={formData}
      onValuesChanged={handleChange}
      isLoading={isLoading}
      customActions={
        <div className="mba-auth-submit-button">
          <Button
            isLoading={isLoading}
            primary
            fullWidth
            type={ButtonTypes.submit}
            size={ButtonSizes.big}
            text="Submit"
          />
        </div>
      }
    />
  );
};

export default EditProfile;
