import { call, CallEffect, put, PutEffect, takeEvery } from 'redux-saga/effects';

import { setResourcesAction } from './resources.actions';
import { getResourcesRequest } from './resources.api';
import { LOAD_RESOURCES, LoadResourcesAction, ResourcesActionTypes } from './resources.types';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../global-request';
import { DefaultResponseDTO, Resource } from '../../constants';

function* loadResourcesData(
  action: LoadResourcesAction,
): Generator<
  CallEffect<DefaultResponseDTO<Resource[]>> | PutEffect<ResourcesActionTypes> | PutEffect<GlobalRequestActions>
> {
  const { type: actionType } = action;
  const { queryProps, resourceName } = action.payload;

  let query = '';
  let resource = resourceName;
  if (queryProps) {
    const { name, value } = queryProps;
    resource = `${resourceName}/${name}-${value}`;
    query = `${name}=${value}`;
  }

  try {
    yield put(setRequestStartedAction(actionType));
    const { data } = (yield call(getResourcesRequest, resourceName, query)) as DefaultResponseDTO<Resource[]>;
    yield put(setResourcesAction(resource, data));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

export default function* resourcesSaga(): Generator {
  yield takeEvery(LOAD_RESOURCES, loadResourcesData);
}
