import { ChangeEventHandler, FocusEventHandler } from 'react';

export interface CheckboxInputOption {
  name: string;
  text?: string;
  value?: boolean;
}

export interface CheckboxOptionsState {
  [key: string]: boolean;
}

export enum CheckboxLabelPositions {
  left = 'left',
  right = 'right',
}

export type CommonCheckboxProps = {
  disabled?: boolean;
  error?: string;
  id?: string;
  inline?: boolean;
  labelPosition?: CheckboxLabelPositions;
  name: string;
  onBlur?: FocusEventHandler;
  onChange?: ChangeEventHandler;
  required?: boolean;
  touched?: boolean;
  valid?: boolean;
};
