import { RankingDTO, RankingTimelineStepDTO } from './ranking-profile.dto';
import { get } from '../../shared/api';
import { DefaultResponseDTO } from '../../shared/constants';

export const getRankingRequest = (rankingId: number): Promise<DefaultResponseDTO<RankingDTO>> => {
  return get<DefaultResponseDTO<RankingDTO>>(`/rankings/${rankingId}`);
};

export const getRankingParticipationCriteriaRequest = (rankingId: number): Promise<DefaultResponseDTO<string>> => {
  return get<DefaultResponseDTO<string>>(`/rankings/${rankingId}/participation-criteria`);
};

export const getRankingInstructionsRequest = (rankingId: number): Promise<DefaultResponseDTO<string>> => {
  return get<DefaultResponseDTO<string>>(`/rankings/${rankingId}/welcome-text`);
};

export const getRankingTimelineStepsRequest = (
  schoolId: number,
  rankingId: number,
): Promise<DefaultResponseDTO<RankingTimelineStepDTO[]>> => {
  return get<DefaultResponseDTO<RankingTimelineStepDTO[]>>(`/schools/${schoolId}/rankings/${rankingId}/timeline`);
};
