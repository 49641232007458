import { CreateUserDTO, EditUserDTO, SchoolUserRolesAndPermissionsDTO, UserDTO } from './users.dto';
import { del, get, post, put } from '../../../shared/api';
import {
  BasicResponseDTO,
  DefaultResponseDTO,
  PagedResponseDTO,
  PageSearchQueryConfig,
} from '../../../shared/constants';
import { generateSearchQuery } from '../../../shared/helpers';

export const createUserRequest = (userData: CreateUserDTO, schoolId: number): Promise<DefaultResponseDTO<UserDTO>> => {
  return post<DefaultResponseDTO<UserDTO>, CreateUserDTO>(`/schools/${schoolId}/accounts`, userData);
};

export const updateUserRequest = (userData: EditUserDTO): Promise<BasicResponseDTO> => {
  const { id: userId, school: schoolId } = userData;
  return put<BasicResponseDTO, EditUserDTO>(`/schools/${schoolId}/accounts/${userId}`, userData);
};

export const getUsersRequest = (
  pageConfig: PageSearchQueryConfig = {},
  schoolId: number,
): Promise<PagedResponseDTO<UserDTO[]>> => {
  const query = generateSearchQuery(pageConfig);
  return get<PagedResponseDTO<UserDTO[]>>(`/schools/${schoolId}/accounts${query}`);
};

export const deactivateUserRequest = (userId: number, schoolId: number): Promise<BasicResponseDTO> => {
  return put<BasicResponseDTO, EditUserDTO>(`/schools/${schoolId}/accounts/${userId}`, { status: 2 } as EditUserDTO);
};

export const activateUserRequest = (userId: number, schoolId: number): Promise<BasicResponseDTO> => {
  return put<BasicResponseDTO, EditUserDTO>(`/schools/${schoolId}/accounts/${userId}`, { status: 1 } as EditUserDTO);
};

export const deleteUserRequest = (userId: number, schoolId: number): Promise<BasicResponseDTO> => {
  return del<BasicResponseDTO, EditUserDTO>(`/schools/${schoolId}/accounts/${userId}?ignoreWarnings=false`);
};

export const getSchoolUserRequest = (schoolUserId: number, schoolId: number): Promise<DefaultResponseDTO<UserDTO>> => {
  return get<DefaultResponseDTO<UserDTO>>(`/schools/${schoolId}/accounts/${schoolUserId}`);
};

export const updateSchoolUserRoleAndPermissionsRequest = (
  roleAndPermissions: SchoolUserRolesAndPermissionsDTO,
  schoolId: number,
  userId: number,
): Promise<BasicResponseDTO> => {
  return put<BasicResponseDTO, SchoolUserRolesAndPermissionsDTO>(
    `/schools/${schoolId}/accounts/${userId}/partnerships`,
    roleAndPermissions,
  );
};
