export const TYPOGRAPHY_ITALIC_CLASS_NAME = 'o-typography-italic';

export enum TypographyVariants {
  h1 = 'o-typography-heading-level-1',
  h2 = 'o-typography-heading-level-2',
  h3 = 'o-typography-heading-level-3',
  h4 = 'o-typography-heading-level-4',
  h5 = 'o-typography-heading-level-5',
  h6 = 'o-typography-heading-level-6',
  sup = 'o-typography-sup',
  sub = 'o-typography-sub',
  body = 'o-typography-body',
  link = 'o-typography-link',
  caption = 'o-typography-caption',
}
