import { CLEAR_REQUEST_FAILED, GlobalRequestActions, GlobalRequestFailedAction } from './global-request.types';
import { put, PutEffect, takeEvery } from 'redux-saga/effects';

import { REQUEST_FAILED_NETWORK, NETWORK_ERROR, REQUEST_FAILED } from './global-request.constants';
import { setRequestFailedNetworkAction, setRequestSucceededAction } from './global-request.actions';
import { redirectUserToForbiddenUrl, redirectUserToLogin } from '../../helpers';

function* requestFailedSaga(action: GlobalRequestFailedAction): Generator<PutEffect<GlobalRequestActions>> {
  const { payload } = action;
  const isNetworkError = payload.data.message === 'Network Error';
  const response = payload.data?.response;
  const errorMessage = response?.data?.errorMessage;

  if (isNetworkError) {
    const networkError = { errorMessage: NETWORK_ERROR };
    yield put(setRequestFailedNetworkAction(REQUEST_FAILED_NETWORK, networkError));
  }

  //Forbidden
  if (response?.status === 403) {
    redirectUserToForbiddenUrl(errorMessage);
  }

  //Unauthorized
  if (response?.status === 401) {
    redirectUserToLogin();
  }
}

function* resetRequestFailedNetwork(): Generator<PutEffect<GlobalRequestActions>> {
  yield put(setRequestSucceededAction(REQUEST_FAILED_NETWORK));
}

export default function* globalRequestSaga(): Generator {
  yield takeEvery(REQUEST_FAILED, requestFailedSaga);
  yield takeEvery(CLEAR_REQUEST_FAILED, resetRequestFailedNetwork);
}
