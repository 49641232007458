import React, { ChangeEvent, FocusEventHandler, FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { TextInputTypes } from '../TextInput';
import InputWrapper, { InputWrapperProps } from '../InputWrapper';
import { FormControlFieldType } from '../Form';

import styles from './EmaiPhoneInput.module.scss';

type EmailPhoneInputProps = {
  name: string;
  label?: string;
  type?: FormControlFieldType;
  disabled?: boolean;
  error?: string;
  id?: string;
  onBlur?: FocusEventHandler;
  onChange?: (name: string, value: string, fieldType?: string) => void;
  placeholder?: string;
  required?: boolean;
  small?: boolean;
  suffix?: ReactNode;
  touched?: boolean;
  valid?: boolean;
  value: string;
};

type Props = EmailPhoneInputProps & InputWrapperProps;

const PHONE_REGEX = /^[0-9\s+-.()]+$/;
const EMAIL_REGEX = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

export const EmailPhoneInput: FunctionComponent<Props> = ({
  disabled = false,
  id,
  name,
  onBlur,
  onChange,
  placeholder,
  required = false,
  label,
  type,
  value,
}) => {
  const [fieldValues, setFieldValues] = useState<string[]>([]);
  const [defaultValue, setDefaultValue] = useState<string>('');
  const [fieldError, setFieldError] = useState<string>('');

  const isEmail = (email: string): boolean => {
    return EMAIL_REGEX.test(email);
  };

  const isPhone = (email: string): boolean => {
    return PHONE_REGEX.test(email);
  };

  const isInList = (val: string): boolean => {
    return fieldValues.indexOf(val) > -1;
  };

  useEffect(() => {
    if (value) {
      setFieldValues([...value.split(',')] || []);
    }
  }, [value]);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setDefaultValue(event.target.value);
  };

  const isValidValue = (val: string): boolean => {
    let emailError = '';
    if (type === TextInputTypes.emailPhone && !isEmail(val)) {
      emailError = `${val} is not a valid ${type}.`;
    }

    if (type === TextInputTypes.emailPhone && !isPhone(val)) {
      emailError = `${val} is not a valid ${type}.`;
    }

    if (isInList(val)) {
      emailError = `${val} has already been added.`;
    }

    setFieldError(emailError);

    return !emailError;
  };

  const handleFieldChange = (value: string): void => {
    setFieldValues((prev) => [...prev, value]);
    setDefaultValue('');
    onChange && onChange(name, [...fieldValues, value].join(','), type);
  };

  const handleKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>): void => {
    if (['Enter', 'Tab', ';', ',', ' '].includes(evt.key)) {
      evt.preventDefault();
      const fieldValue = defaultValue.trim();
      if (type && type === TextInputTypes.emailPhone && fieldValue && isValidValue(fieldValue)) {
        handleFieldChange(fieldValue);
      }
      if (type && type === TextInputTypes.emailPhone && isValidValue(fieldValue)) {
        handleFieldChange(fieldValue);
      }
    }
  };

  const handleDelete = (toBeRemoved: string): ((event: React.MouseEvent<HTMLElement>) => void) => {
    return (event: React.MouseEvent<HTMLElement>): void => {
      event.preventDefault();
      event.stopPropagation();
      const updatedField = fieldValues.filter((value) => value !== toBeRemoved);
      setFieldValues(updatedField);
      onChange && onChange(name, updatedField.join(','), type);
    };
  };

  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
    if (!event.target.value) {
      setFieldError('');
      return;
    }
    if (isValidValue(defaultValue)) {
      handleFieldChange(defaultValue);
    }
    onBlur && onBlur(event);
  };

  return (
    <InputWrapper required={required} title={label}>
      <div className={`${styles.emailPhoneField} ${fieldError ? styles.inputInvalid : ''}`}>
        <span className={styles.emailPhonePlaceholder}>{placeholder}</span>
        {fieldValues.map((fieldValue) => (
          <div key={fieldValue} className={styles.emailPhoneTag}>
            <span>{fieldValue}</span>
            <button type="button" className={styles.removeTag} onMouseUp={handleDelete(fieldValue)}>
              &times;
            </button>
          </div>
        ))}
        <input
          id={id}
          type={'text'}
          name={name}
          disabled={disabled}
          required={required}
          value={defaultValue}
          onChange={handleOnChange}
          onKeyDown={handleKeyDown}
          onBlur={handleOnBlur}
          placeholder={placeholder}
        />
      </div>
      {fieldError && (
        <div className="o-forms-input--invalid">
          <span className="o-forms-input__error ">{fieldError}</span>
        </div>
      )}
    </InputWrapper>
  );
};
