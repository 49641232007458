import { call, CallEffect, put, PutEffect, select, SelectEffect, takeEvery, takeLatest } from 'redux-saga/effects';

import { setCampusAction } from './campus-profile.actions';
import { createCampusRequest, deleteCampusRequest, getCampusRequest, updateCampusRequest } from './campus-profile.api';
import { CampusDTO } from './campus-profile.dto';
import {
  CampusActionTypes,
  CLEAR_CURRENT_CAMPUS,
  CREATE_CAMPUS,
  CreateCampusAction,
  DELETE_CAMPUS,
  DeleteCampusAction,
  EDIT_CAMPUS,
  EDIT_CAMPUS_DETAILS,
  EditCampusAction,
  LOAD_CAMPUS,
  LoadCampusAction,
} from './campus-profile.types';
import { CampusesActionTypes, loadCampusesAction, selectCampusesPageConfiguration } from '../CampusesList';
import { BasicResponseDTO, DefaultResponseDTO, PageSearchQueryConfig } from '../../../../shared/constants';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../../../../shared/state/global-request';

function* createNewCampusSaga(
  action: CreateCampusAction,
): Generator<
  PutEffect<GlobalRequestActions | CampusActionTypes | CampusesActionTypes> | CallEffect<DefaultResponseDTO<CampusDTO>>
> {
  const {
    type: actionType,
    payload: { schoolId, data },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(createCampusRequest, schoolId, data);
    yield put(setRequestSucceededAction(actionType));
    yield put(loadCampusesAction(schoolId));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* loadCampusSaga(
  action: LoadCampusAction,
): Generator<CallEffect<DefaultResponseDTO<CampusDTO>> | PutEffect<GlobalRequestActions | CampusActionTypes>> {
  const {
    type: actionType,
    payload: { campusId, schoolId },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { data } = (yield call(getCampusRequest, schoolId, campusId)) as DefaultResponseDTO<CampusDTO>;
    yield put(setCampusAction(data));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* editCampusSaga(
  action: EditCampusAction,
): Generator<PutEffect<GlobalRequestActions | CampusesActionTypes> | CallEffect<BasicResponseDTO> | SelectEffect> {
  const {
    type: actionType,
    payload: { data, schoolId },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(updateCampusRequest, schoolId, data);
    yield put(setRequestSucceededAction(actionType));
    const pageConfig = (yield select(selectCampusesPageConfiguration)) as PageSearchQueryConfig;
    yield put(loadCampusesAction(schoolId, pageConfig));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* deleteCampusSaga(
  action: DeleteCampusAction,
): Generator<PutEffect<GlobalRequestActions | CampusesActionTypes> | CallEffect<BasicResponseDTO> | SelectEffect> {
  const {
    type: actionType,
    payload: { campusId, schoolId },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(deleteCampusRequest, schoolId, campusId);
    yield put(setRequestSucceededAction(actionType));
    const pageConfig = (yield select(selectCampusesPageConfiguration)) as PageSearchQueryConfig;
    yield put(loadCampusesAction(schoolId, pageConfig));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* resetCampusErrors(): Generator<PutEffect<GlobalRequestActions>> {
  yield put(setRequestSucceededAction(CREATE_CAMPUS));
  yield put(setRequestSucceededAction(EDIT_CAMPUS));
  yield put(setRequestSucceededAction(EDIT_CAMPUS_DETAILS));
  yield put(setRequestSucceededAction(DELETE_CAMPUS));
}

export default function* campusProfileSaga(): Generator {
  yield takeLatest(CREATE_CAMPUS, createNewCampusSaga);
  yield takeLatest(LOAD_CAMPUS, loadCampusSaga);
  yield takeLatest(EDIT_CAMPUS, editCampusSaga);
  yield takeLatest(DELETE_CAMPUS, deleteCampusSaga);
  yield takeEvery(CLEAR_CURRENT_CAMPUS, resetCampusErrors);
}
