import { useEffect, DependencyList, EffectCallback, useRef, Ref, useState } from 'react';
import isDeepEqual from 'fast-deep-equal/react';
import { WindowSize } from './constants';

interface OutsideClickOptions {
  disableClickAway: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isPrimitive = (val: any): boolean => val !== Object(val);

type DepsEqualFnType<TDeps extends DependencyList> = (prevDeps: TDeps, nextDeps: TDeps) => boolean;

const useObjectCompareEffect = <TDeps extends DependencyList>(
  effect: EffectCallback,
  deps: TDeps,
  depsEqual: DepsEqualFnType<TDeps>,
): void => {
  const ref = useRef<TDeps | undefined>(undefined);

  if (!ref.current || !depsEqual(deps, ref.current)) {
    ref.current = deps;
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, ref.current);
};

export const useObjectEqualityEffect = (effect: EffectCallback, deps: DependencyList): void => {
  if (process.env.NODE_ENV !== 'production') {
    if (!(deps instanceof Array) || !deps.length) {
      console.warn('`useObjectCompareEffect` should not be used with no dependencies. Use React.useEffect instead.');
    }

    if (deps.every(isPrimitive)) {
      console.warn(
        '`useObjectCompareEffect` should not be used with dependencies that are all primitive values. Use React.useEffect instead.',
      );
    }
  }

  useObjectCompareEffect(effect, deps, isDeepEqual);
};

export function useOutsideClick(ref: Ref<HTMLElement>, callback: () => void, options?: OutsideClickOptions): void {
  useEffect(() => {
    if (options && options.disableClickAway) return;
    function handleClickOutside(event: MouseEvent): void {
      // @ts-ignore
      if (ref?.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return (): void => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback, options]);
}

export const useWindowSize = (): WindowSize => {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: 0,
    height: 0,
    isMobile: false,
  });

  const handleResize = (): void => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
      isMobile: window.innerWidth < 740,
    });
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
};
