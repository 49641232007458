import React, { FunctionComponent, useEffect, useState } from 'react';

import TimelineIndicator, { TimelineStepStatus } from './TimelineIndicator';
import TimelineStepDates from './TimelineStepDates';
import { RankingTimelineStepBaseProps, TimelineExpandStepStatus } from '../RankingTimeline.constants';
import Button from '../../../../components/Button';
import Grid from '../../../../components/Grid';
import { goToPublicPortal } from '../../../../shared/helpers';

const PublicationStep: FunctionComponent<RankingTimelineStepBaseProps> = ({
  rankingId,
  rankingSlug,
  endDate,
  name,
  status,
  isCompleted,
}) => {
  const [expand, setExpand] = useState<boolean>(false);

  useEffect(() => {
    setExpand(TimelineExpandStepStatus[status]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const visitRanking = (): void => {
    goToPublicPortal(`rankings/${rankingId}/${rankingSlug}`);
  };

  return (
    <Grid container key={name}>
      <Grid item xs={5}>
        <TimelineStepDates endDate={endDate} endDateLabel="Date" isEndDateRequired={true} />
      </Grid>
      <Grid item xs={1} className="mba-no-padding">
        <TimelineIndicator isLast timeIndication={status} isCompleted={isCompleted !== null ? isCompleted : false} />
      </Grid>
      <Grid item xs={6} className="mba-margin-bottom-20 mba-no-padding">
        <button
          className="mba-text--accent mba-no-margin mba-text--bold mba-timeline-button--link mba-margin-bottom-15"
          onClick={(): void => setExpand(!expand)}
        >
          {name}
        </button>
        {expand && (
          <div className="mba-actions mba-margin-top-10">
            <Button
              primary
              text="Go to ranking's page"
              onClick={visitRanking}
              disabled={status !== TimelineStepStatus.completed}
            />
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default PublicationStep;
