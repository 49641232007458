import React, { FunctionComponent, useState } from 'react';
import { getHtmlFromJSON } from '../../../../../shared/helpers';

import styles from '../../RankingTimeline.module.scss';

type SurveyLetterProps = {
  content?: string;
};

const SurveyLetter: FunctionComponent<SurveyLetterProps> = ({ content }) => {
  const [isWholeContentShown, setIsWholeContentShown] = useState(false);

  const handleClick = (): void => {
    setIsWholeContentShown(!isWholeContentShown);
  };

  const __html = content ? getHtmlFromJSON(content) : '-';
  const contentClassName = isWholeContentShown ? '' : styles.shortenedAlumniLetter;
  return (
    <>
      {content ? (
        <>
          <div dangerouslySetInnerHTML={{ __html }} style={{ marginBottom: '8px' }} className={contentClassName} />
          <button className="mba-button--link" onClick={handleClick}>
            {!isWholeContentShown ? 'Show more' : 'Show less'}
          </button>
        </>
      ) : (
        <span className="mba-font--16" style={{ color: '#afafaf' }}>
          The alumni letter is not available yet
        </span>
      )}
    </>
  );
};

export default SurveyLetter;
