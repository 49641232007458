import React, { CSSProperties, FunctionComponent } from 'react';
import ReactSelect, { StylesConfig, ValueType } from 'react-select';

import { getSelectControlCSSProperties, SELECT_CUSTOM_STYLES, SelectOptionType, SelectProps } from './Select.constants';
import InputWrapper from '../InputWrapper';

const Select: FunctionComponent<SelectProps> = ({
  className,
  classNamePrefix,
  defaultOption,
  error,
  id,
  disabled = false,
  isMulti = false,
  isSearchable = false,
  name,
  noOptionsMessage,
  noOptionsText,
  onValueChanged,
  optional,
  options = [],
  placeholder,
  required,
  shrunken = false,
  subTitle,
  title,
  touched,
  valid,
  value,
  verticallyCentered,
}) => {
  const selectWrapperClasses = ['o-forms-input', 'o-forms-input--select'];

  if (className) {
    selectWrapperClasses.push(className);
  }

  if (error || (touched && !valid)) {
    selectWrapperClasses.push('o-forms-input--invalid');
  }

  const getNoOptionsText = (): string | null => (noOptionsText ? noOptionsText : null);

  const selectStyles: StylesConfig<SelectOptionType, boolean> = {
    ...SELECT_CUSTOM_STYLES,
    control: (provided, state) => ({
      ...provided,
      ...(getSelectControlCSSProperties(
        state.isFocused,
        state.isDisabled,
        !!error || (touched && !valid),
      ) as CSSProperties),
    }),
  };

  const defaultSelectedOption = options
    ? ((options as SelectOptionType[]).find((option) => option.value === defaultOption) as ValueType<
        SelectOptionType,
        boolean
      >)
    : undefined;

  const handleOnChange = (selectedOption: ValueType<SelectOptionType, boolean>): void => {
    name && onValueChanged && onValueChanged(name, selectedOption as SelectOptionType | SelectOptionType[]);
  };

  return (
    <InputWrapper
      id={id}
      inline={shrunken}
      optional={optional}
      required={required}
      shrunken={shrunken}
      subTitle={subTitle}
      title={title}
      verticallyCentered={verticallyCentered}
    >
      <div className={selectWrapperClasses.join(' ')}>
        <ReactSelect<SelectOptionType, boolean>
          maxMenuHeight={200}
          styles={selectStyles}
          classNamePrefix={classNamePrefix}
          defaultValue={defaultSelectedOption}
          isDisabled={disabled}
          isMulti={isMulti}
          isSearchable={isSearchable}
          name={name}
          options={options}
          placeholder={placeholder}
          required={required}
          noOptionsMessage={noOptionsText ? getNoOptionsText : noOptionsMessage}
          value={value}
          onChange={handleOnChange}
          openMenuOnFocus={false}
          tabSelectsValue={false}
        />
        {error && <span className="o-forms-input__error">{error}</span>}
      </div>
    </InputWrapper>
  );
};

export default Select;
