import { CountryDTO } from './countries.dto';

export const LOAD_COUNTRIES = 'LOAD_COUNTRIES';
export const SET_COUNTRIES = 'SET_COUNTRIES';

export type CountriesState = {
  [key: string]: CountryDTO[];
};

export interface CountriesQueryProps {
  name: string;
  value: string | number;
}

export interface LoadCountriesAction {
  type: typeof LOAD_COUNTRIES;
  payload: {
    queryProps?: CountriesQueryProps;
    country: string;
  };
}

export interface SetCountriesAction {
  type: typeof SET_COUNTRIES;
  payload: CountryDTO[];
}

export interface CountryData {
  message: string;
  data: CountryDTO[];
}

export type CountriesActionTypes = LoadCountriesAction | SetCountriesAction;
