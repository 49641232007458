import React, { FunctionComponent, useEffect, useState } from 'react';
import Button from '../../../../../components/Button';

import styles from '../../RankingTimeline.module.scss';
import { getSurveyLinkRequest } from '../../ranking-timeline.api';
import { SurveyLinkDTO } from '../../ranking-timeline.dto';

type SurveyLinkProps = {
  text: string;
  rankingId: number;
  schoolId: number;
};

const SurveyLink: FunctionComponent<SurveyLinkProps> = ({ text, rankingId, schoolId }) => {
  const [isCopied, setIsCopied] = useState(false);
  const [linkData, setLinkData] = useState<SurveyLinkDTO | null>();

  useEffect(() => {
    const fetchLinkData = async () => {
      const data = await getSurveyLinkRequest(rankingId, schoolId);
      setLinkData(data);
    };
    fetchLinkData();
  }, [rankingId, schoolId]);

  const handleClick = () => {
    navigator.clipboard.writeText(linkData?.value || '');
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 10000);
  };

  return (
    <>
      {linkData ? (
        <>
          <Button primary text={text} onClick={handleClick} />{' '}
          {isCopied && (
            <>
              <span className={styles.tickIcon} />
              <span style={{ fontSize: '14px', color: '#00572c', marginLeft: '5px' }}>Copied</span>
            </>
          )}
        </>
      ) : (
        <span className="mba-font--16" style={{ color: '#afafaf' }}>
          The survey link is not available yet
        </span>
      )}
    </>
  );
};

export default SurveyLink;
