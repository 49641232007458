import { TableHeaderColumnTypes } from '../../../components/Table';

export const LEADS_TABLE_COLUMN_DEFINITION = [
  {
    id: 'selection',
    type: TableHeaderColumnTypes.selection,
    width: '90px',
  },
  {
    id: 'status',
    title: 'Status',
    type: TableHeaderColumnTypes.status,
    sorting: true,
    width: '90px',
  },
  {
    id: 'name',
    sorting: true,
    title: 'Name',
    width: '120px',
    type: TableHeaderColumnTypes.link,
    path: '/leads/lead/:id',
  },
  {
    id: 'question.experience',
    sorting: true,
    title: 'Years of Professional Experience',
    type: TableHeaderColumnTypes.numeric,
    width: '120px',
  },
  {
    id: 'user.country',
    sorting: true,
    title: 'Country',
    width: '120px',
  },
  {
    id: 'createdAt',
    sorting: true,
    title: 'Date Created',
    type: TableHeaderColumnTypes.date,
    width: '110px',
  },
];

export enum Status {
  contacted = 'Contacted',
  rejected = 'Rejected',
  pending = 'Pending',
}

export enum Color {
  contacted = '#006b36',
  rejected = '#a50505',
  pending = '#d6b70c',
}

export enum FileType {
  PDF = 'pdf',
  EXCEL = 'excel',
  CSV = 'csv',
}

export interface UpdateLead {
  id: number;
  status?: number;
}
