import React, { FunctionComponent, useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import SecondaryNavigation from './SecondaryNavigation';
import { NavigationConfiguration } from '../PortalLayout.constants';
import UserRoleNavigation from './UserRoleNavigation';

import styles from './Navigation.module.scss';
import { useSelector } from 'react-redux';
import { useWindowSize } from '../../../../../../shared/helpers.hooks';
import { selectIsTokenVerified } from '../../../../Auth';
import UserNavigation from './UserNavigation';
import UserNavigationLinks from './UserNavigationLinks';

type NavigationProps = {
  routes: NavigationConfiguration;
  onLogoutClick?: () => void;
};

const Navigation: FunctionComponent<NavigationProps> = ({ routes, onLogoutClick }) => {
  const location = useLocation();
  const navRef = useRef<HTMLDivElement>(null);
  const isAuthenticated = useSelector(selectIsTokenVerified);
  const { isMobile } = useWindowSize();

  const currentPageKey = location.pathname.split('/')[1] || '';
  let subNavigation = null;

  if (currentPageKey && routes.hasOwnProperty(currentPageKey) && routes[currentPageKey].navigation) {
    subNavigation = <SecondaryNavigation pageName={currentPageKey} {...routes[currentPageKey]} />;
  }

  const closeNavMenu = (): void => {
    if (navRef && navRef.current && navRef.current.classList.contains('o-header-services__primary-nav--open')) {
      navRef.current.classList.remove('o-header-services__primary-nav--open');
    }
  };

  return (
    <>
      <nav ref={navRef} style={{ zIndex: '999' }} className="o-header-services__primary-nav" aria-label="primary">
        <div className={styles.headerNavWrapper}>
          <ul className="o-header-services__primary-nav-list">
            {Object.entries(routes)
              .filter(([_, route]) => route.visible)
              .map(([key, { label, route }]) => (
                <li key={key}>
                  <NavLink exact={route === '/'} to={route} onClick={closeNavMenu}>
                    {label}
                  </NavLink>
                </li>
              ))}
            <UserRoleNavigation closeNavMenu={closeNavMenu} />
          </ul>
        </div>
      </nav>
      {subNavigation}
      {onLogoutClick && (
        <ul className="o-header-services__related-content">
          {isAuthenticated &&
            (isMobile ? (
              <UserNavigationLinks logout={onLogoutClick} handleNavElementClick={closeNavMenu} />
            ) : (
              <li style={{ height: '100%' }}>
                <UserNavigation logout={onLogoutClick} />
              </li>
            ))}
        </ul>
      )}
    </>
  );
};

export default Navigation;
