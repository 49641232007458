import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadRankingsAction } from '../rankings.actions';
import { selectRankingsPageConfiguration, selectRankingsTableData } from '../rankings.selectors';
import RankingsFiltersAndSearch from './RankingsFiltersAndSearch';
import { RANKINGS_HISTORY_TABLE_COLUMN_DEFINITION } from './RankingsHistory.constants';
import Pager, { INITIAL_PAGE } from '../../../components/Pager';
import Table from '../../../components/Table';
import Typography, { TypographyVariants } from '../../../components/Typography';
import { ASCENDING_ORDER_DIRECTION, DESCENDING_ORDER_DIRECTION } from '../../../shared/constants';
import { loadResourcesAction, selectResources } from '../../../shared/state/resources';
import { loadStatusesAction, selectStatuses } from '../../../shared/state/statuses';

const RankingsHistory: FunctionComponent = () => {
  const dispatch = useDispatch();
  const pageConfig = useSelector(selectRankingsPageConfiguration);
  const rankingsData = useSelector(selectRankingsTableData);

  const rankingStatuses = useSelector(selectStatuses('portalRanking'));
  const rankingTypes = useSelector(selectResources('ranking-types'));
  const rankingYears = useSelector(selectResources('rankings/publication-years'));

  useEffect(() => {
    dispatch(loadRankingsAction());
    dispatch(loadResourcesAction('ranking-types'));
    dispatch(loadResourcesAction('rankings/publication-years'));
    dispatch(loadStatusesAction('portalRanking'));
  }, [dispatch]);

  const handlePageChanged = (page: number): void => {
    dispatch(loadRankingsAction({ ...pageConfig, page }));
  };

  const handleSortByChanged = (fieldName: string): void => {
    const orderDirection =
      pageConfig.sortBy !== fieldName
        ? DESCENDING_ORDER_DIRECTION
        : pageConfig.order === ASCENDING_ORDER_DIRECTION
        ? DESCENDING_ORDER_DIRECTION
        : ASCENDING_ORDER_DIRECTION;
    dispatch(loadRankingsAction({ ...pageConfig, sortBy: fieldName, order: orderDirection }));
  };
  return (
    <>
      <div className="mba-heading--wrapper">
        <Typography component="p" variant={TypographyVariants.h1} className="mba-heading--title">
          Rankings
        </Typography>
      </div>
      <div className="mba-table-wrapper">
        <div className="mba-table-filters mba-background--white">
          <RankingsFiltersAndSearch
            pageConfig={pageConfig}
            filtersData={{ rankingStatuses, rankingYears, rankingTypes }}
          />
        </div>
        <div className="mba-table-container mba-background--white">
          <Table
            id="rankings-list"
            stripes={false}
            columns={RANKINGS_HISTORY_TABLE_COLUMN_DEFINITION}
            data={rankingsData}
            sortingOptions={{
              order: pageConfig.order || DESCENDING_ORDER_DIRECTION,
              sortBy: pageConfig.sortBy || '',
            }}
            onSortByColumn={handleSortByChanged}
          />
          {pageConfig && pageConfig.totalPages !== INITIAL_PAGE && (
            <Pager
              currentPage={pageConfig.page}
              totalPages={pageConfig.totalPages || INITIAL_PAGE}
              onPageChange={handlePageChanged}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default RankingsHistory;
