import { RootState } from '../../store/reducers';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';
import { TableListData } from '../../shared/constants';
import { LeadsDTO } from './leads.dto';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const selectLeads = (state: RootState): TableListData<LeadsDTO> => state.leads;

export const selectLeadsTableData = createDeepEqualSelector(selectLeads, (leads) => {
  return leads.data;
});

export const selectLeadsPageConfiguration = createDeepEqualSelector(selectLeads, (leads) => {
  return {
    filter: leads.filter,
    limit: leads.pagination.limit,
    order: leads.sortOrder.order,
    page: leads.pagination.page,
    search: leads.search,
    sortBy: leads.sortOrder.sortBy,
    totalPages: leads.pagination.totalPages,
  };
});
