import React, { FunctionComponent, ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import EmbargoTableStep from './components/EmbargoTableStep';
import ParticipationStep from './components/ParticipationStep';
import PublicationStep from './components/PublicationStep';
import SpreadsheetStep from './components/SpreadsheetStep';
import SurveyStep from './components/SurveyStep';
import { TimelineStepStatus } from './components/TimelineIndicator';
import { getTimelineIndicatorStatus } from './RankingTimeline.helpers';
import { loadRankingTimelineAction } from '../ranking-profile.actions';
import { RankingDTO, RankingTimelineStepDTO } from '../ranking-profile.dto';
import { selectCurrentRanking, selectCurrentTimelineSteps } from '../ranking-profile.selectors';
import { SchoolUserRole, selectCurrentSchool } from '../../App/Auth';

import styles from './RankingTimeline.module.scss';

const RankingTimeline: FunctionComponent = () => {
  const dispatch = useDispatch();
  const currentRanking = useSelector(selectCurrentRanking) as RankingDTO;
  const { schoolId } = useSelector(selectCurrentSchool) as SchoolUserRole;
  const timelineSteps = useSelector(selectCurrentTimelineSteps) as RankingTimelineStepDTO[];

  const { id: rankingId, publicationDate, slug: rankingSlug } = currentRanking;

  useEffect(() => {
    if (rankingId) {
      dispatch(loadRankingTimelineAction(rankingId));
    }
  }, [dispatch, rankingId]);

  const setStepStatus = (data: RankingTimelineStepDTO): number => {
    return data.participationStatus?.key !== 1
      ? TimelineStepStatus.upcoming
      : getTimelineIndicatorStatus(data.endDate, data.startDate);
  };

  const steps = Object.values(timelineSteps).map((stepData: RankingTimelineStepDTO, index): ReactNode => {
    const { isOptional, surveyId } = stepData;
    const data = {
      ...stepData,
      rankingId,
      rankingSlug,
      schoolId,
    };

    switch (stepData.id) {
      case 1:
        return (
          !isOptional && (
            <ParticipationStep
              {...data}
              key={`timeline-step-${index}`}
              status={getTimelineIndicatorStatus(data.endDate)}
            />
          )
        );
      case 2:
        return (
          !isOptional && (
            <SpreadsheetStep
              {...data}
              key={`timeline-step-${index}`}
              spreadsheetType="alumni"
              status={setStepStatus(data)}
            />
          )
        );
      case 3:
        return (
          !isOptional && (
            <SpreadsheetStep
              {...data}
              key={`timeline-step-${index}`}
              spreadsheetType="faculty"
              status={setStepStatus(data)}
            />
          )
        );
      case 4:
        return (
          !isOptional && (
            <SpreadsheetStep
              {...data}
              key={`timeline-step-${index}`}
              spreadsheetType="research"
              status={setStepStatus(data)}
            />
          )
        );
      case 5:
        return (
          !isOptional && (
            <SurveyStep
              {...data}
              key={`timeline-step-${index}`}
              surveyType="school"
              url={surveyId}
              status={setStepStatus(data)}
            />
          )
        );
      case 7:
        return <EmbargoTableStep {...data} key={`timeline-step-${index}`} status={setStepStatus(data)} />;
      case 8:
        return (
          <PublicationStep
            {...data}
            key={`timeline-step-${index}`}
            endDate={publicationDate}
            status={
              getTimelineIndicatorStatus(publicationDate) !== TimelineStepStatus.completed
                ? TimelineStepStatus.upcoming
                : TimelineStepStatus.completed
            }
          />
        );
      case 9:
        return (
          !isOptional && (
            <SurveyStep
              {...data}
              key={`timeline-step-${index}`}
              surveyType="alumniLink"
              status={setStepStatus(data)}
              rankingId={rankingId}
              schoolId={schoolId}
            />
          )
        );
      case 10:
        return (
          !isOptional && (
            <SurveyStep
              {...data}
              key={`timeline-step-${index}`}
              surveyType="alumniResponseRate"
              schoolId={schoolId}
              rankingId={rankingId}
              status={setStepStatus(data)}
            />
          )
        );
      case 11:
        return (
          !isOptional && (
            <SurveyStep
              {...data}
              key={`timeline-step-${index}`}
              surveyType="alumniLetter"
              content={stepData.content}
              status={setStepStatus(data)}
            />
          )
        );
      default:
        return null;
    }
  });

  return <section className={styles.timeline}>{steps}</section>;
};

export default RankingTimeline;
