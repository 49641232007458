import { DefaultResponseDTO } from '../../shared/constants';
import { UpdateLead } from '../Leads/components/Leads.constants';
import { LeadsDTO } from '../Leads/leads.dto';
import { getRegistrationRequest, updateRegistrationRequest } from '../../shared/registrationApi/api';

export const updateLeadProfileRequest = (data: UpdateLead): Promise<DefaultResponseDTO<LeadsDTO>> => {
  return updateRegistrationRequest<DefaultResponseDTO<LeadsDTO>, UpdateLead>(`lead/${data?.id}`, data);
};

export const getLeadRequest = (id: number): Promise<DefaultResponseDTO<LeadsDTO>> => {
  return getRegistrationRequest<DefaultResponseDTO<LeadsDTO>>(`lead/${id}`);
};
