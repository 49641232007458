import { RankingDTO, RankingTimelineStepDTO } from './ranking-profile.dto';
import {
  CLEAR_CURRENT_RANKING,
  ClearCurrentRankingAction,
  LOAD_RANKING,
  LOAD_RANKING_INSTRUCTIONS,
  LOAD_RANKING_PARTICIPATION_CRITERIA,
  LOAD_RANKING_TIMELINE,
  LoadRankingAction,
  LoadRankingInstructionsAction,
  LoadRankingParticipationCriteriaAction,
  LoadRankingTimelineAction,
  SET_RANKING,
  SET_RANKING_INSTRUCTIONS,
  SET_RANKING_PARTICIPATION_CRITERIA,
  SET_RANKING_TIMELINE,
  SetRankingAction,
  SetRankingInstructionsAction,
  SetRankingParticipationCriteriaAction,
  SetRankingTimelineAction,
} from './ranking-profile.types';

export const loadRankingAction = (id: number): LoadRankingAction => ({
  type: LOAD_RANKING,
  payload: { id },
});

export const setRankingAction = (data: RankingDTO): SetRankingAction => ({
  type: SET_RANKING,
  payload: { data },
});

export const clearCurrentRankingAction = (): ClearCurrentRankingAction => ({
  type: CLEAR_CURRENT_RANKING,
});

export const loadRankingParticipationCriteriaAction = (id: number): LoadRankingParticipationCriteriaAction => ({
  type: LOAD_RANKING_PARTICIPATION_CRITERIA,
  payload: { id },
});

export const setRankingParticipationCriteriaAction = (data: string): SetRankingParticipationCriteriaAction => ({
  type: SET_RANKING_PARTICIPATION_CRITERIA,
  payload: { data },
});

export const loadRankingInstructionsAction = (id: number): LoadRankingInstructionsAction => ({
  type: LOAD_RANKING_INSTRUCTIONS,
  payload: { id },
});

export const setRankingInstructionsAction = (data: string): SetRankingInstructionsAction => ({
  type: SET_RANKING_INSTRUCTIONS,
  payload: { data },
});

export const loadRankingTimelineAction = (id: number): LoadRankingTimelineAction => ({
  type: LOAD_RANKING_TIMELINE,
  payload: { id },
});

export const setRankingTimelineAction = (data: RankingTimelineStepDTO[]): SetRankingTimelineAction => ({
  type: SET_RANKING_TIMELINE,
  payload: { data },
});
