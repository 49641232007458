import { FormConfig } from '../../../components/Form';

export interface LeadProfileRouteParams {
  id: string;
}

export const LEAD_PROFILE_CONFIG_FIRST: FormConfig = {
  status: {
    title: 'Status',
  },
  email: {
    title: 'Email',
  },
  name: {
    title: 'Name',
  },
  country: {
    title: 'Country',
  },
  createdAt: {
    title: 'Date Created',
  },
  nationality: {
    title: 'Nationality',
  },
};

export const LEAD_PROFILE_CONFIG_SECOND: FormConfig = {
  role: {
    title: 'Role',
  },
};

export const selectControl = {
  id: '',
  name: 'status',
  touched: false,
  valid: true,
  value: '',
  options: [
    { label: 'New', value: '0' },
    { label: 'Pending', value: '1' },
    { label: 'Contacted', value: '2' },
    { label: 'Rejected', value: '3' },
  ],
};
