import React, { FunctionComponent } from 'react';

import { DEFAULT_GRID_COLSPAN_SIZE, GridColSpanSize } from './Grid.constants';

type GridProps = {
  bleed?: boolean;
  className?: string;
  center?: boolean;
  compact?: boolean;
  container?: boolean;
  item?: boolean;
  xs?: boolean | GridColSpanSize;
  sm?: boolean | GridColSpanSize;
  md?: boolean | GridColSpanSize;
  lg?: boolean | GridColSpanSize;
  xl?: boolean | GridColSpanSize;
};

const Grid: FunctionComponent<GridProps> = ({
  bleed = false,
  className,
  center = false,
  compact = false,
  container = false,
  item = false,
  xs = DEFAULT_GRID_COLSPAN_SIZE,
  sm = false,
  md = false,
  lg = false,
  xl = false,
  children,
}) => {
  const gridClasses = [];
  let content = <div>children</div>;

  if (className) {
    gridClasses.push(className);
  }

  if (container) {
    gridClasses.push('o-grid-container');
    const rowClasses = ['o-grid-row'];

    if (bleed) {
      gridClasses.push('o-grid-container--bleed');
    }

    if (compact) {
      rowClasses.push('o-grid-row--compact');
    }

    content = (
      <div className={gridClasses.join(' ')}>
        <div className={rowClasses.join(' ')}>{children}</div>
      </div>
    );
  } else if (item) {
    const colspan = {
      center: center ? 'center' : false,
      xs: typeof xs === 'number' ? xs : DEFAULT_GRID_COLSPAN_SIZE,
      sm: typeof sm === 'number' ? `S${sm}` : false,
      md: typeof md === 'number' ? `M${md}` : false,
      lg: typeof lg === 'number' ? `L${lg}` : false,
      xl: typeof xl === 'number' ? `XL${xl}` : false,
    };

    content = (
      <div
        className={gridClasses.join(' ')}
        data-o-grid-colspan={Object.values(colspan)
          .filter((colValue) => !!colValue)
          .join(' ')}
      >
        {children}
      </div>
    );
  }

  return content;
};

export default Grid;
