import { ReactNode } from 'react';

import {
  FormConfig,
  FormConfigProps,
  FormControlElementProps,
  FormControlFieldType,
  FormControlProps,
  FormControlValidations,
  FormDataValues,
  FormValue,
} from './Form.constants';
import {
  renderCheckboxField,
  renderPasswordField,
  renderRadioButtons,
  renderSelectField,
  renderTextAreaField,
  renderTextInputField,
  renderEmailPhoneInputField,
} from './FormFields';

export const generateFormFields = (
  config: FormConfigProps,
  inputChangedHandler: (name: string, value: FormValue) => void,
): ReactNode => {
  if (!config) {
    return null;
  }

  const formElementsArray: FormControlElementProps[] = [];
  Object.entries(config).forEach(([controlKey, controlConfig]) => {
    formElementsArray.push({
      ...(controlConfig as FormControlElementProps),
      id: (controlConfig as FormControlElementProps).id || controlKey,
      name: controlKey,
    });
  });

  return formElementsArray.map((control) => {
    switch (control.fieldType) {
      case 'checkbox':
        return renderCheckboxField({ control, inputChangedHandler });
      case 'password':
        return renderPasswordField({ control, inputChangedHandler });
      case 'radio':
        return renderRadioButtons({ control, inputChangedHandler });
      case 'select':
        return renderSelectField({ control, inputChangedHandler });
      case 'text':
      case 'email':
        return renderTextInputField({ control, inputChangedHandler });
      case 'textarea':
        return renderTextAreaField({ control, inputChangedHandler });
      case 'email-phone':
        return renderEmailPhoneInputField({ control, inputChangedHandler });
      case 'number':
        return renderTextInputField({ control, inputChangedHandler });
      default:
        return null;
    }
  });
};

export const getFormValues = (controls: FormConfigProps): FormDataValues => {
  const formData: FormDataValues = {};

  Object.entries(controls as FormConfigProps).forEach(([fieldKey, props]) => {
    formData[fieldKey] = (props as FormControlProps).value || '';
  });

  return formData;
};

const checkIsRequiredFieldFilled = (fieldValue: any): boolean => {
  if (typeof fieldValue === 'string') {
    return !!fieldValue;
  }
  if (Array.isArray(fieldValue) && fieldValue.length === 0) {
    return false;
  }
  return fieldValue !== null && fieldValue !== undefined;
};

const checkIfFieldMatchesRegex = (fieldValue: string, pattern: RegExp): boolean => {
  return pattern.test(fieldValue);
};

const checkIsNumberFieldValid = (fieldValue: number, minValue?: number, maxValue?: number): boolean => {
  if (minValue && maxValue) {
    return fieldValue > minValue && fieldValue < maxValue;
  }
  if (minValue) {
    return fieldValue > minValue;
  }
  if (maxValue) {
    return fieldValue < maxValue;
  }
  return true;
};

const getFieldError = (
  fieldValue: any,
  validations: FormControlValidations,
  fieldType?: FormControlFieldType,
): string => {
  let errorMessage = '';

  if (validations.required && !checkIsRequiredFieldFilled(fieldValue)) {
    errorMessage = validations.required.errorText;
  }
  if (validations.regex && fieldValue && !checkIfFieldMatchesRegex(fieldValue.toString(), validations.regex.rule)) {
    errorMessage = validations.regex.errorText;
  }
  if (
    fieldType === 'number' &&
    validations.minMaxValue &&
    fieldValue &&
    !checkIsNumberFieldValid(fieldValue as number, validations.minMaxValue.minValue, validations.minMaxValue.maxValue)
  ) {
    errorMessage = validations.minMaxValue.errorText;
  }

  return errorMessage;
};

export const getErrorMessage = (
  value?: FormValue,
  validations?: FormControlValidations,
  fieldType?: FormControlFieldType,
): string => {
  return validations ? getFieldError(value, validations, fieldType) : '';
};

export const initializeFormFields = (
  controls: FormConfigProps,
  config: FormConfig,
  initialValues: FormDataValues = {},
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: any,
): FormConfigProps => {
  const controlsData: FormConfigProps = {};

  Object.entries(config).forEach(([controlKey, controlConfig]) => {
    let errorMessage = '';
    let value: FormValue = controlConfig.value || '';

    if (initialValues.hasOwnProperty(controlKey)) {
      value = initialValues[controlKey];
      errorMessage = getErrorMessage(value, controlConfig.validations, controlConfig.fieldType);
    }

    if (errors && errors.hasOwnProperty(controlKey) && errors[controlKey].length > 0) {
      errorMessage = errors[controlKey][0];
    }

    controlsData[controlKey] = {
      ...controlConfig,
      error: errorMessage,
      touched: !!controls[controlKey]?.touched,
      valid: !errorMessage,
      value,
    };
  });

  return controlsData;
};
