import { FormConfig } from '../../../components/Form';

export interface UserProfileRouteParams {
  id: string;
  schoolId: string;
}

export const USER_PROFILE_CONFIG: FormConfig = {
  name: {
    fieldType: 'text',
    placeholder: `Enter user's name`,
    required: true,
    title: 'Name',
    validations: {
      required: {
        errorText: `Please enter user's name!`,
      },
    },
  },
  phone: {
    fieldType: 'text',
    placeholder: 'Enter phone',
    required: false,
    title: 'Phone',
  },
  email: {
    fieldType: 'text',
    placeholder: 'Enter email',
    required: true,
    title: 'Email',
    validations: {
      required: {
        errorText: `Please enter email!`,
      },
      regex: {
        errorText: 'Please enter a valid e-mail address!',
        rule: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
      },
    },
  },

  school: {
    fieldType: 'select',
    placeholder: 'Select school',
    required: true,
    title: 'School',
    options: [],
    validations: {
      required: {
        errorText: `Please select a school!`,
      },
    },
    disabled: true,
  },
};
