import {
  CampusActionTypes,
  CampusProfileState,
  CLEAR_CURRENT_CAMPUS,
  CREATE_CAMPUS,
  EDIT_CAMPUS,
  SET_CAMPUS,
} from './campus-profile.types';

const initialState: CampusProfileState = {} as CampusProfileState;

export default (state = initialState, action: CampusActionTypes): CampusProfileState => {
  switch (action.type) {
    case CLEAR_CURRENT_CAMPUS: {
      return initialState;
    }
    case CREATE_CAMPUS:
    case EDIT_CAMPUS:
    case SET_CAMPUS: {
      return {
        ...action.payload.data,
      };
    }
    default: {
      return state;
    }
  }
};
