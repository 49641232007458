import { call, CallEffect, put, PutEffect, select, SelectEffect, takeLatest } from 'redux-saga/effects';

import { setRankingsAction } from './rankings.actions';
import { getRankingsRequest } from './rankings.api';
import { LOAD_RANKINGS, LoadRankingsAction, RankingsActionTypes } from './rankings.types';
import { RankingDTO } from '../RankingProfile';
import { SchoolUserRole, selectCurrentSchool } from '../App/Auth';
import { PagedResponseDTO } from '../../shared/constants';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../../shared/state/global-request';

function* loadRankingsTableData(
  action: LoadRankingsAction,
): Generator<
  | CallEffect<PagedResponseDTO<RankingDTO[]>>
  | PutEffect<RankingsActionTypes>
  | PutEffect<GlobalRequestActions>
  | SelectEffect
> {
  const { type: actionType } = action;
  const { schoolId } = (yield select(selectCurrentSchool)) as SchoolUserRole;

  try {
    yield put(setRequestStartedAction(actionType));
    const { pageConfig } = action.payload;
    const { data, pagination, sortOrder } = (yield call(getRankingsRequest, schoolId, pageConfig)) as PagedResponseDTO<
      RankingDTO[]
    >;
    yield put(setRankingsAction({ data, pagination, sortOrder }));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

export default function* rankingsSaga(): Generator {
  yield takeLatest(LOAD_RANKINGS, loadRankingsTableData);
}
