import React, { FunctionComponent, useEffect, useState } from 'react';

import { Accordion } from '../../../components/Accordion/Accordion';
import { HelpFAQDto } from '../help.dto';
import { useDispatch, useSelector } from 'react-redux';
import { selectFaqData } from '../help.selectors';
import { loadFAQAction } from '../help.actions';
import { getHtmlFromJSON } from '../../../shared/helpers';

import styles from './HelpFaq.module.scss';

interface AccordionProps {
  searchTerm?: string | undefined;
}

export const HelpFaq: FunctionComponent<AccordionProps> = ({ searchTerm }) => {
  const dispatch = useDispatch();
  const helpFaqData = useSelector(selectFaqData);
  const [accordionData, setAccordionData] = useState<HelpFAQDto[]>([]);

  useEffect(() => {
    dispatch(loadFAQAction());
  }, [dispatch]);

  const handleSearch = (): HelpFAQDto[] => {
    if (!searchTerm) return helpFaqData;
    return helpFaqData.filter((item) => {
      const descriptionHtml = getHtmlFromJSON(item.description).toLowerCase();
      return item.categoryTitle.toLowerCase().includes(searchTerm) || descriptionHtml.includes(searchTerm);
    });
  };

  useEffect(() => {
    const filteredData = handleSearch();
    setAccordionData(filteredData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  useEffect(() => {
    if (helpFaqData.length > 1) {
      setAccordionData(helpFaqData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [helpFaqData]);

  return (
    <div className={styles.accordionSection}>
      <div className={styles.mbaAccordion}>
        {accordionData.map((item: HelpFAQDto) => (
          <Accordion title={item.categoryTitle} description={item.description} key={item.id} />
        ))}
        {accordionData.length === 0 && searchTerm && (
          <h4>We couldn&apos;t find any matches for &quot;{searchTerm}&quot;</h4>
        )}
      </div>
    </div>
  );
};
