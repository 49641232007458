import React, { FunctionComponent } from 'react';

import LoginDetails from './LoginDetails';
import PersonalDetails from './PersonalDetails';
import Typography, { TypographyVariants } from '../../components/Typography';

const Index: FunctionComponent = () => (
  <>
    <Typography component="h1" variant={TypographyVariants.h1}>
      My Profile
    </Typography>
    <div className="mba-background--white">
      <PersonalDetails />
      <hr />
      <LoginDetails />
    </div>
  </>
);

export default Index;
