const FT_LEGAL_PRIVACY_URL = 'http://help.ft.com/help/legal-privacy';
const BUSED_SURVEYS_PRIVACY_URL = 'https://busedsurveys.ft.com/rankingsprivacypolicy';

export const FOOTER_LINKS = [
  {
    key: 'cookies',
    title: 'Cookies',
    url: `${FT_LEGAL_PRIVACY_URL}/cookies/`,
  },
  {
    key: 'copyright',
    title: 'Copyright',
    url: `${FT_LEGAL_PRIVACY_URL}/copyright-policy/`,
  },
  {
    key: 'privacy',
    title: 'Privacy',
    url: BUSED_SURVEYS_PRIVACY_URL,
  },
  {
    key: 'terms-and-conditions',
    title: 'Terms & Conditions',
    url: `${FT_LEGAL_PRIVACY_URL}/terms-conditions/`,
  },
];
