import {
  DOWNLOAD_SPREADSHEET,
  DownloadRankingSpreadsheetAction,
  DOWNLOAD_TEMPLATE,
  DownloadSpreadsheetTemplateAction,
  UPDATE_PARTICIPATION,
  UpdateParticipationAction,
  UPLOAD_SPREADSHEET,
  UploadSpreadsheetAction,
  DOWNLOAD_TIMELINE_SPREADSHEET,
  DownloadTimelineSpreadsheetAction,
  DOWNLOAD_STEP_ATTACHMENT,
  DownloadStepAttachmentAction,
} from './ranking-timeline.types';
import { RankingSpreadsheetFileDTO, TimelineAttachmentDTO, TimelineSpreadsheetDTO } from './ranking-timeline.dto';
import { ParticipationStatus } from './RankingTimeline.constants';

export const downloadSpreadsheetAction = (
  rankingId: number,
  schoolId: number,
  spreadsheetType: string,
): DownloadRankingSpreadsheetAction => ({
  type: DOWNLOAD_SPREADSHEET,
  payload: { rankingId, schoolId, spreadsheetType },
});

export const downloadSpreadsheetTemplateAction = (
  rankingId: number,
  spreadsheetType: string,
): DownloadSpreadsheetTemplateAction => ({
  type: DOWNLOAD_TEMPLATE,
  payload: { rankingId, spreadsheetType },
});

export const updateParticipationAction = (
  rankingId: number,
  schoolId: number,
  status: ParticipationStatus,
): UpdateParticipationAction => ({
  type: UPDATE_PARTICIPATION,
  payload: { rankingId, schoolId, status },
});

export const uploadSpreadsheetAction = (data: RankingSpreadsheetFileDTO): UploadSpreadsheetAction => ({
  type: UPLOAD_SPREADSHEET,
  payload: data,
});

export const downloadStepAttachmentAction = (data: TimelineAttachmentDTO): DownloadStepAttachmentAction => ({
  type: DOWNLOAD_STEP_ATTACHMENT,
  payload: data,
});

export const downloadTimelineSpreadsheetAction = (data: TimelineSpreadsheetDTO): DownloadTimelineSpreadsheetAction => ({
  type: DOWNLOAD_TIMELINE_SPREADSHEET,
  payload: data,
});
