import React, { FunctionComponent, useEffect, useState } from 'react';

import TimelineIndicator from './TimelineIndicator';
import TimelineStepDates from './TimelineStepDates';
import { RankingTimelineStepBaseProps, TimelineExpandStepStatus } from '../RankingTimeline.constants';
import Grid from '../../../../components/Grid';
import SurveyLink from './AlumniSurverySteps/SurveyLink';
import SurveyLetter from './AlumniSurverySteps/SurveyLetter';
import SurveyResponseRate from './AlumniSurverySteps/ResponseRate';
import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';
import Typography, { TypographyVariants } from '../../../../components/Typography';
import { getHtmlFromJSON } from '../../../../shared/helpers';

interface SurveyStepProps extends RankingTimelineStepBaseProps {
  surveyType: string;
  url?: string;
  content?: string;
}

const SurveyStep: FunctionComponent<SurveyStepProps> = ({
  endDate,
  name,
  startDate,
  status,
  surveyType,
  url,
  content,
  rankingId,
  schoolId,
  instructions,
}) => {
  const [expand, setExpand] = useState<boolean>(false);
  const [isInstructionsModalOpen, setIsInstructionsModalOpen] = useState(false);
  useEffect(() => {
    setExpand(TimelineExpandStepStatus[status]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const handleSchoolSurveyClick = (): void => {
    if (url) {
      window.location.href = url;
    }
  };

  const openInstructionsModal = (): void => {
    setIsInstructionsModalOpen(true);
  };

  const closeInstructionsModal = (): void => {
    setIsInstructionsModalOpen(false);
  };

  const __html = instructions ? getHtmlFromJSON(instructions) : '-';

  return (
    <Grid container key={name + surveyType}>
      <Grid item xs={5}>
        {surveyType !== 'alumniResponseRate' && (
          <TimelineStepDates
            endDate={endDate}
            endDateLabel="End"
            isEndDateRequired={true}
            startDate={startDate}
            startDateLabel="Start"
            isStartDateRequired={true}
          />
        )}
      </Grid>
      <Grid item xs={1} className="mba-no-padding">
        <TimelineIndicator timeIndication={status} />
      </Grid>
      <Grid item xs={6} className="mba-margin-bottom-20 mba-no-padding">
        <div>
          <button
            className="mba-text--accent mba-no-margin mba-text--bold mba-margin-bottom-15 mba-timeline-button--link"
            onClick={(): void => setExpand(!expand)}
          >
            {name}
          </button>
        </div>
        {expand && (
          <>
            {surveyType !== 'school' && (
              <Typography variant={TypographyVariants.link}>
                <button className="mba-button--link" onClick={openInstructionsModal}>
                  Read instructions
                </button>
              </Typography>
            )}
            {isInstructionsModalOpen && (
              <Modal
                title={`Instructions for ${name.toLowerCase()}`}
                isOpen={isInstructionsModalOpen}
                onClose={closeInstructionsModal}
              >
                <Typography className="mba-no-margin" component="p" variant={TypographyVariants.h5}>
                  Instructions
                </Typography>
                <div dangerouslySetInnerHTML={{ __html }} />
              </Modal>
            )}
            {surveyType === 'school' && url && (
              <Button primary text={'Go to survey'} onClick={handleSchoolSurveyClick} />
            )}
            {surveyType === 'alumniLink' && (
              <SurveyLink text={'Copy survey link'} schoolId={schoolId} rankingId={rankingId} />
            )}
            {surveyType === 'alumniLetter' && <SurveyLetter content={content} />}
            {surveyType === 'alumniResponseRate' && <SurveyResponseRate schoolId={schoolId} rankingId={rankingId} />}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default SurveyStep;
