import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ADMIN_USER_CREATE_FORM_CONFIG } from '../UsersList.constants';
import { createNewUserAction } from '../../users.actions';
import { CreateUserDTO, UserDTO } from '../../users.dto';
import { CREATE_USER, EDIT_USER } from '../../users.types';
import Form from '../../../../../components/Form';
import { SelectOption } from '../../../../../components/Select';
import { selectAreRequestsLoading, selectRequestFormErrors } from '../../../../../shared/state/global-request';
import { capitalizeText } from '../../../../../shared/helpers';
import { loadRolesAction, selectRoles } from '../../../../../shared/state/roles';
import { selectUserToken } from '../../../../App/Auth';
import jwtDecode from 'jwt-decode';

type UserFormProps = {
  isEdit?: boolean;
  onFormClose: () => void;
};

const UserForm: FunctionComponent<UserFormProps> = ({ onFormClose }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectAreRequestsLoading([CREATE_USER, EDIT_USER]));
  const formErrors = useSelector(selectRequestFormErrors(CREATE_USER));
  const roles = useSelector(selectRoles('school-users'));
  const userToken = useSelector(selectUserToken);
  const {
    data: { schoolId },
  } = jwtDecode(userToken) as { data: { schoolId: number } };

  useEffect(() => {
    dispatch(loadRolesAction('school-users'));
  }, [dispatch]);

  const roleOptions: SelectOption[] = roles
    ? roles.map((roleName: string) => ({ label: capitalizeText(roleName), value: roleName }))
    : [];

  const defaultFormConfig = {
    ...ADMIN_USER_CREATE_FORM_CONFIG,
    role: {
      ...ADMIN_USER_CREATE_FORM_CONFIG.role,
      options: roleOptions,
    },
  };

  const userInitialData = {} as UserDTO;

  const onUserSaveSubmit = (data: CreateUserDTO): void => {
    dispatch(createNewUserAction(data as CreateUserDTO, schoolId));
  };

  return (
    <Form
      id="admin-user-form"
      isLoading={isLoading}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      config={defaultFormConfig as any}
      onSubmit={onUserSaveSubmit}
      submitButtonText="Create user"
      actions={[
        {
          label: 'Cancel',
          onClick: onFormClose,
        },
      ]}
      initialValues={userInitialData}
      errors={formErrors}
    />
  );
};

export default UserForm;
