import React, { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CAMPUS_CREATE_FORM_CONFIG, CAMPUS_EDIT_FORM_CONFIG } from '../CampusesList.constants';
import {
  CampusDTO,
  CREATE_CAMPUS,
  CreateCampusDTO,
  createNewCampusAction,
  EDIT_CAMPUS,
  editCampusAction,
  EditCampusDTO,
  selectCurrentCampus,
} from '../../../CampusProfile';
import Form, { FormConfig } from '../../../../../../components/Form';
import { selectAreRequestsLoading, selectRequestFormErrors } from '../../../../../../shared/state/global-request';
import { selectStatuses } from '../../../../../../shared/state/statuses';

type CampusFormProps = {
  isEdit?: boolean;
  onFormClose: () => void;
  schoolId: number;
};

const CampusForm: FunctionComponent<CampusFormProps> = ({ onFormClose, isEdit = false, schoolId }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectAreRequestsLoading([CREATE_CAMPUS, EDIT_CAMPUS]));
  const currentCampus = useSelector(selectCurrentCampus);
  const formErrors = useSelector(selectRequestFormErrors(isEdit ? EDIT_CAMPUS : CREATE_CAMPUS));
  const statuses = useSelector(selectStatuses('campus'));

  const onCampusSaveSubmit = (data: CreateCampusDTO | EditCampusDTO): void => {
    if (isEdit) {
      const { id } = currentCampus as EditCampusDTO;
      const { status } = data as EditCampusDTO;
      dispatch(editCampusAction(schoolId, { ...data, id, status: +status } as EditCampusDTO));
    } else {
      dispatch(createNewCampusAction(schoolId, { ...data } as CreateCampusDTO));
    }
  };

  const defaultFormConfig: FormConfig = isEdit
    ? {
        ...CAMPUS_EDIT_FORM_CONFIG,
        status: {
          ...CAMPUS_EDIT_FORM_CONFIG.status,
          options: statuses ? statuses.map(({ key, label }) => ({ label, value: key })) : [],
        },
      }
    : CAMPUS_CREATE_FORM_CONFIG;

  const campusInitialData =
    isEdit && (currentCampus as CampusDTO).status && (currentCampus as CampusDTO).status.key
      ? {
          ...currentCampus,
          status: (currentCampus as CampusDTO).status.key,
        }
      : currentCampus;

  return (
    <Form
      id="campus-form"
      isLoading={isLoading}
      config={defaultFormConfig}
      onSubmit={onCampusSaveSubmit}
      submitButtonText={isEdit ? 'Save' : 'Add campus'}
      actions={[
        {
          label: 'Cancel',
          onClick: onFormClose,
        },
      ]}
      initialValues={campusInitialData}
      errors={formErrors}
    />
  );
};

export default CampusForm;
