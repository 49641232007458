import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import ChangePassword from '../ChangePassword';
import { selectAuthUser } from '../../App/Auth';
import Button, { ButtonSizes } from '../../../components/Button';
import Grid from '../../../components/Grid';
import InputWrapper from '../../../components/InputWrapper';
import Modal from '../../../components/Modal';
import Typography, { TypographyVariants } from '../../../components/Typography';

const LoginDetails: React.FunctionComponent = () => {
  const [showChangePassword, setShowChangePassword] = useState(false);
  const authUser = useSelector(selectAuthUser);

  const handleCloseDialog = (): void => setShowChangePassword(false);

  return (
    <>
      <div className="mba-heading--wrapper mba-heading--table">
        <Typography component="h3" variant={TypographyVariants.h3}>
          Login details
        </Typography>
        <Button
          primary
          text={'Change Password'}
          size={ButtonSizes.big}
          onClick={(): void => setShowChangePassword(true)}
        />
      </div>
      <Grid container>
        <Grid item xs={12} md={5}>
          <InputWrapper title="Password">*******</InputWrapper>
        </Grid>
        <Grid item xs={12} md={5}>
          <InputWrapper title="Last updated">
            {moment(authUser.passwordLastChangeAt).format('Do MMM YYYY')}
          </InputWrapper>
        </Grid>
      </Grid>
      <Modal title="Change password" isOpen={showChangePassword} onClose={handleCloseDialog}>
        <ChangePassword key={String(showChangePassword)} onClose={handleCloseDialog} />
      </Modal>
    </>
  );
};

export default LoginDetails;
