export const LOAD_PRIVACY_DATA = 'LOAD_PRIVACY_DATA';
export const SET_PRIVACY_DATA = 'SET_PRIVACY_DATA';

export interface LoadPrivacyAction {
  type: typeof LOAD_PRIVACY_DATA;
}

export interface SetPrivacyAction {
  type: typeof SET_PRIVACY_DATA;
  payload: string;
}

export type PrivacyPolicyTypes = LoadPrivacyAction | SetPrivacyAction;
