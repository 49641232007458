import { LeadsDTO } from '../Leads/leads.dto';
import { CLEAR_CURRENT_LEAD, LeadActionTypes, LeadProfileState, SET_LEAD } from './lead-profile.types';

const initialState: LeadProfileState = {} as LeadsDTO;

const leadProfileReducer = (state = initialState, action: LeadActionTypes): LeadProfileState => {
  switch (action.type) {
    case CLEAR_CURRENT_LEAD: {
      return initialState;
    }
    case SET_LEAD: {
      return {
        ...state,
        ...action.payload.data,
      };
    }
    default: {
      return state;
    }
  }
};

export default leadProfileReducer;
