import { FormConfig } from '../../../../../components/Form';

export const LOGIN_FORM_CONFIG: FormConfig = {
  email: {
    label: 'E-mail',
    id: 'email-input',
    placeholder: 'Enter your e-mail address',
    fieldType: 'text',
    validations: {
      required: {
        errorText: 'Please enter a valid e-mail address!',
      },
    },
  },
  password: {
    label: 'Password',
    placeholder: 'Enter your password',
    fieldType: 'password',
    validations: {
      required: {
        errorText: `Please enter your password!`,
      },
    },
  },
};

export const LOGIN_ERROR_MESSAGE = 'Email address and/or password is incorrect.';
