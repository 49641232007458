/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { RootState } from '../../../store/reducers';

import { createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const selectRoles = (resourceName: string) =>
  createDeepEqualSelector(
    (state: RootState) => state.roles,
    (roles) => {
      return roles.hasOwnProperty(resourceName) ? roles[resourceName] : [];
    },
  );
