import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import UserForm from './UserForm';
import { USERS_TABLE_COLUMN_DEFINITION, USER_CREATION_CONFIRM_MSG } from './UsersList.constants';
import { loadUsersAction } from '../users.actions';
import { selectUsersPageConfiguration, selectUsersTableData } from '../users.selectors';
import { SchoolUserRole, selectCurrentSchool } from '../../../App/Auth';
import Button, { ButtonIcons, ButtonSizes } from '../../../../components/Button';
import Modal from '../../../../components/Modal';
import Pager, { INITIAL_PAGE } from '../../../../components/Pager';
import Table from '../../../../components/Table';
import Typography, { TypographyVariants } from '../../../../components/Typography';
import { ASCENDING_ORDER_DIRECTION, DESCENDING_ORDER_DIRECTION } from '../../../../shared/constants';
import AnimatedSuccessMessage from '../../../../components/AnimatedSuccessMessage';
import { clearGivenRequestAction, selectRequestStatus } from '../../../../shared/state/global-request';
import { CREATE_USER } from '../users.types';

const UsersList: FunctionComponent = () => {
  const dispatch = useDispatch();
  const pageConfig = useSelector(selectUsersPageConfiguration);
  const usersData = useSelector(selectUsersTableData);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { schoolId } = useSelector(selectCurrentSchool) as SchoolUserRole;
  const createUserReqStatus = useSelector(selectRequestStatus(CREATE_USER));
  const [isConfirmationMsgShown, setIsConfirmationMsgShown] = useState(false);

  const handlePageChanged = (page: number): void => {
    dispatch(loadUsersAction(schoolId, { ...pageConfig, page }));
  };

  const handleSortByChanged = (fieldName: string): void => {
    const orderDirection =
      pageConfig.sortBy !== fieldName
        ? DESCENDING_ORDER_DIRECTION
        : pageConfig.order === ASCENDING_ORDER_DIRECTION
        ? DESCENDING_ORDER_DIRECTION
        : ASCENDING_ORDER_DIRECTION;
    dispatch(loadUsersAction(schoolId, { ...pageConfig, sortBy: fieldName, order: orderDirection }));
  };

  const openModal = (): void => {
    setIsModalOpen(true);
  };

  const closeModal = (): void => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    dispatch(loadUsersAction(schoolId));
  }, [dispatch, schoolId]);

  useEffect(() => {
    if (!createUserReqStatus.isLoading && createUserReqStatus.isSuccess) {
      closeModal();
      const showMsgTimeout = setTimeout(showConfirmationMsg, 800);
      const hideMsgTimeout = setTimeout(hideConfirmationMsg, 3000);
      return () => {
        hideConfirmationMsg();
        clearTimeout(showMsgTimeout);
        clearTimeout(hideMsgTimeout);
      };
    }
  }, [dispatch, createUserReqStatus]);

  const showConfirmationMsg = () => {
    setIsConfirmationMsgShown(true);
  };

  const hideConfirmationMsg = () => {
    setIsConfirmationMsgShown(false);
    dispatch(clearGivenRequestAction(CREATE_USER));
  };

  return (
    <>
      <div className="mba-heading--wrapper">
        <Typography component="p" variant={TypographyVariants.h1} className="mba-heading--title">
          Users
        </Typography>
        {isConfirmationMsgShown && (
          <AnimatedSuccessMessage messageContent={USER_CREATION_CONFIRM_MSG} onClose={hideConfirmationMsg} />
        )}
        <Button
          text="Create new user"
          size={ButtonSizes.big}
          icon={ButtonIcons.plus}
          disabled={isModalOpen}
          primary
          onClick={openModal}
        />
      </div>
      <div className="mba-background--white mba-table-wrapper">
        <Table
          id="admin-users"
          stripes={false}
          columns={USERS_TABLE_COLUMN_DEFINITION}
          data={usersData}
          sortingOptions={{
            order: pageConfig.order || DESCENDING_ORDER_DIRECTION,
            sortBy: pageConfig.sortBy || '',
          }}
          onSortByColumn={handleSortByChanged}
        />
      </div>
      {pageConfig && pageConfig.totalPages !== INITIAL_PAGE && (
        <Pager
          currentPage={pageConfig.page}
          totalPages={pageConfig.totalPages || INITIAL_PAGE}
          onPageChange={handlePageChanged}
        />
      )}
      <Modal title="Create new user" isOpen={isModalOpen} onClose={closeModal}>
        <UserForm onFormClose={closeModal} />
      </Modal>
    </>
  );
};

export default UsersList;
