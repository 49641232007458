import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import FilterGroup, { mapInitialValuesToFiltersConfig } from '../../../../components/FilterGroup';
import { PageFilterConfig, PageSearchQueryConfig } from '../../../../shared/constants';
import { leadsStatuses, LEADS_TABLE_FILTERS, PROGRAMMES_OF_INTEREST } from './LeadsFilters.constants';
import { loadLeadsAction } from '../../leads.actions';
import { CountryDTO } from '../../../../shared/state/countries/countries.dto';
import styles from './LeadsFilters.module.scss';

type LeadsFiltersProps = {
  pageConfig?: PageSearchQueryConfig;
  filtersData?: { countriesData: CountryDTO[] };
};

const convertCountriesId = (countries: CountryDTO[]): { key: number; label: string }[] => {
  return countries.map((item: CountryDTO) => {
    return {
      key: item.id,
      label: item.name,
    };
  });
};

const LeadsFilters: FunctionComponent<LeadsFiltersProps> = ({ pageConfig = {}, filtersData = {} }) => {
  const dispatch = useDispatch();
  const [leadsFilters, setLeadsFilters] = useState({});
  const { countriesData } = filtersData;

  const { filter } = pageConfig;
  useEffect(() => {
    setLeadsFilters(
      mapInitialValuesToFiltersConfig(
        LEADS_TABLE_FILTERS,
        {
          countryId: convertCountriesId(countriesData ?? []),
          status: leadsStatuses,
          programmeOfInterest: PROGRAMMES_OF_INTEREST,
        },
        filter,
        true,
      ),
    );
  }, [filter, filtersData, countriesData]);

  const handleFiltersChange = (newFilters: PageFilterConfig): void => {
    dispatch(
      loadLeadsAction({
        ...pageConfig,
        filter: newFilters,
      }),
    );
  };

  return (
    <div className={`${styles.filtersContainer} sticky-sidebar-container`}>
      <FilterGroup defaultFilters={filter || {}} filtersConfig={leadsFilters} onFiltersChanged={handleFiltersChange} />
    </div>
  );
};

export default LeadsFilters;
