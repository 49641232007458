import React, { ChangeEvent, FunctionComponent, useLayoutEffect, useRef, useState } from 'react';

import {
  CheckboxInputOption,
  CheckboxLabelPositions,
  CheckboxOptionsState,
  CommonCheckboxProps,
} from '../Checkbox.constants';
import Icon from '../../Icon';
import InputWrapper, { InputWrapperProps } from '../../InputWrapper';

import styles from './CheckboxGroup.module.scss';
import { useObjectEqualityEffect } from '../../../shared/helpers.hooks';

type CheckboxGroupProps = {
  isDropdown?: boolean;
  options: CheckboxInputOption[];
};

type Props = CommonCheckboxProps & CheckboxGroupProps & InputWrapperProps;

const CheckboxGroup: FunctionComponent<Props> = ({
  disabled = false,
  error,
  id,
  inline = false,
  isDropdown = false,
  labelPosition = CheckboxLabelPositions.left,
  name,
  onBlur,
  onChange,
  optional = false,
  options,
  required = false,
  shrunken = false,
  subTitle,
  title,
  touched,
  valid,
  verticallyCentered,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [inputsChecked, setInputsChecked] = useState({} as CheckboxOptionsState);
  const checkboxGroupClasses = ['o-forms-input', 'o-forms-input--checkbox'];
  const checkboxLabelClasses: string[] = [];
  const dropdownButtonRef = useRef<HTMLDivElement>(null);
  let dropdownButton = null;

  if (error || (touched && !valid)) {
    checkboxGroupClasses.push('o-forms-input--invalid');
  } else if (touched && valid) {
    checkboxGroupClasses.push('o-forms-input--valid');
  }

  if (inline) {
    checkboxGroupClasses.push('o-forms-input--inline');
  }

  if (labelPosition === CheckboxLabelPositions.right) {
    checkboxLabelClasses.push('o-forms-input__right');
  }

  useObjectEqualityEffect(() => {
    setInputsChecked(
      options?.reduce(
        (acc, checkboxOption: CheckboxInputOption) => ({
          ...acc,
          [checkboxOption.name]: !!checkboxOption.value,
        }),
        {},
      ),
    );
  }, [options]);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    setInputsChecked({
      ...inputsChecked,
      [event.target.name]: event.target.checked,
    });
    onChange && onChange(event);
  };

  const handleClickOutside = (event: MouseEvent): void => {
    if (
      isDropdownOpen &&
      dropdownButtonRef &&
      dropdownButtonRef.current &&
      !dropdownButtonRef.current.contains(event.target as Node)
    ) {
      setIsDropdownOpen(false);
    }
  };

  useLayoutEffect(() => {
    if (isDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return (): void => {
      if (isDropdown) {
        document.removeEventListener('mousedown', handleClickOutside);
      }
    };
  });

  const content = options.map(({ name: checkboxName, text = '' }) => (
    <label key={`${name}-${checkboxName}`} className={checkboxLabelClasses.join(' ')}>
      <input
        type="checkbox"
        name={checkboxName}
        checked={inputsChecked[checkboxName] || false}
        value={inputsChecked[checkboxName] ? inputsChecked[checkboxName].toString() : ''}
        onChange={handleOnChange}
        aria-label={text}
        disabled={disabled}
      />
      <span className="o-forms-input__label" aria-hidden="true">
        {text}
      </span>
    </label>
  ));

  if (isDropdown) {
    checkboxGroupClasses.push(styles.dropdownContent);

    if (isDropdownOpen) {
      checkboxGroupClasses.push(styles.open);
    }

    const selectedOptions = Object.values(options)
      .filter((option) => option.value)
      .map((option) => option.text || option.name);
    const dropdownText =
      selectedOptions.length > 0 ? `${selectedOptions.length} selected` : `Choose ${title?.toLowerCase()}`;

    const onDropdownClick = (event: React.MouseEvent<HTMLInputElement>): void => {
      event.preventDefault();
      setIsDropdownOpen(!isDropdownOpen);
    };

    dropdownButton = (
      <label className="o-forms-field" id="rankings-search-label">
        <div className={['o-forms-input', 'o-forms-input--text', styles.dropdownWrapper].join(' ')}>
          <input className={styles.dropdown} type="button" value={dropdownText} onClick={onDropdownClick} />
          <Icon className={styles.arrowIcon} size={25} name={isDropdownOpen ? 'arrow-up' : 'arrow-down'} />
        </div>
      </label>
    );
  }

  return (
    <InputWrapper
      id={id}
      inline={inline}
      groupedElement
      optional={optional}
      required={required}
      shrunken={shrunken}
      subTitle={subTitle}
      title={title}
      verticallyCentered={verticallyCentered}
    >
      {isDropdown ? (
        <div ref={dropdownButtonRef}>
          {dropdownButton}
          <div className={checkboxGroupClasses.join(' ')}>
            {content}
            {error && <span className="o-forms-input__error">{error}</span>}
          </div>
        </div>
      ) : (
        <div className={checkboxGroupClasses.join(' ')}>
          {content}
          {error && <span className="o-forms-input__error">{error}</span>}
        </div>
      )}
    </InputWrapper>
  );
};

export default CheckboxGroup;
