import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CampusesList from './CampusesList/components';
import SchoolAccreditations from './SchoolAccreditations';
import SchoolLogo from './SchoolLogo';
import { SCHOOL_PROFILE_CONFIG } from './SchoolProfile.constants';
import { editSchoolProfileAction, loadSchoolAction } from '../school-profile.actions';
import { EditSchoolDTO, SchoolDTO } from '../school-profile.dto';
import { selectCurrentSchoolProfile } from '../school-profile.selectors';
import { EDIT_SCHOOL_PROFILE } from '../school-profile.types';
import { SchoolUserRole, selectCurrentSchool } from '../../App/Auth';
import EditableGroup from '../../../components/EditableGroup';
import { FormDataValues } from '../../../components/Form';
import Typography, { TypographyVariants } from '../../../components/Typography';
import { selectRequestErrors, setRequestSucceededAction } from '../../../shared/state/global-request';
import { loadResourcesAction } from '../../../shared/state/resources';
import { loadStatusesAction } from '../../../shared/state/statuses';

const SchoolProfile: FunctionComponent = () => {
  const dispatch = useDispatch();
  const currentSchool = useSelector(selectCurrentSchoolProfile) as SchoolDTO;
  const { schoolId } = useSelector(selectCurrentSchool) as SchoolUserRole;
  const formErrors = useSelector(selectRequestErrors(EDIT_SCHOOL_PROFILE));
  const { defaultCampusId, accreditations, logo } = currentSchool as SchoolDTO;

  useEffect(() => {
    dispatch(loadResourcesAction('accreditations'));
    dispatch(loadStatusesAction('campus'));
  }, [dispatch]);

  useEffect(() => {
    if (schoolId) {
      dispatch(loadSchoolAction(schoolId));
    }
  }, [dispatch, schoolId]);

  const updateSchoolProfile = (data: FormDataValues): void => {
    dispatch(editSchoolProfileAction({ ...data, id: schoolId } as EditSchoolDTO));
  };

  const resetFormState = (): void => {
    dispatch(setRequestSucceededAction(EDIT_SCHOOL_PROFILE));
  };

  return (
    <>
      <div className="mba-heading--wrapper">
        <Typography component="p" variant={TypographyVariants.h1} className="mba-heading--title">
          School profile
        </Typography>
      </div>
      <div className="mba-background--white">
        <EditableGroup<SchoolDTO>
          columnSize={6}
          data={currentSchool}
          editButtonLabel="Edit details"
          formConfig={SCHOOL_PROFILE_CONFIG}
          formErrors={formErrors}
          heading={
            <Typography component="p" variant={TypographyVariants.h3} className="mba-heading--title-2">
              Details
            </Typography>
          }
          onEditSubmit={updateSchoolProfile}
          onEditCancel={resetFormState}
          prefixColumn={{
            content: <SchoolLogo image={logo} />,
            columnSize: { xs: 12, md: 4, lg: 3 },
          }}
        />
        <hr className="mba-separator" />
        <SchoolAccreditations
          schoolId={schoolId}
          schoolAccreditations={accreditations?.map((accreditation) => +accreditation.key)}
        />
        <hr className="mba-separator" />
        {schoolId && <CampusesList defaultCampusId={defaultCampusId || 0} schoolId={schoolId} />}
      </div>
    </>
  );
};

export default SchoolProfile;
