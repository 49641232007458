import { call, CallEffect, put, PutEffect, takeEvery } from 'redux-saga/effects';

import {
  downloadAttachmentRequest,
  downloadSpreadsheetRequest,
  downloadSpreadsheetTemplateRequest,
  downloadTimelineSpreadsheetRequest,
  updateRankingParticipationStatusRequest,
  uploadSpreadsheetRequest,
} from './ranking-timeline.api';
import {
  DOWNLOAD_SPREADSHEET,
  DownloadRankingSpreadsheetAction,
  DOWNLOAD_TEMPLATE,
  DownloadSpreadsheetTemplateAction,
  RankingTimelineActionTypes,
  UPDATE_PARTICIPATION,
  UpdateParticipationAction,
  UPLOAD_SPREADSHEET,
  UploadSpreadsheetAction,
  DOWNLOAD_TIMELINE_SPREADSHEET,
  DownloadTimelineSpreadsheetAction,
  DOWNLOAD_STEP_ATTACHMENT,
  DownloadStepAttachmentAction,
} from './ranking-timeline.types';
import { loadRankingTimelineAction } from '../ranking-profile.actions';
import { RankingActionTypes } from '../ranking-profile.types';
import { BasicResponseDTO } from '../../../shared/constants';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../../../shared/state/global-request';

function* updateParticipationStatusSaga(
  action: UpdateParticipationAction,
): Generator<
  CallEffect<BasicResponseDTO> | PutEffect<GlobalRequestActions | RankingActionTypes | RankingTimelineActionTypes>
> {
  const {
    type: actionType,
    payload: { rankingId, schoolId, status },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(updateRankingParticipationStatusRequest, rankingId, schoolId, status);
    yield put(loadRankingTimelineAction(rankingId));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* downloadSpreadsheetSaga(
  action: DownloadRankingSpreadsheetAction,
): Generator<CallEffect<BasicResponseDTO> | PutEffect<GlobalRequestActions | RankingTimelineActionTypes>> {
  const {
    type: actionType,
    payload: { rankingId, schoolId, spreadsheetType },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(downloadSpreadsheetRequest, rankingId, schoolId, spreadsheetType);
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* downloadSpreadsheetTemplateSaga(
  action: DownloadSpreadsheetTemplateAction,
): Generator<
  CallEffect<BasicResponseDTO> | PutEffect<GlobalRequestActions | RankingActionTypes | RankingTimelineActionTypes>
> {
  const {
    type: actionType,
    payload: { rankingId, spreadsheetType },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(downloadSpreadsheetTemplateRequest, rankingId, spreadsheetType);
    yield put(loadRankingTimelineAction(rankingId));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}
function* uploadSpreadsheetSaga(
  action: UploadSpreadsheetAction,
): Generator<
  CallEffect<BasicResponseDTO> | PutEffect<GlobalRequestActions | RankingActionTypes | RankingTimelineActionTypes>
> {
  const { type: actionType, payload: data } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(uploadSpreadsheetRequest, data);
    yield put(setRequestSucceededAction(actionType));
    yield put(loadRankingTimelineAction(data.rankingId));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* downloadTimelineSpreadsheetSaga(
  action: DownloadTimelineSpreadsheetAction,
): Generator<CallEffect<BasicResponseDTO> | PutEffect<GlobalRequestActions | RankingTimelineActionTypes>> {
  const {
    type: actionType,
    payload: { rankingId, type },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(downloadTimelineSpreadsheetRequest, rankingId, type);
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* downloadStepAttachmentSaga(
  action: DownloadStepAttachmentAction,
): Generator<CallEffect<Blob> | PutEffect<GlobalRequestActions>> {
  const {
    type: actionType,
    payload: { rankingId, stepId },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(downloadAttachmentRequest, rankingId, stepId);
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

export default function* rankingProfileSaga(): Generator {
  yield takeEvery(UPDATE_PARTICIPATION, updateParticipationStatusSaga);
  yield takeEvery(DOWNLOAD_TEMPLATE, downloadSpreadsheetTemplateSaga);
  yield takeEvery(DOWNLOAD_SPREADSHEET, downloadSpreadsheetSaga);
  yield takeEvery(UPLOAD_SPREADSHEET, uploadSpreadsheetSaga);
  yield takeEvery(DOWNLOAD_TIMELINE_SPREADSHEET, downloadTimelineSpreadsheetSaga);
  yield takeEvery(DOWNLOAD_STEP_ATTACHMENT, downloadStepAttachmentSaga);
}
