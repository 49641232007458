import { EditSchoolDTO, EditSchoolLogoDTO, SchoolDTO } from './school-profile.dto';

export const LOAD_SCHOOL = 'LOAD_SCHOOL';
export const SET_SCHOOL = 'SET_SCHOOL';
export const EDIT_SCHOOL_ACCREDITATIONS = 'EDIT_SCHOOL_ACCREDITATIONS';
export const EDIT_SCHOOL_PROFILE = 'EDIT_SCHOOL_PROFILE';
export const EDIT_SCHOOL_LOGO = 'EDIT_SCHOOL_LOGO';

export type SchoolProfileState = EditSchoolDTO | SchoolDTO;

export interface LoadSchoolAction {
  type: typeof LOAD_SCHOOL;
  payload: { id: number };
}

export interface EditSchoolAccreditationsAction {
  type: typeof EDIT_SCHOOL_ACCREDITATIONS;
  payload: { accreditationIds: number[]; schoolId: number };
}

export interface EditSchoolProfileAction {
  type: typeof EDIT_SCHOOL_PROFILE;
  payload: { data: EditSchoolDTO };
}

export interface EditSchoolLogoAction {
  type: typeof EDIT_SCHOOL_LOGO;
  payload: { data: EditSchoolLogoDTO };
}

export interface SetSchoolAction {
  type: typeof SET_SCHOOL;
  payload: { data: SchoolDTO };
}

export type SchoolActionTypes =
  | EditSchoolAccreditationsAction
  | EditSchoolLogoAction
  | EditSchoolProfileAction
  | LoadSchoolAction
  | SetSchoolAction;
