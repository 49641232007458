import React, { FunctionComponent } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

export interface ProtectedRouteProps extends RouteProps {
  isAuthenticated: boolean;
  isAllowed: boolean;
  redirectedPath: string;
  authenticationPath: string;
}

const ProtectedRoute: FunctionComponent<ProtectedRouteProps> = ({
  authenticationPath,
  component,
  isAuthenticated,
  isAllowed,
  redirectedPath,
  ...rest
}) => {
  let redirectPath = '';
  if (!isAuthenticated) {
    redirectPath = authenticationPath;
  }

  if (isAuthenticated && !isAllowed) {
    redirectPath = redirectedPath;
  }

  if (redirectPath) {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const renderComponent = () => <Redirect to={{ pathname: redirectPath }} />;
    return <Route {...rest} component={renderComponent} render={undefined} />;
  } else {
    return <Route {...rest} component={component} />;
  }
};

export default ProtectedRoute;
