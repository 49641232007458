import { DashboardRankingsDto } from './dahboards.dto';
import {
  LoadDashboardDataAction,
  LOAD_DASHBOARD_DATA,
  SetDashboardDataAction,
  SET_DASHBOARD_DATA,
} from './dashboards.types';

export const loadDashboardsDataAction = (schoolId: number): LoadDashboardDataAction => ({
  type: LOAD_DASHBOARD_DATA,
  payload: { schoolId },
});

export const setDashboardsDataAction = (data: DashboardRankingsDto): SetDashboardDataAction => ({
  type: SET_DASHBOARD_DATA,
  payload: { data },
});
