import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';

import { FormConfig, FormConfigProps, FormControlProps, FormDataValues, FormErrors, FormValue } from './Form.constants';
import { generateFormFields, getErrorMessage, getFormValues, initializeFormFields } from './Form.helpers';
import Button, { ButtonSizes, ButtonTypes } from '../Button';

interface AdditionalFormAction {
  label: string;
  onClick: () => void;
}

type FormProps = {
  id: string;
  isLoading?: boolean;
  config: FormConfig;
  customActions?: ReactNode;
  errors: FormErrors;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialValues?: any;
  actions?: AdditionalFormAction[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (data: any) => void;
  onValuesChanged?: (data: FormDataValues) => void;
  submitButtonText?: string;
};

const Form: FunctionComponent<FormProps> = ({
  id,
  isLoading = false,
  config,
  customActions,
  errors = {},
  initialValues = {},
  actions,
  onSubmit,
  onValuesChanged,
  submitButtonText = 'Submit',
}) => {
  const [controls, setControls] = useState({} as FormConfigProps);
  const [isFormInvalid, setIsFormInvalid] = useState(true);

  useEffect(() => {
    const controlsData = initializeFormFields(controls, config, initialValues, errors);
    const hasError = Object.values(controlsData).some((control) => control && !control.valid);
    setIsFormInvalid(hasError);
    setControls(controlsData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config, initialValues, errors]);

  const inputChangedHandler = (name: string, value: FormValue): void => {
    const oldControls: FormConfigProps = controls ? { ...controls } : {};
    const error = getErrorMessage(
      value,
      (controls[name] as FormControlProps).validations,
      (controls[name] as FormControlProps).fieldType,
    );

    const currentControl = {
      [name]: {
        ...(oldControls[name] as FormControlProps),
        error,
        touched: true,
        valid: !error,
        value,
      },
    };

    const updatedControls: FormConfigProps = {
      ...oldControls,
      ...currentControl,
    };

    const hasError = Object.values(updatedControls).some((control) => control && !control.valid);
    setIsFormInvalid(hasError);
    setControls(updatedControls);

    onValuesChanged && onValuesChanged(getFormValues(updatedControls));
  };

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    onSubmit(getFormValues(controls));
  };

  return (
    <form id={id} onSubmit={handleFormSubmit} data-error={isFormInvalid}>
      {generateFormFields(controls, inputChangedHandler)}
      {customActions ? (
        customActions
      ) : (
        <div className="mba-actions">
          {actions &&
            actions.map(({ label, onClick }, index: number) => (
              <Button key={`${id}-action-${index}`} text={label} size={ButtonSizes.big} onClick={onClick} />
            ))}
          <Button
            isLoading={isLoading}
            text={submitButtonText}
            size={ButtonSizes.big}
            primary
            type={ButtonTypes.submit}
            disabled={isLoading || isFormInvalid}
          />
        </div>
      )}
    </form>
  );
};

export default Form;
