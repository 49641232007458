import React, { FunctionComponent } from 'react';

import styles from './AnimatedSuccessMessage.module.scss';
import Message from '../Message';
import { MessageType } from '../Message/Message.constants';

type AnimatedSuccessMessage = {
  messageContent: string;
  onClose: () => void;
  id?: string;
  isActive?: boolean;
};

const AnimatedSuccessMessage: FunctionComponent<AnimatedSuccessMessage> = ({
  messageContent,
  onClose,
  id = '',
  isActive = false,
}) => {
  const accountSetupStyles: string[] = [];
  if (id === 'accountSetup') {
    accountSetupStyles.push(styles.accountSetup);
  }

  if (isActive) {
    accountSetupStyles.push(styles.slideSuccess);
  }

  return (
    <Message
      type={MessageType.SUCCESS}
      content={messageContent}
      customClassess={`${styles.successMessage} ${accountSetupStyles.join(' ')}`}
      onClose={onClose}
    />
  );
};

export default AnimatedSuccessMessage;
