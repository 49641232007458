import { PageConfiguration } from '../containers/App/Main/components/PortalLayout';

export const JWT_TOKEN_COOKIE_NAME = `SCHOOL_PORTAL_${process.env.REACT_APP_JWT_COOKIE_NAME}` || '';

export const CURRENT_SCHOOL_ID = 'CURRENT_SCHOOL_ID';

export const ASCENDING_ORDER_DIRECTION = 'ASC';

export const DESCENDING_ORDER_DIRECTION = 'DESC';

export type OrderDirection = 'ASC' | 'DESC';

export interface BasicResponseDTO {
  message: string;
}

export interface DefaultResponseDTO<T> {
  message: string;
  data: T;
}

export interface Resource {
  key: number;
  label: string;
  shortLabel?: string;
}

export interface Status extends Resource {
  color?: string;
}

export interface Pagination {
  page: number;
  limit: number;
  totalCount: number;
  totalPages: number;
}

export interface SortOrder {
  order: OrderDirection;
  sortBy: string;
}

export interface PagedResponseDTO<T> extends DefaultResponseDTO<T> {
  pagination: Pagination;
  sortOrder: SortOrder;
}

export interface TableListData<T> {
  data: T[];
  errorMessage?: string;
  filter?: PageFilterConfig;
  pagination: Pagination;
  search?: string;
  sortOrder: SortOrder;
}

export type PageProps = PageConfiguration;

export interface PageFilterConfig {
  [key: string]: string[];
}

export interface PageSearchQueryConfig {
  filter?: PageFilterConfig;
  limit?: number;
  order?: OrderDirection;
  page?: number;
  sortBy?: string;
  totalPages?: number;
  search?: string;
  roles?: string[];
  statuses?: number[];
}

export const DEFAULT_TIMEZONE = 'Europe/London';

export const DEFAULT_DATE_FORMAT = 'MMM DD YYYY';

export const SEARCH_DEBOUNCE_TIME = 500;

export const FORBIDDEN_PATH = '/auth/forbidden';

export const LOGIN_PATH = '/auth/login';

export const SERVER_UNAVAILABLE_PATH = '/server-unavailable';

export const INACTIVE_USER = 'Your account is inactive. You cannot proceed further. Contact your administrator.';

export const FORBIDDEN_MESSAGE = '403 - Forbidden';

export const DATETIME_FORMAT_REGEX =
  /(19|20)[0-9][0-9]-(0[0-9]|1[0-2])-(0[1-9]|([12][0-9]|3[01]))T([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]/;

export const PHONE_REGEX = /^[0-9\s+-.()]+$/;

export const TERMS_ACCEPTED_STATUS = 1;

export enum Roles {
  Admin = 'admin',
  Coordinator = 'coordinator',
}

export const DEFAULT_SPREADSHEET_UPLOAD_FAILED_ERROR =
  'Failed to upload spreadsheet. Please, check the file contents and, if the file is derived from a template, make sure you don’t change any column name/order.';

export interface WindowSize {
  width: number;
  height: number;
  isMobile: boolean;
}
