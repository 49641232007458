import { Resource } from '../../constants';

export const LOAD_RESOURCES = 'LOAD_RESOURCES';
export const SET_RESOURCES = 'SET_RESOURCES';

export type ResourcesState = {
  [key: string]: Resource[];
};

export interface ResourceQueryProps {
  name: string;
  value: string | number;
}

export interface LoadResourcesAction {
  type: typeof LOAD_RESOURCES;
  payload: {
    queryProps?: ResourceQueryProps;
    resourceName: string;
  };
}

export interface SetResourcesAction {
  type: typeof SET_RESOURCES;
  payload: {
    data: Resource[];
    resourceName: string;
  };
}

export type ResourcesActionTypes = LoadResourcesAction | SetResourcesAction;
