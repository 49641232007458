import { call, CallEffect, put, PutEffect, SelectEffect, takeLatest } from 'redux-saga/effects';

import {
  EDIT_SCHOOL_ACCREDITATIONS,
  EDIT_SCHOOL_LOGO,
  EDIT_SCHOOL_PROFILE,
  EditSchoolAccreditationsAction,
  EditSchoolLogoAction,
  EditSchoolProfileAction,
  LOAD_SCHOOL,
  LoadSchoolAction,
  SchoolActionTypes,
} from './school-profile.types';
import { BasicResponseDTO, DefaultResponseDTO } from '../../shared/constants';
import { SchoolDTO } from './school-profile.dto';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../../shared/state/global-request';
import {
  getSchoolRequest,
  updateSchoolAccreditations,
  updateSchoolLogoRequest,
  updateSchoolRequest,
} from './school-profile.api';
import { loadSchoolAction, setSchoolAction } from './school-profile.actions';

function* loadSchoolSaga(
  action: LoadSchoolAction,
): Generator<CallEffect<DefaultResponseDTO<SchoolDTO>> | PutEffect<GlobalRequestActions | SchoolActionTypes>> {
  const {
    type: actionType,
    payload: { id },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { data } = (yield call(getSchoolRequest, id)) as DefaultResponseDTO<SchoolDTO>;
    yield put(setSchoolAction(data));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* editSchoolProfileSaga(
  action: EditSchoolProfileAction,
): Generator<PutEffect<GlobalRequestActions | SchoolActionTypes> | CallEffect<BasicResponseDTO> | SelectEffect> {
  const {
    type: actionType,
    payload: { data },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(updateSchoolRequest, data);
    yield put(setRequestSucceededAction(actionType));
    yield put(loadSchoolAction(data.id));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* editSchoolAccreditationsSaga(
  action: EditSchoolAccreditationsAction,
): Generator<PutEffect<GlobalRequestActions | SchoolActionTypes> | CallEffect<BasicResponseDTO> | SelectEffect> {
  const {
    type: actionType,
    payload: { accreditationIds, schoolId },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(updateSchoolAccreditations, schoolId, accreditationIds);
    yield put(setRequestSucceededAction(actionType));
    yield put(loadSchoolAction(schoolId));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* editSchoolLogoSaga(
  action: EditSchoolLogoAction,
): Generator<PutEffect<GlobalRequestActions | SchoolActionTypes> | CallEffect<BasicResponseDTO> | SelectEffect> {
  const {
    type: actionType,
    payload: { data },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(updateSchoolLogoRequest, data);
    yield put(setRequestSucceededAction(actionType));
    yield put(loadSchoolAction(data.id));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

export default function* schoolProfileSaga(): Generator {
  yield takeLatest(LOAD_SCHOOL, loadSchoolSaga);
  yield takeLatest(EDIT_SCHOOL_ACCREDITATIONS, editSchoolAccreditationsSaga);
  yield takeLatest(EDIT_SCHOOL_LOGO, editSchoolLogoSaga);
  yield takeLatest(EDIT_SCHOOL_PROFILE, editSchoolProfileSaga);
}
