import React, { FunctionComponent, useEffect, useState } from 'react';
import { getSurveyResponseRateRequest } from '../../ranking-timeline.api';
import TimelineProgressBar from '../TimelineProgressBar';
import { SurveyResponseRateDTO } from '../../ranking-timeline.dto';
import moment from 'moment';

type SurveyResponseRateProps = {
  schoolId: number;
  rankingId: number;
};

const SurveyResponseRate: FunctionComponent<SurveyResponseRateProps> = ({ rankingId, schoolId }) => {
  const [rateData, setRateData] = useState<SurveyResponseRateDTO | null>(null);

  useEffect(() => {
    const fetchRateData = async () => {
      const data = await getSurveyResponseRateRequest(rankingId, schoolId);
      setRateData(data);
    };
    fetchRateData();
  }, [rankingId, schoolId]);

  return (
    <>
      {rateData ? (
        <TimelineProgressBar
          label=""
          progress={{ current: rateData?.value, total: rateData?.total }}
          dateUpdated={moment(rateData.lastUpdatedAt).format('ddd D MMM, HH:mm')}
        />
      ) : (
        <span className="mba-font--16" style={{ color: '#afafaf' }}>
          The response rate is not available yet
        </span>
      )}
    </>
  );
};

export default SurveyResponseRate;
