import React, { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';

import AuthLayout from './AuthLayout';
import {
  COMMON_ORIGAMI_COMPONENTS,
  INTERNAL_ORIGAMI_COMPONENTS,
  ORIGAMI_CSS_URL,
  ORIGAMI_JS_URL,
} from './Main.constants';
import PortalLayout from './PortalLayout';
import PrivacyPolicy from './PrivacyPolicy';
import { checkForValidToken, selectIsTokenVerified, VALIDATE_USER_TOKEN } from '../../Auth';
import NotFound from '../../NotFound';
import ServerUnavailable from '../../ServerUnavailable';
import PageLoader from '../../../../components/PageLoader';
import { JWT_TOKEN_COOKIE_NAME } from '../../../../shared/constants';
import { mapOrigamiComponentsToQuery, redirectUserToLogin } from '../../../../shared/helpers';
import { selectRequestIsLoading } from '../../../../shared/state/global-request';
import { getCookie } from '../../../../shared/web-storage';

const commonComponent = mapOrigamiComponentsToQuery(COMMON_ORIGAMI_COMPONENTS);
const internalComponent = mapOrigamiComponentsToQuery(INTERNAL_ORIGAMI_COMPONENTS);

const Main: React.FunctionComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isTokenVerified = useSelector(selectIsTokenVerified);
  const isLoading = useSelector(selectRequestIsLoading(VALIDATE_USER_TOKEN));

  useEffect(() => {
    if (history.location.pathname !== '/privacy-policy') {
      const cookieToken = getCookie(JWT_TOKEN_COOKIE_NAME);

      if (!isTokenVerified) {
        if (cookieToken) {
          dispatch(checkForValidToken(cookieToken));
        } else {
          redirectUserToLogin();
        }
      }
    }
  }, [dispatch, history.location.pathname, isTokenVerified]);

  return (
    <HelmetProvider>
      <Helmet>
        <link rel="stylesheet" href={`${ORIGAMI_CSS_URL}${commonComponent}&brand=internal&system_code=build-service`} />
        <link
          rel="stylesheet"
          href={`${ORIGAMI_CSS_URL}${internalComponent}&brand=internal&system_code=build-service`}
        />
        <script src={`${ORIGAMI_JS_URL}${internalComponent}&system_code=build-service`} defer />
        <title>Business school rankings from the Financial Times - FT.com</title>
      </Helmet>
      <ToastProvider placement="bottom-right" autoDismiss={true}>
        <Switch>
          <Route path="/auth" component={AuthLayout} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/server-unavailable" component={ServerUnavailable} />
          <Route path="/" component={PortalLayout} />
          <Route path="*" component={NotFound} />
        </Switch>
      </ToastProvider>
      {isLoading && <PageLoader />}
    </HelmetProvider>
  );
};

export default Main;
