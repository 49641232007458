import { ColumnDefinition, TableHeaderColumnTypes } from '../../../components/Table';

export const RANKINGS_HISTORY_TABLE_COLUMN_DEFINITION: ColumnDefinition[] = [
  {
    id: 'status',
    title: 'Status',
    type: TableHeaderColumnTypes.status,
    sorting: true,
    width: '90px',
  },
  {
    id: 'name',
    sorting: true,
    title: 'Name',
    type: TableHeaderColumnTypes.link,
    path: '/rankings/:id/timeline',
  },
  {
    id: 'ranked',
    sorting: true,
    title: 'Ranked',
    type: TableHeaderColumnTypes.numeric,
    width: '90px',
  },
  {
    id: 'publicationDate',
    sorting: true,
    title: 'Publication date',
    type: TableHeaderColumnTypes.date,
    width: '160px',
  },
];
