import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CHANGE_PASSWORD_FORM_CONFIG } from './ChangePassword.constants';
import { CHANGE_PASSWORD, changePasswordAction, ChangePasswordDto } from '../../App/Auth';
import Button, { ButtonSizes, ButtonTypes } from '../../../components/Button';
import Form from '../../../components/Form';
import { selectRequestFormErrors, selectRequestIsLoading } from '../../../shared/state/global-request';

const ChangePassword: FunctionComponent<{ onClose: () => void }> = ({ onClose }) => {
  const dispatch = useDispatch();
  const formErrors = useSelector(selectRequestFormErrors(CHANGE_PASSWORD));
  const isLoading = useSelector(selectRequestIsLoading(CHANGE_PASSWORD));
  const [formData] = useState({});
  const [requestInfo, setRequestInfo] = useState({ started: false, finished: false });

  const handleFormSubmit = (values: ChangePasswordDto): void => {
    dispatch(changePasswordAction(values));
  };

  useEffect(() => {
    if (isLoading) {
      setRequestInfo((info) => ({ ...info, started: true }));
    } else if (requestInfo.started) {
      setRequestInfo((info) => ({ ...info, finished: true }));
    }
    // we need to follow isLoading only to catch when the request has finished
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return requestInfo.finished && !Object.keys(formErrors).length ? (
    <>
      <b className="mba-text--b2b" style={{ display: 'block', marginBottom: '28px' }}>
        Your password has been changed successfully.
      </b>
      <Button type={ButtonTypes.button} size={ButtonSizes.normal} text="OK" onClick={onClose} />
    </>
  ) : (
    <Form
      id="password-change-form"
      config={CHANGE_PASSWORD_FORM_CONFIG}
      errors={formErrors}
      onSubmit={handleFormSubmit}
      initialValues={formData}
      isLoading={isLoading}
      customActions={
        <div className="mba-auth-submit-button">
          <Button primary fullWidth type={ButtonTypes.submit} size={ButtonSizes.big} text="Submit" />
        </div>
      }
    />
  );
};

export default ChangePassword;
