import React, { FunctionComponent } from 'react';

import { FormControlElementProps } from '../Form.constants';
import { CheckboxGroup, CheckboxInputOption } from '../../Checkbox';

type CheckboxFieldProps = {
  control: FormControlElementProps;
  inputChangedHandler: (name: string, value: boolean | string[]) => void;
};

const CheckboxField: FunctionComponent<CheckboxFieldProps> = ({ control, inputChangedHandler }) => {
  const { id, error, fieldType, label, name, options, touched, valid, validations, value, ...elementProps } = control;
  const checkboxValues: string[] =
    value && Array.isArray(value) ? value.map((singleValue) => singleValue.toString()) : [];

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name: checkboxOptionName, checked } = event.target;
    let checkedValue: boolean | string[] = checked;

    if (options) {
      const currentValues: string[] = [...checkboxValues];
      const checkboxOptionIndex = currentValues.indexOf(checkboxOptionName);

      if (checked && checkboxOptionIndex < 0) {
        currentValues.push(checkboxOptionName);
      } else if (checkboxOptionIndex >= 0) {
        currentValues.splice(checkboxOptionIndex, 1);
      }

      checkedValue = currentValues;
    }

    inputChangedHandler(name, checkedValue);
  };

  let checkboxOptions: CheckboxInputOption[];
  if (options) {
    checkboxOptions = (options as CheckboxInputOption[]).map((option) => {
      const { name: optionValue } = option;

      return {
        ...option,
        value: checkboxValues.indexOf(optionValue) >= 0,
      };
    });
  } else {
    checkboxOptions = [{ text: label, name, value: !!value }];
  }

  return (
    <div key={`${fieldType}-${id}`}>
      <CheckboxGroup
        id={id}
        name={name}
        required={!!validations?.required}
        title={label}
        error={touched && !valid ? error : ''}
        options={checkboxOptions as CheckboxInputOption[]}
        onChange={handleOnChange}
        {...elementProps}
      />
    </div>
  );
};

export default CheckboxField;
