import { NavigationConfiguration } from '../App/Main/components/PortalLayout';

export interface RankingProfileRouteParams {
  id: string;
}

export const RANKING_PROFILE_MAIN_ROUTE = '/rankings/:id';

export const RANKING_PROFILE_TABS_NAVIGATION: NavigationConfiguration = {
  timeline: {
    label: 'Current timeline',
    route: '/timeline',
  },
  instructions: {
    label: 'Instructions',
    route: '/instructions',
  },
  participationCriteria: {
    label: 'Participation criteria',
    route: '/participation-criteria',
  },
};

export const DOWNLOAD_STEP_ATTACHMENT_ERROR = 'Failed to download attachment';
