import React from 'react';

import Grid from '../../components/Grid';
import ActiveRankings from './components/ActiveRankings';
import RankingsCalendar from './components/RankingsCalendar';

const Home: React.FunctionComponent = () => (
  <Grid container>
    <Grid item xs={12}>
      <h1 className="mba-page-heading-title mba-margin-top-10 mba-margin-bottom-10">Dashboard</h1>
    </Grid>
    <Grid item xs={12} md={8}>
      <ActiveRankings />
    </Grid>
    <Grid item xs={12} md={4}>
      <RankingsCalendar />
    </Grid>
  </Grid>
);

export default Home;
