import { call, CallEffect, put, PutEffect, select, SelectEffect, takeLatest } from 'redux-saga/effects';
import { DefaultResponseDTO, PageSearchQueryConfig } from '../../shared/constants';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../../shared/state/global-request';
import { LoadLeadsAction, loadLeadsAction } from '../Leads';
import { LeadsDTO } from '../Leads/leads.dto';
import { selectLeadsPageConfiguration } from '../Leads/leads.selectors';
import { setLeadProfileAction } from './lead-profile.actions';
//import { SchoolUserRole, selectCurrentSchool } from '../App/Auth';
import { getLeadRequest, updateLeadProfileRequest } from './lead-profile.api';

import {
  EditLeadProfileAction,
  EDIT_LEAD,
  LeadActionTypes,
  LoadLeadProfileAction,
  LOAD_LEAD,
} from './lead-profile.types';

function* editLeadSaga(
  action: EditLeadProfileAction,
): Generator<
  | PutEffect<GlobalRequestActions | LeadActionTypes | LoadLeadsAction>
  | CallEffect<DefaultResponseDTO<LeadsDTO>>
  | SelectEffect
> {
  const {
    type: actionType,
    payload: { data },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(updateLeadProfileRequest, data);
    yield put(setRequestSucceededAction(actionType));
    const pageConfig = (yield select(selectLeadsPageConfiguration)) as PageSearchQueryConfig;
    yield put(loadLeadsAction(pageConfig));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}
function* loadLeadSaga(
  action: LoadLeadProfileAction,
): Generator<CallEffect<DefaultResponseDTO<LeadsDTO>> | PutEffect<GlobalRequestActions | LeadActionTypes>> {
  const {
    type: actionType,
    payload: { id },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { data } = (yield call(getLeadRequest, id)) as DefaultResponseDTO<LeadsDTO>;
    yield put(setLeadProfileAction(data));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}
export default function* leadProfileSaga(): Generator {
  yield takeLatest(EDIT_LEAD, editLeadSaga);
  yield takeLatest(LOAD_LEAD, loadLeadSaga);
}
