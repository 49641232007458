import { call, CallEffect, put, PutEffect, select, SelectEffect, takeEvery, takeLatest } from 'redux-saga/effects';

import { loadSchoolUserAction, loadUsersAction, setUserAction, setUsersAction } from './users.actions';
import {
  activateUserRequest,
  createUserRequest,
  deactivateUserRequest,
  deleteUserRequest,
  getSchoolUserRequest,
  getUsersRequest,
  updateSchoolUserRoleAndPermissionsRequest,
  updateUserRequest,
} from './users.api';
import { UserDTO } from './users.dto';
import { selectUsersPageConfiguration } from './users.selectors';
import {
  CLEAR_CURRENT_USER,
  CREATE_USER,
  CreateUserAction,
  EDIT_USER,
  EditUserAction,
  LOAD_USERS,
  LoadUsersAction,
  UserActionTypes,
  UsersActionTypes,
  DEACTIVATE_USER,
  DeactivateUserAction,
  DELETE_USER,
  DeleteUserAction,
  LOAD_SCHOOL_USER,
  LoadSchoolUserAction,
  UPDATE_SCHOOL_USER_ROLES_AND_PERMISSIONS,
  UpdateSchoolUserRolesAndPermissionsAction,
  ActivateUserAction,
  ACTIVATE_USER,
  SetUserAction,
} from './users.types';
import {
  BasicResponseDTO,
  DefaultResponseDTO,
  PagedResponseDTO,
  PageSearchQueryConfig,
} from '../../../shared/constants';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../../../shared/state/global-request';

function* loadUsersTableData(
  action: LoadUsersAction,
): Generator<CallEffect<PagedResponseDTO<UserDTO[]>> | PutEffect<UsersActionTypes> | PutEffect<GlobalRequestActions>> {
  const { type: actionType } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { pageConfig, schoolId } = action.payload;
    const { data, pagination, sortOrder } = (yield call(getUsersRequest, pageConfig, schoolId)) as PagedResponseDTO<
      UserDTO[]
    >;
    yield put(setUsersAction({ data, pagination, sortOrder }));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* createNewUserSaga(
  action: CreateUserAction,
): Generator<
  PutEffect<GlobalRequestActions | UserActionTypes | UsersActionTypes> | CallEffect<DefaultResponseDTO<UserDTO>>
> {
  const {
    type: actionType,
    payload: { data, schoolId },
  } = action;
  try {
    yield put(setRequestStartedAction(actionType));
    yield call(createUserRequest, data, schoolId);
    yield put(setRequestSucceededAction(actionType));
    yield put(loadUsersAction(schoolId));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* editUserSaga(
  action: EditUserAction,
): Generator<PutEffect<GlobalRequestActions | UsersActionTypes> | CallEffect<BasicResponseDTO> | SelectEffect> {
  const {
    type: actionType,
    payload: { data },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(updateUserRequest, data);
    yield put(setRequestSucceededAction(actionType));
    const pageConfig = (yield select(selectUsersPageConfiguration)) as PageSearchQueryConfig;
    yield put(loadUsersAction(12, pageConfig));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* deactivateUserSaga(
  action: DeactivateUserAction,
): Generator<
  PutEffect<GlobalRequestActions | UsersActionTypes | SetUserAction> | CallEffect<BasicResponseDTO> | SelectEffect
> {
  const {
    type: actionType,
    payload: { userId, schoolId },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(deactivateUserRequest, userId, schoolId);
    const { data } = (yield call(getSchoolUserRequest, userId, schoolId)) as DefaultResponseDTO<UserDTO>;
    yield put(setUserAction(data));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* activateUserSaga(
  action: ActivateUserAction,
): Generator<
  PutEffect<GlobalRequestActions | UsersActionTypes | SetUserAction> | CallEffect<BasicResponseDTO> | SelectEffect
> {
  const {
    type: actionType,
    payload: { userId, schoolId },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(activateUserRequest, userId, schoolId);
    const { data } = (yield call(getSchoolUserRequest, userId, schoolId)) as DefaultResponseDTO<UserDTO>;
    yield put(setUserAction(data));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* deleteUserSaga(
  action: DeleteUserAction,
): Generator<PutEffect<GlobalRequestActions | UsersActionTypes> | CallEffect<BasicResponseDTO> | SelectEffect> {
  const {
    type: actionType,
    payload: { userId, schoolId },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(deleteUserRequest, userId, schoolId);
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* resetUserErrors(): Generator<PutEffect<GlobalRequestActions>> {
  yield put(setRequestSucceededAction(CREATE_USER));
  yield put(setRequestSucceededAction(EDIT_USER));
}

function* loadSchoolUserSaga(
  action: LoadSchoolUserAction,
): Generator<CallEffect<DefaultResponseDTO<UserDTO>> | PutEffect<GlobalRequestActions | UserActionTypes>> {
  const {
    type: actionType,
    payload: { id, schoolId },
  } = action;
  try {
    yield put(setRequestStartedAction(actionType));
    const { data } = (yield call(getSchoolUserRequest, id, schoolId)) as DefaultResponseDTO<UserDTO>;
    yield put(setUserAction(data));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* updateSchoolUserRoleAndPermissionsSaga(
  action: UpdateSchoolUserRolesAndPermissionsAction,
): Generator<PutEffect<GlobalRequestActions | UsersActionTypes> | CallEffect<BasicResponseDTO>> {
  const {
    type: actionType,
    payload: { data, partnershipId, schoolId, userId },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(updateSchoolUserRoleAndPermissionsRequest, data, partnershipId, userId);
    yield put(setRequestSucceededAction(actionType));
    yield put(loadSchoolUserAction(userId, schoolId));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

export default function* usersSaga(): Generator {
  yield takeLatest(CREATE_USER, createNewUserSaga);
  yield takeLatest(LOAD_SCHOOL_USER, loadSchoolUserSaga);
  yield takeLatest(EDIT_USER, editUserSaga);
  yield takeEvery(CLEAR_CURRENT_USER, resetUserErrors);
  yield takeLatest(LOAD_USERS, loadUsersTableData);
  yield takeLatest(DEACTIVATE_USER, deactivateUserSaga);
  yield takeLatest(ACTIVATE_USER, activateUserSaga);
  yield takeLatest(DELETE_USER, deleteUserSaga);
  yield takeLatest(UPDATE_SCHOOL_USER_ROLES_AND_PERMISSIONS, updateSchoolUserRoleAndPermissionsSaga);
}
