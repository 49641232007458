import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { REGISTRATION_API_BASE_URL } from './api.config';
import { error } from '../api';
import { getJWTToken } from '../web-storage';

const api = axios.create({
  baseURL: REGISTRATION_API_BASE_URL,
});

const token = getJWTToken();
if (token) {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

const withToken = (config?: AxiosRequestConfig): AxiosRequestConfig => {
  const token = getJWTToken();

  if (!config)
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    },
  };
};

api.interceptors.request.use((param: AxiosRequestConfig) => ({
  ...param,
  baseURL: REGISTRATION_API_BASE_URL,
}));

export const getCurrentBearerHeader = (): string | number | boolean => {
  return api.defaults.headers.common['Authorization'];
};

export const updateAxiosBearerToken = (newToken: string): void => {
  api.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
};

const success = <T>(response: AxiosResponse<T>): T => {
  if (response.headers['content-type'] === 'application/octet-stream') {
    const contentDisposition = response.headers['content-disposition'];
    const originalFileName = contentDisposition && contentDisposition.split('filename=')[1];

    const filename = originalFileName
      ? decodeURIComponent(originalFileName)
          .replace(/[!@#$%^&*()+=[\]{};':"\\|,<>/?]/g, '')
          .trim()
      : 'leads.xlsx';

    const url = window.URL.createObjectURL(new Blob([response.data as unknown as BlobPart]));
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  }

  return response.data;
};

/**
 * HTTP GET method, used to fetch data `statusCode`: 200.
 *
 * @access public
 * @template T - `TYPE`: expected object.
 * @template R - `RESPONSE`: expected object inside a axios response format.
 * @param {string} url - endpoint you want to reach.
 * @param {AxiosRequestConfig} [config] - axios request configuration.
 * @returns {Promise<T>} HTTP `axios` response payload.
 */
export const getRegistrationRequest = <T, R = AxiosResponse<T>>(
  url: string,
  config?: AxiosRequestConfig,
): Promise<T> => {
  return api.get(url, withToken(config)).then(success).catch(error);
};

/**
 * HTTP PUT method.
 *
 * @access public
 * @template T - `TYPE`: expected object.
 * @template B - `BODY`: body request object.
 * @template R - `RESPONSE`: expected object inside a axios response format.
 * @param {string} url - endpoint you want to reach.
 * @param {B} data - payload to be send as the `request body`,
 * @param {AxiosRequestConfig} [config] - axios request configuration.
 * @returns {Promise<R>} - HTTP [axios] response payload.
 */
export const updateRegistrationRequest = <T, B, R = AxiosResponse<T>>(
  url: string,
  data?: B,
  config?: AxiosRequestConfig,
): Promise<T> => {
  return api.put(url, data, withToken(config)).then(success).catch(error);
};
