import React, { ChangeEvent, Fragment, FunctionComponent, ReactNode, useRef, useState } from 'react';

import Button, { ButtonSizes } from '../Button';
import Modal, { MODAL_ANIMATION_DURATION } from '../Modal';

import styles from './FileUpload.module.scss';
import { getRenamedFile } from '../../shared/helpers';

export interface FileUploadProps {
  errorMessage?: ReactNode | string;
  title?: string;
  isOpen: boolean;
  onClose: () => void;
  onUpload: (file: File) => void;
  isLoading: boolean;
}
const FileUpload: FunctionComponent<FileUploadProps> = ({
  errorMessage,
  isOpen,
  onClose,
  onUpload,
  title,
  isLoading,
}) => {
  const [file, setFile] = useState({} as File);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isFileNameValid, setIsFileNameValid] = useState(false);
  const [isFileSelected, setIsFileSelected] = useState(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const {
      target: { files },
    } = event;

    if (files) {
      if (checkIsFileNameValid(files[0])) {
        setIsFileNameValid(true);
        const renamedFile =
          files[0].name.indexOf('–') > 0 ? getRenamedFile(files[0], encodeURIComponent(files[0].name)) : files[0];
        setFile(renamedFile);
      } else {
        setIsFileNameValid(false);
        setFile({} as File);
      }
      setIsFileSelected(true);
    }
  };

  const handleClose = (): void => {
    onClose();

    setTimeout(() => {
      if (fileInputRef?.current?.files) {
        fileInputRef.current.files = null;
        fileInputRef.current.value = '';
      }

      setFile({} as File);
      setIsFileSelected(false);
    }, MODAL_ANIMATION_DURATION);
  };

  const checkIsFileNameValid = (file: File): boolean => {
    const validFileNamePattern = /^[a-zA-Z0-9-_\s–)(\[\]]+$/;
    const fileName = file.name.split('.')[0];
    return validFileNamePattern.test(fileName);
  };

  return (
    <Modal title={title} isOpen={isOpen} onClose={handleClose}>
      <label htmlFor="file-input" className={styles.label}>
        Choose file
      </label>
      <div className={styles.dropzoneWrapper}>
        <div className={styles.dropzone}>
          {file?.name ? (
            <span className="mba-text--b2b">{file.name}</span>
          ) : (
            <div className="mba-text--center">
              Drag & drop here
              <br />
              or
              <br />
              <span className={styles.linkButton}>Browse</span>
            </div>
          )}
        </div>
        <input
          id="file-input"
          type="file"
          ref={fileInputRef}
          className={styles.dropzoneInput}
          onChange={handleChange}
        />
      </div>
      {isFileSelected && !isFileNameValid && (
        <div className={styles.invalidNameMessage}>
          The filename cannot contain special characters. Please use uppercase letters, lowercase letters, numbers,
          spaces and hyphens only.
        </div>
      )}
      {errorMessage && (
        <div>
          {typeof errorMessage === 'string' ? (
            errorMessage.split('\n').map((line, i) => (
              <Fragment key={i}>
                <span className="mba-text--error">{line}</span>
                <br />
              </Fragment>
            ))
          ) : (
            <span className="mba-text--error">{errorMessage}</span>
          )}
          <br />
          <br />
        </div>
      )}
      <div className="mba-actions">
        <Button text="Cancel" size={ButtonSizes.big} onClick={handleClose} />
        <Button
          primary
          text="Upload"
          size={ButtonSizes.big}
          onClick={(): void => onUpload(file)}
          disabled={!isFileNameValid}
          isLoading={isLoading}
        />
      </div>
    </Modal>
  );
};

export default FileUpload;
