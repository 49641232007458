import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { PASSWORD_SETUP_FORM_CONFIG, USER_ACTIVATION_CONFIRM_MSG } from './AccountSetUp.constants';
import Button, { ButtonSizes, ButtonTypes } from '../../../../../components/Button';
import Grid from '../../../../../components/Grid';
import Form from '../../../../../components/Form';
import Typography, { TypographyVariants } from '../../../../../components/Typography';
import { registerUserAction } from '../../auth.actions';
import {
  selectRequestErrors,
  selectRequestIsLoading,
  selectRequestStatus,
} from '../../../../../shared/state/global-request';
import { REGISTER_USER } from '../../auth.types';
import { selectIsTokenVerified } from '../../auth.selectors';
import { AccountSetUpDTO } from '../../auth.dto';
import AnimatedSuccessMessage from '../../../../../components/AnimatedSuccessMessage';

const AccountSetUp: FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isConfirmationMsgShown, setIsConfirmationMsgShown] = useState(false);

  const isAuth = useSelector(selectIsTokenVerified);
  const formErrors = useSelector(selectRequestErrors(REGISTER_USER, 'email'));
  const isLoading = useSelector(selectRequestIsLoading(REGISTER_USER));
  const registerUserStatus = useSelector(selectRequestStatus(REGISTER_USER));

  const [formData, setFormData] = useState({});

  const { registerToken }: { registerToken: string } = useParams();

  const handleFormSubmit = (values: AccountSetUpDTO): void => {
    setFormData(values);
    const registerData = { ...values, registerToken };
    dispatch(registerUserAction(registerData));
  };

  useEffect(() => {
    if (isAuth) {
      history.push('/');
    }
  }, [isAuth, history]);

  useEffect(() => {
    if (!registerUserStatus.isLoading && registerUserStatus.isSuccess) {
      setIsConfirmationMsgShown(true);
      let loginRedirect: ReturnType<typeof setTimeout>;
      if (Object.keys(formErrors).length === 0) {
        loginRedirect = setTimeout(redirectToLogin, 1500);
      }
      return () => {
        clearTimeout(loginRedirect);
      };
    }
  }, [dispatch, registerUserStatus]);

  const hideConfirmationMsg = () => {
    setIsConfirmationMsgShown(false);
  };

  const redirectToLogin = () => {
    history.push('/auth/login');
  };

  return (
    <Grid container className="mba-auth-form-wrapper">
      <AnimatedSuccessMessage
        messageContent={USER_ACTIVATION_CONFIRM_MSG}
        onClose={hideConfirmationMsg}
        id="accountSetup"
        isActive={isConfirmationMsgShown}
      />

      <Grid item xs={11} sm={9} md={7} lg={5} center className="mba-auth-form">
        <Typography component="p" variant={TypographyVariants.h3} className="mba-auth-heading">
          User account Set-up
        </Typography>

        <Form
          id="account-setup-form"
          config={PASSWORD_SETUP_FORM_CONFIG}
          errors={formErrors}
          onSubmit={handleFormSubmit}
          initialValues={formData}
          isLoading={isLoading}
          customActions={
            <div className="mba-auth-submit-button">
              <Button primary fullWidth type={ButtonTypes.submit} size={ButtonSizes.big} text="Submit" />
            </div>
          }
        />
      </Grid>
    </Grid>
  );
};

export default AccountSetUp;
