import { CountriesActionTypes, CountriesState, SET_COUNTRIES } from './countries.types';
const initialState: CountriesState = {};
export default (state = initialState, action: CountriesActionTypes): CountriesState => {
  switch (action.type) {
    case SET_COUNTRIES: {
      return {
        ...state,
        data: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
