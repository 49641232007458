import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import {
  DEFAULT_HOME_ROUTE,
  LeadStatus,
  NavigationConfiguration,
  SCHOOL_PORTAL_NAVIGATION,
} from './PortalLayout.constants';
import Footer from '../Footer';
import Header from '../Header';
import { logOutUserAction, SchoolUserRole, selectAreTermsAccepted, selectCurrentSchool } from '../../../Auth';
import Admin from '../../../../Admin';
import UserProfile from '../../../../Admin/UserProfile';
import Home from '../../../../Home';
import Profile from '../../../../Profile';
import RankingsHistory from '../../../../RankingsHistory';
import RankingProfile from '../../../../RankingProfile';
import SchoolProfile from '../../../../SchoolProfile';
import Settings from '../../../../Settings';
import TermsAndConditions from '../../../../TermsAndConditions';
import PageLoader from '../../../../../components/PageLoader';
import ProtectedRoute from '../../../../../components/ProtectedRoute';
import { JWT_TOKEN_COOKIE_NAME, PageProps } from '../../../../../shared/constants';
import { REQUEST_FAILED_NETWORK, selectRequestErrorMessage } from '../../../../../shared/state/global-request';
import { Help } from '../../../../Help/Help';
import Leads from '../../../../Leads';
import LeadProfile from '../../../../LeadProfile/components/LeadProfile';
import { getRegistrationRequest } from '../../../../../shared/registrationApi/api';
import { getCookie } from '../../../../../shared/web-storage';

interface RouteComponents {
  [key: string]: FunctionComponent<PageProps>;
}

const routeComponentsMap: RouteComponents = {
  admin: Admin,
  leads: Leads,
  userProfile: UserProfile,
  home: Home,
  history: RankingsHistory,
  schoolProfile: SchoolProfile,
  help: Help,
  leadProfile: LeadProfile,
};

const PortalLayout: FunctionComponent = () => {
  const dispatch = useDispatch();
  const requestNetworkErrorMessage = useSelector(selectRequestErrorMessage(REQUEST_FAILED_NETWORK));
  const { schoolId } = useSelector(selectCurrentSchool) as SchoolUserRole;
  const areTermsAccepted = useSelector(selectAreTermsAccepted);
  const { addToast } = useToasts();
  const [routesNavigation, setRoutesNavigation] = useState({
    ...SCHOOL_PORTAL_NAVIGATION,
  } as NavigationConfiguration);

  useEffect(() => {
    const cookieToken = getCookie(JWT_TOKEN_COOKIE_NAME);

    if (cookieToken && schoolId) {
      const getSchoolLeadStatus = async (): Promise<void> => {
        const response: any = await getRegistrationRequest(`/school/${schoolId}`).catch(console.error);
        const status = response.data.status;

        setRoutesNavigation({
          ...routesNavigation,
          leads: {
            ...routesNavigation.leads,
            visible: status.key !== LeadStatus.Inactive,
          },
        });
      };

      getSchoolLeadStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schoolId]);

  useEffect(() => {
    if (requestNetworkErrorMessage) {
      addToast(requestNetworkErrorMessage, { appearance: 'error' });
    }
  }, [addToast, requestNetworkErrorMessage]);

  const handleLogoutClick = (): void => {
    dispatch(logOutUserAction());
  };

  return areTermsAccepted === null ? (
    <PageLoader />
  ) : (
    <>
      <div className="o-layout o-layout--landing" data-o-component="o-layout">
        <div className="o-layout__header">
          <Header
            title="Business education"
            tagline="Survey administration"
            routes={routesNavigation}
            onLogoutClick={handleLogoutClick}
          />
        </div>
        <div className="o-layout__main o-layout-typography" data-o-component="o-syntax-highlight">
          <Switch>
            <Route path="/admin/:schoolId/users/:id" component={UserProfile} />
            <Route path="/leads/lead/:id" component={LeadProfile} />
            {Object.entries(routesNavigation).map(([key, { label, route, navigation }]) => {
              const MainComponent: FunctionComponent<PageProps> = routeComponentsMap[key];
              const renderPage = (): React.ReactNode => (
                <MainComponent label={label} route={route} navigation={navigation} />
              );
              return (
                <ProtectedRoute
                  isAuthenticated={true}
                  isAllowed={!!areTermsAccepted}
                  authenticationPath={''}
                  redirectedPath={'/terms-and-conditions'}
                  key={key}
                  exact={route === DEFAULT_HOME_ROUTE}
                  path={route}
                  component={renderPage as FunctionComponent}
                />
              );
            })}
            <Route path="/profile" component={Profile} />
            <Route path="/settings" component={Settings} />
            <Route path="/rankings/:id" component={RankingProfile} />
            <Route path="/terms-and-conditions" component={TermsAndConditions} />
          </Switch>
        </div>
        <div className="o-layout__footer">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default PortalLayout;
