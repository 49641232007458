import moment from 'moment';
import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';

import { DatePickerProps, DEFAULT_DATE_FORMAT } from './DatePicker.constants';
import InputWrapper, { InputWrapperProps } from '../InputWrapper';

import styles from './DatePicker.module.scss';

type Props = DatePickerProps & InputWrapperProps;

const DatePicker: FunctionComponent<Props> = ({
  disabled = false,
  error,
  id,
  name,
  onChange,
  optional,
  required,
  shrunken,
  small,
  subTitle,
  title,
  touched,
  valid,
  value,
  verticallyCentered,
}) => {
  const [dateValue, setDateValue] = useState(moment().toDate());
  const dateInputClasses = [styles.inputElement];
  const inputClasses = ['o-forms-input', 'o-forms-input--text'];

  useEffect(() => {
    const updatedDateValue = moment(value || '');

    if (updatedDateValue.isValid()) {
      setDateValue(updatedDateValue.toDate());
    }
  }, [value]);

  if (error || (touched && !valid)) {
    inputClasses.push('o-forms-input--invalid');
  } else if (touched && valid) {
    inputClasses.push('o-forms-input--valid');
  }

  if (small) {
    inputClasses.push('o-forms-input--small');
  }

  if (error) {
    dateInputClasses.push(styles.error);
  }

  const handleDateChange = (value: Date): void => {
    setDateValue(value);
    onChange && onChange({ target: { name, value: value.getTime() } } as unknown as ChangeEvent<HTMLInputElement>);
  };

  return (
    <InputWrapper
      id={id}
      inline={shrunken}
      optional={optional}
      required={required}
      shrunken={shrunken}
      subTitle={subTitle}
      title={title}
      verticallyCentered={verticallyCentered}
    >
      <div className={inputClasses.join(' ')}>
        <ReactDatePicker
          name={name}
          disabled={disabled}
          className={dateInputClasses.join(' ')}
          dateFormat={DEFAULT_DATE_FORMAT}
          selected={dateValue}
          onChange={handleDateChange}
        />
        {error && <span className="o-forms-input__error">{error}</span>}
      </div>
    </InputWrapper>
  );
};

export default DatePicker;
