import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link, useHistory } from 'react-router-dom';

import SchoolUserRolesAndPermissions from './SchoolUserRolesAndPermissions';
import { USER_PROFILE_CONFIG, UserProfileRouteParams } from './UserProfile.constants';
import UserDeleteConfirmation from './UserDeleteConfirmation';
import {
  activateUserAction,
  deactivateUserAction,
  DELETE_USER,
  deleteUserAction,
  EDIT_USER,
  EDIT_USER_PROFILE,
  editUserAction,
  EditUserDTO,
  JoinedSchoolDTO,
  loadSchoolUserAction,
  selectCurrentUser,
  SingleUserState,
  UserDTO,
} from '../Users';
import { SchoolUserRole, selectCurrentSchool } from '../../App/Auth';
import Button, { ButtonSizes } from '../../../components/Button';
import EditableGroup from '../../../components/EditableGroup';
import { FormConfig, FormDataValues } from '../../../components/Form';
import Icon from '../../../components/Icon';
import Modal from '../../../components/Modal';
import Label, { LabelStatus } from '../../../components/Label';
import Typography, { TypographyVariants } from '../../../components/Typography';
import { Roles } from '../../../shared/constants';
import {
  selectRequestErrorMessage,
  selectRequestFormErrors,
  setRequestSucceededAction,
} from '../../../shared/state/global-request';
import { loadResourcesAction, selectResources } from '../../../shared/state/resources';
import { loadRolesAction, selectRoles } from '../../../shared/state/roles';

import styles from './UserProfile.module.scss';

const UserProfile: FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: userId, schoolId } = useParams<UserProfileRouteParams>();
  const rankingTypes = useSelector(selectResources('ranking-types'));
  const currentUser = useSelector(selectCurrentUser) as UserDTO;
  const { id, name, status, school, permissions, role } = currentUser;
  const formErrors = useSelector(selectRequestFormErrors(EDIT_USER_PROFILE));
  const deactivateUserError = useSelector(selectRequestErrorMessage(EDIT_USER));
  const deleteUserError = useSelector(selectRequestErrorMessage(DELETE_USER));
  const schools = useSelector(selectResources('schools'));
  const schoolUserRoles = useSelector(selectRoles('school-users'));
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
  const { schoolId: currentSchoolId, role: currentRole } = useSelector(selectCurrentSchool) as SchoolUserRole;
  const [currentSchool, setCurrentSchool] = useState<JoinedSchoolDTO | null>(null);

  useEffect(() => {
    if (userId && schoolId) {
      dispatch(loadSchoolUserAction(parseInt(userId), parseInt(schoolId)));
      dispatch(loadRolesAction('school-users'));
      dispatch(loadResourcesAction('schools'));
      dispatch(loadResourcesAction('ranking-types'));
    }
  }, [dispatch, userId, schoolId]);

  useEffect(() => {
    if (currentSchoolId === school?.key && permissions) {
      setCurrentSchool({
        ...school,
        permissions,
        role,
      });
    } else if (currentUser?.joinedSchools) {
      const joinedSchool = currentUser?.joinedSchools.find((school) => school.key === currentSchoolId);
      if (joinedSchool) {
        setCurrentSchool({ ...joinedSchool });
      } else {
        history.push('/');
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [school, currentSchoolId]);

  const updateUser = (data: FormDataValues): void => {
    dispatch(editUserAction({ ...data, id } as EditUserDTO));
  };

  const resetFormState = (): void => {
    dispatch(setRequestSucceededAction(EDIT_USER_PROFILE));
  };

  const openConfirmationModal = (): void => {
    setIsConfirmPopupOpen(true);
  };

  const closeConfirmationModal = (): void => {
    setIsConfirmPopupOpen(false);
  };

  const openDeleteModal = (): void => {
    setIsDeleteConfirmPopupOpen(true);
  };

  const closeDeleteModal = (): void => {
    setIsDeleteConfirmPopupOpen(false);
  };

  const handleSchoolConfirm = (type: 'activate' | 'deactivate') => {
    return () => {
      switch (type) {
        case 'activate':
          dispatch(activateUserAction(currentUser.id, currentSchoolId));
          break;
        case 'deactivate':
          dispatch(deactivateUserAction(currentUser.id, currentSchoolId));
          break;
      }

      closeConfirmationModal();
    };
  };

  const handleDeleteSchoolConfirm = (): void => {
    const {
      id: userId,
      school: { key: schoolId },
    } = currentUser;
    dispatch(deleteUserAction(userId, schoolId));
    closeDeleteModal();
    setTimeout(() => history.goBack(), 800);
  };

  const userProfileFormConfig: FormConfig = {
    ...USER_PROFILE_CONFIG,
    school: {
      ...USER_PROFILE_CONFIG.school,
      options: schools.map(({ key, label }) => ({ label, value: key })),
    },
  };

  return (
    <>
      <Typography component="h1" variant={TypographyVariants.h1} className="mba-center--vertically">
        Users
      </Typography>
      <div className="mba-background--white">
        <EditableGroup<SingleUserState>
          columnSize={6}
          data={currentUser}
          editButtonLabel="Edit"
          formConfig={userProfileFormConfig}
          formErrors={formErrors}
          headingType="back"
          heading={
            <Link
              className="o-layout__unstyled-element mba-back-arrow mba-center--vertically"
              title="Go back to the school profile"
              to="/admin/users"
            >
              <Icon name="arrow-left" size={30} />
              <span className="mba-section-title mba-text-capitalize mba-margin-right-10">{name}</span>
              {status && (
                <Label color={status.color} status={LabelStatus.active}>
                  {status.label}
                </Label>
              )}
            </Link>
          }
          onEditSubmit={updateUser}
          onEditCancel={resetFormState}
          isEditable={currentRole === Roles.Admin}
        />
        <hr className="mba-separator" />
        {currentSchool && (
          <>
            <div className="mba-heading--wrapper mba-heading--table">
              <Typography component="h2" variant={TypographyVariants.h2} className="mba-center--vertically">
                Role and access to rankings
              </Typography>
            </div>
            <SchoolUserRolesAndPermissions
              partnershipId={currentSchool && currentSchool.key ? currentSchool.key : 0}
              schoolId={school && school.key ? school.key : 0}
              label={currentSchool && currentSchool.label ? currentSchool.label : ''}
              permissions={currentSchool.permissions || []}
              permissionsSubTitle="Permissions to access ranking types"
              rankingTypes={rankingTypes}
              role={currentSchool.role}
              rolesSubTitle="User role for the current school"
              schoolUserRoles={schoolUserRoles}
              userId={id}
            />
          </>
        )}
        {currentRole === Roles.Admin && (
          <>
            <hr className="mba-separator" />
            <div className={styles.actionsWrapper}>
              {status?.key === 1 && (
                <Button danger text="Deactivate" size={ButtonSizes.big} onClick={openConfirmationModal} />
              )}
              {status?.key === 2 && <Button text="Activate" size={ButtonSizes.big} onClick={openConfirmationModal} />}
              <Button primary danger text="Delete" size={ButtonSizes.big} onClick={openDeleteModal} />
            </div>
          </>
        )}
      </div>
      {currentRole === Roles.Admin && (
        <>
          <Modal
            title={status?.key === 1 ? 'Deactivate' : 'Activate'}
            isOpen={isConfirmPopupOpen}
            onClose={closeConfirmationModal}
          >
            <UserDeleteConfirmation
              id={parseInt(userId)}
              error={deactivateUserError}
              name={name}
              onCancel={closeConfirmationModal}
              onConfirm={handleSchoolConfirm(status?.key === 1 ? 'deactivate' : 'activate')}
              action={status?.key === 1 ? 'deactivate' : 'activate'}
            />
          </Modal>
          <Modal title="Delete" isOpen={isDeleteConfirmPopupOpen} onClose={closeDeleteModal}>
            <UserDeleteConfirmation
              id={parseInt(userId)}
              error={deleteUserError}
              name={name}
              onCancel={closeDeleteModal}
              onConfirm={handleDeleteSchoolConfirm}
              action="delete"
            />
          </Modal>
        </>
      )}
    </>
  );
};

export default UserProfile;
