import React, { FunctionComponent } from 'react';

import { FormControlElementProps } from '../Form.constants';

import { EmailPhoneInput } from '../../EmailPhoneInput/EmailPhoneInput';

type TextInputFieldProps = {
  control: FormControlElementProps;
  inputChangedHandler: (name: string, value: string, fieldType?: string) => void;
};

const EmailPhoneField: FunctionComponent<TextInputFieldProps> = ({ control, inputChangedHandler }) => {
  const { id, fieldType, validations, required, name, value } = control;

  const handleOnChange = (name: string, value: string, fieldType?: string): void => {
    inputChangedHandler(name, value, fieldType);
  };

  return (
    <EmailPhoneInput
      key={`${fieldType}-${id}`}
      {...control}
      type={fieldType}
      name={name}
      value={value as string}
      required={required || !!validations?.required}
      onChange={handleOnChange}
    />
  );
};

export default EmailPhoneField;
