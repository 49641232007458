import { TermsDto } from './terms.dto';
import { SET_TERMS, TermsActionTypes } from './terms.types';

const initialState: TermsDto = {};

export default (state = initialState, action: TermsActionTypes): TermsDto => {
  switch (action.type) {
    case SET_TERMS: {
      return {
        ...state,
        description: action.payload.data,
      };
    }
    default: {
      return state;
    }
  }
};
