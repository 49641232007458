import React, { ChangeEvent, FunctionComponent } from 'react';

import { FormControlElementProps } from '../Form.constants';
import TextInput, { TextInputTypes } from '../../TextInput';

type TextInputFieldProps = {
  control: FormControlElementProps;
  inputChangedHandler: (name: string, value: string) => void;
};

const TextInputField: FunctionComponent<TextInputFieldProps> = ({ control, inputChangedHandler }) => {
  const { id, error, fieldType, label, required, touched, valid, validations, value, ...elementProps } = control;

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    inputChangedHandler(name, value);
  };

  return (
    <div key={`${fieldType}-${id}`}>
      <TextInput
        {...elementProps}
        id={id}
        type={(fieldType as TextInputTypes) ?? 'text'}
        value={value as string}
        required={required || !!validations?.required}
        title={label}
        error={touched && !valid ? error : ''}
        onChange={handleOnChange}
      />
    </div>
  );
};

export default TextInputField;
