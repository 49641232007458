const ORIGAMI_MAIN_URL = 'https://www.ft.com/__origami/service/build/v3/bundles/';
//o-layout@^5.2.3,o-table@^9.2.5,o-forms@^9.4.0,o-buttons@^7.6.0,o-header-services@^5.2.3,o-footer-services@^4.2.3,o-autoinit@^3.1.3&brand=internal';

export const ORIGAMI_CSS_URL = `${ORIGAMI_MAIN_URL}css?components=`;

export const ORIGAMI_JS_URL = `${ORIGAMI_MAIN_URL}js?components=`;

export const COMMON_ORIGAMI_COMPONENTS: string[] = ['o-grid@^6.1.5', 'o-typography@^7.3.2', 'o-autoinit@^3.1.3'];

export const INTERNAL_ORIGAMI_COMPONENTS: string[] = [
  'o-layout@^5.2.3',
  'o-footer-services@^4.2.3',
  'o-header-services@^5.2.3',
  'o-buttons@^7.6.0',
  'o-icons@^7.3.0',
  'o-forms@^9.4.0',
  'o-labels@^6.2.2',
  'o-loading@^5.2.1',
  'o-overlay@^4.2.4',
  'o-tabs@^8.1.1',
  'o-table@^9.2.5',
  'o-message@^5.0.3',
  'o-autoinit@^3.1.3',
];
