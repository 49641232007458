import { CreateUserDTO, EditUserDTO, SchoolUserRolesAndPermissionsDTO, UserDTO } from './users.dto';
import { PageSearchQueryConfig, TableListData } from '../../../shared/constants';

export const CREATE_USER = 'CREATE_USER';
export const EDIT_USER = 'EDIT_USER';
export const CLEAR_CURRENT_USER = 'CLEAR_CURRENT_USER';
export const SET_USER = 'SET_USER';
export const LOAD_USERS = 'LOAD_USERS';
export const SET_USERS = 'SET_USERS';
export const EDIT_USER_PROFILE = 'EDIT_USER_PROFILE';
export const DEACTIVATE_USER = 'DEACTIVATE_USER';
export const ACTIVATE_USER = 'ACTIVATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const UPDATE_SCHOOL_USER_ROLES_AND_PERMISSIONS = 'UPDATE_SCHOOL_USER_ROLES_AND_PERMISSIONS';
export const LOAD_SCHOOL_USER = 'LOAD_SCHOOL_USER';

export type SingleUserState = CreateUserDTO | EditUserDTO | UserDTO;

export interface CreateUserAction {
  type: typeof CREATE_USER;
  payload: { data: CreateUserDTO; schoolId: number };
}

export interface EditUserAction {
  type: typeof EDIT_USER;
  payload: { data: EditUserDTO };
}

export interface ClearCurrentUserAction {
  type: typeof CLEAR_CURRENT_USER;
}

export interface SetUserAction {
  type: typeof SET_USER;
  payload: { data: UserDTO };
}

export interface DeactivateUserAction {
  type: typeof DEACTIVATE_USER;
  payload: { userId: number; schoolId: number };
}

export interface ActivateUserAction {
  type: typeof ACTIVATE_USER;
  payload: { userId: number; schoolId: number };
}

export interface DeleteUserAction {
  type: typeof DELETE_USER;
  payload: { userId: number; schoolId: number };
}

export type UserActionTypes = ClearCurrentUserAction | CreateUserAction | EditUserAction | SetUserAction;

export interface LoadUsersAction {
  type: typeof LOAD_USERS;
  payload: { pageConfig?: PageSearchQueryConfig; schoolId: number };
}

export interface SetUsersAction {
  type: typeof SET_USERS;
  payload: { tableData: TableListData<UserDTO> };
}

export interface UpdateSchoolUserRolesAndPermissionsAction {
  type: typeof UPDATE_SCHOOL_USER_ROLES_AND_PERMISSIONS;
  payload: {
    data: SchoolUserRolesAndPermissionsDTO;
    partnershipId: number;
    schoolId: number;
    userId: number;
  };
}

export interface LoadSchoolUserAction {
  type: typeof LOAD_SCHOOL_USER;
  payload: {
    id: number;
    schoolId: number;
  };
}

export type UsersActionTypes =
  | LoadSchoolUserAction
  | LoadUsersAction
  | SetUsersAction
  | DeactivateUserAction
  | DeleteUserAction;
