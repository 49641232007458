import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { changeSchoolAccountAction, selectAuthUser, selectCurrentSchool } from '../../../../../Auth';
import { SchoolUserRole } from '../../../../../Auth';

import styles from './UserRoleNavigation.module.scss';
import { getCookie } from '../../../../../../../shared/web-storage';
import { CURRENT_SCHOOL_ID } from '../../../../../../../shared/constants';
import { useHistory } from 'react-router-dom';
import { useWindowSize } from '../../../../../../../shared/helpers.hooks';

type UserRoleNavigationProps = {
  closeNavMenu?: () => void;
};

const UserRoleNavigation: FunctionComponent<UserRoleNavigationProps> = ({ closeNavMenu }) => {
  const dispatch = useDispatch();

  const authUser = useSelector(selectAuthUser);
  const currentSchool = useSelector(selectCurrentSchool);

  const [displaySubmenu, setDisplaySubmenu] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    const cookieSchoolID = parseInt(getCookie(CURRENT_SCHOOL_ID));
    const cookieSchool = authUser.schoolUserRoles.find((row) => row.schoolId === cookieSchoolID);
    const currentSchool = cookieSchool || authUser.schoolUserRoles[0];
    dispatch(changeSchoolAccountAction(currentSchool));
  }, [authUser, dispatch]);
  const { isMobile } = useWindowSize();

  const handleClick =
    (schoolUserRole: SchoolUserRole) =>
    (e: React.MouseEvent): void => {
      e.preventDefault();
      dispatch(changeSchoolAccountAction(schoolUserRole));
      setDisplaySubmenu(false);
      history.push('/');
      closeNavMenu && closeNavMenu();
    };

  const handleClickDropdown = (e: React.MouseEvent) => {
    e.preventDefault();
    setDisplaySubmenu(!displaySubmenu);
  };

  const getDisplayStyle = (show: boolean): { [key: string]: string | number } => {
    return show ? { display: 'block', opacity: 1 } : { display: 'none', opacity: 0 };
  };

  return (
    <>
      {authUser.schoolUserRoles && (
        <>
          {isMobile ? (
            <ul className="o-header-services__related-content mba-margin-top-20">
              {authUser.schoolUserRoles.map((schoolUserRole: SchoolUserRole) => (
                <li
                  className={schoolUserRole.schoolName === currentSchool.schoolName ? `${styles.currentSchool}` : ``}
                  key={schoolUserRole.schoolId}
                >
                  <button className={styles.switchSchoolButton} onClick={handleClick(schoolUserRole)}>
                    {schoolUserRole.schoolName}
                  </button>
                </li>
              ))}
            </ul>
          ) : (
            <li className={styles.menuItemWrapper}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a onClick={handleClickDropdown} href="#">
                {currentSchool.schoolName}
              </a>
              <button
                onClick={(): void => setDisplaySubmenu(!displaySubmenu)}
                className="o-header-services__drop-down-button"
                type="button"
                name="button"
                aria-label="Toggle dropdown menu"
              />
              <ul data-o-header-services-level="2" aria-hidden="true" style={getDisplayStyle(displaySubmenu)}>
                {authUser.schoolUserRoles.map((schoolUserRole: SchoolUserRole) => (
                  <li key={schoolUserRole.schoolId} className="nav-subItems">
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a href="#" onClick={handleClick(schoolUserRole)}>
                      {schoolUserRole.schoolName}
                    </a>
                  </li>
                ))}
              </ul>
            </li>
          )}
        </>
      )}
    </>
  );
};

export default UserRoleNavigation;
