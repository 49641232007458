import {
  LOAD_LEADS,
  LoadLeadsAction,
  SET_LEADS,
  SetLeadsAction,
  DownloadSpreadsheetAction,
  DOWNLOAD_LEADS,
} from './leads.types';
import { PageSearchQueryConfig, TableListData } from '../../shared/constants';
import { LeadsDTO } from './leads.dto';

export const loadLeadsAction = (pageConfig?: PageSearchQueryConfig): LoadLeadsAction => ({
  type: LOAD_LEADS,
  payload: { pageConfig },
});

export const setLeadsAction = (tableData: TableListData<LeadsDTO>): SetLeadsAction => ({
  type: SET_LEADS,
  payload: { tableData },
});

export const downloadLeadsAction = (
  selectedData?: string[],
  pageConfig?: PageSearchQueryConfig,
): DownloadSpreadsheetAction => ({
  type: DOWNLOAD_LEADS,
  payload: { pageConfig, selectedData },
});
