import React, { FunctionComponent } from 'react';

import LoadingSpinner from '../LoadingSpinner';

import styles from './PageLoader.module.scss';

const PageLoader: FunctionComponent = () => (
  <div className={['o-overlay-shadow', styles.overlay].join(' ')}>
    <LoadingSpinner className={styles.loader} size={'large'} />
  </div>
);

export default PageLoader;
