export const DEFAULT_HOME_ROUTE = '/';

export interface PageConfiguration {
  exact?: boolean;
  label: string;
  route: string;
  visible?: boolean;
  navigation?: NavigationConfiguration;
}

export interface NavigationConfiguration {
  [key: string]: PageConfiguration;
}

export const SCHOOL_PORTAL_NAVIGATION: NavigationConfiguration = {
  home: {
    label: 'Dashboard',
    route: '/',
    visible: true,
  },
  history: {
    label: 'Rankings',
    route: '/history',
    visible: true,
  },
  schoolProfile: {
    label: 'School profile',
    route: '/school-profile',
    visible: true,
  },
  admin: {
    label: 'Admin',
    route: '/admin',
    visible: true,
  },
  leads: {
    label: 'Leads',
    route: '/leads',
    visible: false,
  },
  help: {
    label: 'Help',
    route: '/help',
    visible: true,
  },
};

export enum LeadStatus {
  Active = 0,
  Inactive = 1,
  Expired = 2,
}
