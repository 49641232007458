import React, { FunctionComponent } from 'react';

import { FOOTER_LINKS } from './Footer.constants';

import styles from './Footer.module.scss';

const Footer: FunctionComponent = () => (
  <>
    <footer className={[styles.footer, 'o-footer-services'].join(' ')}>
      <div className="o-footer-services__container">
        <div className="o-footer-services__wrapper o-footer-services__wrapper--legal">
          <div className="o-footer-services__links">
            {FOOTER_LINKS.map(({ key, title, url }) => (
              <a key={key} href={url} target="_blank" rel="noreferrer noopener">
                {title}
              </a>
            ))}
          </div>
          <p>
            <span>&#xA9; THE FINANCIAL TIMES LTD {new Date().getFullYear()}.</span> FT and &apos;Financial Times&apos;
            are trademarks of The Financial Times Ltd.
          </p>
        </div>
      </div>
    </footer>
  </>
);

export default Footer;
