import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { RANKINGS_TABLE_FILTERS } from './RankingsFiltersAndSearch.constants';
import { loadRankingsAction } from '../../rankings.actions';
import FilterGroup, { mapInitialValuesToFiltersConfig } from '../../../../components/FilterGroup';
import SearchInput from '../../../../components/SearchInput';
import { PageFilterConfig, PageSearchQueryConfig, Resource, Status } from '../../../../shared/constants';

type RankingsSearchProps = {
  pageConfig: PageSearchQueryConfig;
  filtersData: { rankingYears: Resource[]; rankingStatuses: Status[]; rankingTypes: Resource[] };
};

const RankingsFiltersAndSearch: FunctionComponent<RankingsSearchProps> = ({ pageConfig, filtersData }) => {
  const dispatch = useDispatch();
  const [rankingFilters, setRankingFilters] = useState({});
  const { filter, search } = pageConfig;

  useEffect(() => {
    const { rankingStatuses, rankingYears, rankingTypes } = filtersData;
    setRankingFilters(
      mapInitialValuesToFiltersConfig(
        RANKINGS_TABLE_FILTERS,
        {
          rankingTypeId: rankingTypes,
          status: rankingStatuses,
          year: rankingYears,
        },
        filter,
        true,
      ),
    );
  }, [filter, filtersData]);

  const handleSearchChange = (newPageConfig: PageSearchQueryConfig): void => {
    dispatch(loadRankingsAction(newPageConfig));
  };

  const handleFiltersChange = (newFilters: PageFilterConfig): void => {
    dispatch(
      loadRankingsAction({
        ...pageConfig,
        filter: newFilters,
      }),
    );
  };

  return (
    <div className="sticky-sidebar-container">
      <SearchInput
        id="rankings-search"
        pageConfig={pageConfig}
        placeholder="Search by ranking name"
        onSearchChanged={handleSearchChange}
        searchTerm={search}
      />
      <FilterGroup
        defaultFilters={filter || {}}
        filtersConfig={rankingFilters}
        onFiltersChanged={handleFiltersChange}
      />
    </div>
  );
};

export default RankingsFiltersAndSearch;
