import React, { FunctionComponent } from 'react';

import { LoadingSpinnerSizes, LoadingSpinnerThemes } from './LoadingSpinner.constants';

type LoadingSpinnerProps = {
  className?: string;
  size?: LoadingSpinnerSizes;
  theme?: LoadingSpinnerThemes;
};

const LoadingSpinner: FunctionComponent<LoadingSpinnerProps> = ({ className, size = 'medium', theme = 'light' }) => {
  const loadingSpinnerClasses = ['o-loading', `o-loading--${theme}`, `o-loading--${size}`];

  if (className) {
    loadingSpinnerClasses.push(className);
  }

  return <div className={loadingSpinnerClasses.join(' ')} />;
};

export default LoadingSpinner;
