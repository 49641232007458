import { RankingTimelineStepDTO } from '../ranking-profile.dto';
import { TimelineStepStatus } from './components/TimelineIndicator';

export enum ParticipationStatus {
  confirmed = 1,
  declined = 2,
  pending = 3,
  rejected = 4,
}

export interface RankingTimelineStepBaseProps extends RankingTimelineStepDTO {
  rankingId: number;
  rankingSlug: string;
  schoolId: number;
}

export const TimelineExpandStepStatus: Record<TimelineStepStatus, boolean> = {
  [TimelineStepStatus['active-short']]: true,
  [TimelineStepStatus['active-medium']]: true,
  [TimelineStepStatus['active-long']]: true,
  [TimelineStepStatus.completed]: false,
  [TimelineStepStatus.skipped]: false,
  [TimelineStepStatus.upcoming]: false,
};

export const MIN_DAYS_FOR_ACTIVE_STATUS = 0;

export const MAX_DAYS_FOR_ACTIVE_SHORT_STATUS = 2;

export const MAX_DAYS_FOR_ACTIVE_MEDIUM_STATUS = 6;
