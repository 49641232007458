import { DashboardRankingsDto } from './dahboards.dto';
import { DashboardActionTypes, SET_DASHBOARD_DATA } from './dashboards.types';

const initialState: DashboardRankingsDto = {
  activeRankings: [],
  upcomingRankings: [],
} as DashboardRankingsDto;

export default (state = initialState, action: DashboardActionTypes): DashboardRankingsDto => {
  switch (action.type) {
    case SET_DASHBOARD_DATA: {
      return {
        ...state,
        ...action.payload.data,
      };
    }
    default: {
      return state;
    }
  }
};
