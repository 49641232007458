import { BasicResponseDTO, PagedResponseDTO, PageSearchQueryConfig } from '../../shared/constants';
import { generateSearchQuery } from '../../shared/helpers';
import { getRegistrationRequest } from '../../shared/registrationApi/api';
import { PROGRAMME_OF_INTEREST_KEY } from './components/LeadsFilters/LeadsFilters.constants';
import { LeadsDTO } from './leads.dto';

export const getLeadsRequest = (
  schoolId: number,
  pageConfig: PageSearchQueryConfig = {},
): Promise<PagedResponseDTO<LeadsDTO[]>> => {
  let query = generateSearchQuery(pageConfig);
  if (query.includes(PROGRAMME_OF_INTEREST_KEY)) {
    query = query.replaceAll(PROGRAMME_OF_INTEREST_KEY, 'answers');
  }
  return getRegistrationRequest<PagedResponseDTO<LeadsDTO[]>>(`school/${schoolId}/leads${query}`);
};

export const downloadLeadsSpreadsheetRequest = (
  pageConfig: PageSearchQueryConfig = {},
  selectedData: string[] = [],
  schoolId: number,
): Promise<BasicResponseDTO> => {
  const config = { ...pageConfig, filter: { leadId: selectedData } };
  const query = generateSearchQuery(config);
  return getRegistrationRequest<BasicResponseDTO>(`/school/${schoolId}/download-leads${query}`, {
    responseType: 'blob',
  });
};
