import React, { FunctionComponent } from 'react';

import styles from './TimelineIndicator.module.scss';

export enum TimelineStepStatus {
  'upcoming',
  'active-long',
  'active-medium',
  'active-short',
  'completed',
  'skipped',
}

interface TimelineIndicatorProps {
  isCompleted?: boolean;
  isLast?: boolean;
  timeIndication?: TimelineStepStatus;
}

const TimelineIndicator: FunctionComponent<TimelineIndicatorProps> = ({
  isCompleted,
  isLast = false,
  timeIndication = TimelineStepStatus.upcoming,
}) => {
  const wrapperClasses = [styles.indicatorWrapper, styles[`indicationTime-${timeIndication}`]];
  const indicatorBoxClasses = [styles.indicatorBox];

  if (isLast) {
    wrapperClasses.push(styles.last);
  }

  if (isCompleted && timeIndication !== TimelineStepStatus['active-short']) {
    indicatorBoxClasses.push(styles.checked);
  }

  return (
    <div className={wrapperClasses.join(' ')}>
      <div className={indicatorBoxClasses.join(' ')}>
        <div className={styles.indicator} />
      </div>
    </div>
  );
};

export default TimelineIndicator;
