import {
  CHANGE_DEFAULT_CAMPUS,
  ChangeDefaultCampusAction,
  LOAD_CAMPUSES,
  LoadCampusesAction,
  SET_CAMPUSES,
  SetCampusesAction,
} from './campuses.types';
import { CampusDTO } from '../CampusProfile';
import { PageSearchQueryConfig, TableListData } from '../../../../shared/constants';

export const changeDefaultCampusAction = (schoolId: number, campusId: number): ChangeDefaultCampusAction => ({
  type: CHANGE_DEFAULT_CAMPUS,
  payload: { campusId, schoolId },
});

export const loadCampusesAction = (schoolId: number, pageConfig?: PageSearchQueryConfig): LoadCampusesAction => ({
  type: LOAD_CAMPUSES,
  payload: { pageConfig, schoolId },
});

export const setCampusesAction = (tableData: TableListData<CampusDTO>): SetCampusesAction => ({
  type: SET_CAMPUSES,
  payload: { tableData },
});
