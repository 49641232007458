import { PageSearchQueryConfig, TableListData } from '../../shared/constants';
import { LeadsDTO } from './leads.dto';

export const LOAD_LEADS = 'LOAD_LEADS';
export const SET_LEADS = 'SET_LEADS';
export const DOWNLOAD_LEADS = 'DOWNLOAD_LEADS';

export interface LoadLeadsAction {
  type: typeof LOAD_LEADS;
  payload: { pageConfig?: PageSearchQueryConfig };
}

export interface SetLeadsAction {
  type: typeof SET_LEADS;
  payload: { tableData: TableListData<LeadsDTO> };
}

export interface DownloadSpreadsheetAction {
  type: typeof DOWNLOAD_LEADS;
  payload: { selectedData?: string[]; pageConfig?: PageSearchQueryConfig };
}

export type LeadsActionTypes = LoadLeadsAction | SetLeadsAction | DownloadSpreadsheetAction;
