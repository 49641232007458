import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import CurrentTimeline from './CurrentTimeline';
import Instructions from './Instructions';
import ParticipationCriteria from './ParticipationCriteria';
import {
  RANKING_PROFILE_MAIN_ROUTE,
  RANKING_PROFILE_TABS_NAVIGATION,
  RankingProfileRouteParams,
} from './RankingProfile.constants';
import { clearCurrentRankingAction, loadRankingAction } from './ranking-profile.actions';
import { RankingDTO } from './ranking-profile.dto';
import { selectCurrentRanking } from './ranking-profile.selectors';
import { DEFAULT_HOME_ROUTE, PageRouter } from '../App/Main/components/PortalLayout';
import Icon from '../../components/Icon';
import PageLoader from '../../components/PageLoader';
import TabsNavigation from '../../components/TabsNavigation';

const componentsMap = {
  instructions: Instructions,
  participationCriteria: ParticipationCriteria,
  timeline: CurrentTimeline,
};

const RankingProfile: FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { id: rankingId } = useParams<RankingProfileRouteParams>();
  const { id, name } = useSelector(selectCurrentRanking) as RankingDTO;
  const tabsNavigation = Object.values(RANKING_PROFILE_TABS_NAVIGATION).map(({ label, route }) => ({
    label,
    route: `${RANKING_PROFILE_MAIN_ROUTE.replace(':id', rankingId)}${route}`,
  }));

  useEffect(() => {
    if (rankingId) {
      dispatch(loadRankingAction(parseInt(rankingId)));
    }

    return () => {
      dispatch(clearCurrentRankingAction());
    };
  }, [dispatch, rankingId]);

  const navigateBack = (): void => {
    history.length > 0 ? history.goBack() : history.push(DEFAULT_HOME_ROUTE);
  };

  return rankingId && id ? (
    <>
      <div className="mba-background--white">
        <div className="mba-heading--wrapper">
          <div>
            <button
              className="o-layout__unstyled-element mba-back-arrow mba-timeline-button--link"
              title="Go back to dashboard"
              onClick={navigateBack}
            >
              <Icon name="arrow-left" size={30} />
            </button>
            <span className="mba-center--vertically mba-heading--b2b">{name}</span>
          </div>
        </div>
        <br />
        <TabsNavigation tabs={tabsNavigation} />
        <PageRouter
          mainRoute={RANKING_PROFILE_MAIN_ROUTE}
          navigation={RANKING_PROFILE_TABS_NAVIGATION}
          componentsMap={componentsMap}
        />
      </div>
      <br />
    </>
  ) : (
    <PageLoader />
  );
};

export default RankingProfile;
