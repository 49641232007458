import { UpdateLead } from '../Leads/components/Leads.constants';
import { LeadsDTO } from '../Leads/leads.dto';
import {
  ClearCurrentLeadAction,
  CLEAR_CURRENT_LEAD,
  EditLeadProfileAction,
  EDIT_LEAD,
  LoadLeadProfileAction,
  LOAD_LEAD,
  SetLeadProfileAction,
  SET_LEAD,
} from './lead-profile.types';

export const setLeadProfileAction = (data: LeadsDTO): SetLeadProfileAction => ({
  type: SET_LEAD,
  payload: { data },
});

export const clearCurrentLeadAction = (): ClearCurrentLeadAction => ({
  type: CLEAR_CURRENT_LEAD,
});

export const editLeadAction = (data: UpdateLead): EditLeadProfileAction => ({
  type: EDIT_LEAD,
  payload: { data },
});

export const loadLeadProfileAction = (id: number): LoadLeadProfileAction => ({
  type: LOAD_LEAD,
  payload: { id },
});
