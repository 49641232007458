import { call, CallEffect, put, PutEffect, takeEvery } from 'redux-saga/effects';

import { setRolesAction } from './roles.actions';
import { getRolesRequest } from './roles.api';
import { LOAD_ROLES, LoadRolesAction, RolesActionTypes } from './roles.types';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../global-request';
import { DefaultResponseDTO } from '../../constants';

function* loadRolesData(
  action: LoadRolesAction,
): Generator<CallEffect<DefaultResponseDTO<string[]>> | PutEffect<RolesActionTypes> | PutEffect<GlobalRequestActions>> {
  const { type: actionType } = action;
  const { rolesType } = action.payload;

  try {
    yield put(setRequestStartedAction(actionType));
    const { data } = (yield call(getRolesRequest, rolesType)) as DefaultResponseDTO<string[]>;
    yield put(setRolesAction(rolesType, data));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

export default function* rolesSaga(): Generator {
  yield takeEvery(LOAD_ROLES, loadRolesData);
}
