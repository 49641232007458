import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware as createRouterMiddleware } from 'react-router-redux';
import createSagaMiddleware from 'redux-saga';

import { rootReducer } from './reducers';
import { authSaga } from '../containers/App/Auth';
import { rankingProfileSaga } from '../containers/RankingProfile';
import { rankingsHistorySaga } from '../containers/RankingsHistory';
import history from '../shared/history';
import { attachmentsSaga } from '../shared/state/attachments';
import { globalRequestSaga } from '../shared/state/global-request';
import { resourcesSaga } from '../shared/state/resources';
import { rolesSaga } from '../shared/state/roles';
import { statusesSaga } from '../shared/state/statuses';
import { usersSaga } from '../containers/Admin/Users';
import { rankingTimelineSaga } from '../containers/RankingProfile/CurrentTimeline';
import { schoolProfileSaga } from '../containers/SchoolProfile';
import { campusesSaga } from '../containers/SchoolProfile/components/CampusesList';
import { campusProfileSaga } from '../containers/SchoolProfile/components/CampusProfile';
import { termsSaga } from '../containers/TermsAndConditions';
import { dashboardsSaga } from '../containers/Home/dashboards.sagas';
import { privacySaga } from '../containers/App/Main/components/PrivacyPolicy';
import { faqSagas } from '../containers/Help';
import { leadsSaga } from '../containers/Leads';
import { countriesSaga } from '../shared/state/countries';
import leadProfileSaga from '../containers/LeadProfile/lead-profile.sagas';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const routerMiddleware = createRouterMiddleware(history);

const sagaMiddleware = createSagaMiddleware();

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(routerMiddleware, sagaMiddleware)));

// Auth
sagaMiddleware.run(authSaga);

// Common
sagaMiddleware.run(attachmentsSaga);
sagaMiddleware.run(resourcesSaga);
sagaMiddleware.run(statusesSaga);
sagaMiddleware.run(countriesSaga);

// Campuses
sagaMiddleware.run(campusesSaga);
sagaMiddleware.run(campusProfileSaga);

// Admin
sagaMiddleware.run(rolesSaga);
sagaMiddleware.run(usersSaga);

// Rankings
sagaMiddleware.run(rankingProfileSaga);
sagaMiddleware.run(rankingsHistorySaga);
sagaMiddleware.run(leadsSaga);
sagaMiddleware.run(leadProfileSaga);
sagaMiddleware.run(rankingTimelineSaga);

// School profile
sagaMiddleware.run(schoolProfileSaga);

// global request
sagaMiddleware.run(globalRequestSaga);

// Terms and conditions
sagaMiddleware.run(termsSaga);

// Privacy policy
sagaMiddleware.run(privacySaga);

// Help FAQ
sagaMiddleware.run(faqSagas);

// Dashboard
sagaMiddleware.run(dashboardsSaga);

export default store;
