import {
  RankingParticipationStatusDTO,
  RankingSpreadsheetDTO,
  RankingSpreadsheetFileDTO,
  RankingSpreadsheetTemplateDTO,
  TimelineAttachmentDTO,
  TimelineSpreadsheetDTO,
} from './ranking-timeline.dto';

export const DOWNLOAD_TEMPLATE = 'DOWNLOAD_TEMPLATE';
export const DOWNLOAD_SPREADSHEET = 'DOWNLOAD_SPREADSHEET';
export const UPDATE_PARTICIPATION = 'UPDATE_PARTICIPATION';
export const UPLOAD_SPREADSHEET = 'UPLOAD_SPREADSHEET';
export const DOWNLOAD_TIMELINE_SPREADSHEET = 'DOWNLOAD_TIMELINE_SPREADSHEET';
export const DOWNLOAD_STEP_ATTACHMENT = 'DOWNLOAD_STEP_ATTACHMENT';

export interface DownloadRankingSpreadsheetAction {
  type: typeof DOWNLOAD_SPREADSHEET;
  payload: RankingSpreadsheetDTO;
}

export interface DownloadSpreadsheetTemplateAction {
  type: typeof DOWNLOAD_TEMPLATE;
  payload: RankingSpreadsheetTemplateDTO;
}

export interface UpdateParticipationAction {
  type: typeof UPDATE_PARTICIPATION;
  payload: RankingParticipationStatusDTO;
}

export interface UploadSpreadsheetAction {
  type: typeof UPLOAD_SPREADSHEET;
  payload: RankingSpreadsheetFileDTO;
}

export interface DownloadTimelineSpreadsheetAction {
  type: typeof DOWNLOAD_TIMELINE_SPREADSHEET;
  payload: TimelineSpreadsheetDTO;
}

export interface DownloadStepAttachmentAction {
  type: typeof DOWNLOAD_STEP_ATTACHMENT;
  payload: TimelineAttachmentDTO;
}

export type RankingTimelineActionTypes =
  | DownloadRankingSpreadsheetAction
  | DownloadSpreadsheetTemplateAction
  | UpdateParticipationAction
  | UploadSpreadsheetAction
  | DownloadStepAttachmentAction
  | DownloadTimelineSpreadsheetAction;
