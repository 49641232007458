import { CallEffect, PutEffect, put, call, takeLatest } from 'redux-saga/effects';
import { LOAD_PRIVACY_DATA, LoadPrivacyAction, PrivacyPolicyTypes } from './privacy.types';
import { DefaultResponseDTO } from '../../../../../shared/constants';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../../../../../shared/state/global-request';
import { getPrivacyRequest } from './privacy.api';
import { setPrivacyAction } from './privacy.actions';

function* loadPrivacySaga(
  action: LoadPrivacyAction,
): Generator<CallEffect<DefaultResponseDTO<string>> | PutEffect<GlobalRequestActions | PrivacyPolicyTypes>> {
  const { type: actionType } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { data } = (yield call(getPrivacyRequest)) as DefaultResponseDTO<string>;
    yield put(setPrivacyAction(data));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

export default function* privacySaga(): Generator {
  yield takeLatest(LOAD_PRIVACY_DATA, loadPrivacySaga);
}
