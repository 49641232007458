import { CampusDTO } from '../CampusProfile';
import { PageSearchQueryConfig, TableListData } from '../../../../shared/constants';

export const CHANGE_DEFAULT_CAMPUS = 'CHANGE_DEFAULT_CAMPUS';
export const LOAD_CAMPUSES = 'LOAD_CAMPUSES';
export const SET_CAMPUSES = 'SET_CAMPUSES';

export interface ChangeDefaultCampusAction {
  type: typeof CHANGE_DEFAULT_CAMPUS;
  payload: { campusId: number; schoolId: number };
}

export interface LoadCampusesAction {
  type: typeof LOAD_CAMPUSES;
  payload: { pageConfig?: PageSearchQueryConfig; schoolId: number };
}

export interface SetCampusesAction {
  type: typeof SET_CAMPUSES;
  payload: { tableData: TableListData<CampusDTO> };
}

export type CampusesActionTypes = ChangeDefaultCampusAction | LoadCampusesAction | SetCampusesAction;
