import React, { FunctionComponent, MouseEvent, useState } from 'react';

import TextInput from '../TextInput';
import { TextInputProps, TextInputTypes } from '../TextInput.constants';
import Icon from '../../Icon';

import styles from './PasswordInput.module.scss';
import { ReactComponent as EyeOpenIcon } from '../../../assets/images/visibility.svg';
import { ReactComponent as EyeClosedIcon } from '../../../assets/images/invisible.svg';

const PasswordInput: FunctionComponent<TextInputProps> = (props) => {
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);

  const icon = isPasswordHidden ? <EyeClosedIcon width={30} height={30} /> : <EyeOpenIcon width={30} height={30} />;

  const togglePassword = (event: MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setIsPasswordHidden(!isPasswordHidden);
  };

  return (
    <TextInput
      type={isPasswordHidden ? TextInputTypes.password : TextInputTypes.text}
      {...props}
      suffix={
        <button className={styles.iconWrapper} onClick={togglePassword} type="button">
          <Icon customIcon={icon} />
        </button>
      }
    />
  );
};

export default PasswordInput;
