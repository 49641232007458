import React, { Fragment, FunctionComponent, ReactNode } from 'react';

import { INITIAL_PAGE, MAX_NUMBER_OF_PAGE_BOXES_WITHOUT_DOTS } from './Pager.constants';

import styles from './Pager.module.scss';

type PagerProps = {
  big?: boolean;
  currentPage?: number;
  onPageChange: (pageNumber: number) => void;
  totalPages: number;
};

const Pager: FunctionComponent<PagerProps> = ({
  big = false,
  currentPage = INITIAL_PAGE,
  totalPages,
  onPageChange,
}) => {
  const pagerClasses = ['o-buttons-pagination', styles.pagerWrapper];
  const pagerButtonArrowClasses = ['o-buttons-icon', 'o-buttons-icon--icon-only'];
  const pagerButtonClasses = ['o-buttons', 'o-buttons--secondary', styles.pagerButton];
  const separatorClasses = [styles.separator];
  let pages: (string | number)[];

  if (big) {
    pagerButtonClasses.push('o-buttons--big');
    separatorClasses.push(styles.big);
  }

  if (totalPages <= MAX_NUMBER_OF_PAGE_BOXES_WITHOUT_DOTS) {
    pages = Array.from({ length: totalPages }, (_, index: number) => index + 1);
  } else {
    switch (true) {
      case currentPage < 3 || currentPage > totalPages - 2: {
        pages = [INITIAL_PAGE, INITIAL_PAGE + 1, INITIAL_PAGE + 2, '...', totalPages - 2, totalPages - 1, totalPages];
        break;
      }
      case currentPage === 3: {
        pages = [INITIAL_PAGE, currentPage - 1, currentPage, currentPage + 1, '...', totalPages - 1, totalPages];
        break;
      }
      case currentPage === totalPages - 2: {
        pages = [INITIAL_PAGE, INITIAL_PAGE + 1, '...', currentPage - 1, currentPage, currentPage + 1, totalPages];
        break;
      }
      default: {
        pages = [INITIAL_PAGE, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages];
      }
    }
  }

  const handlePageChange = (pageNumber: number): void => {
    onPageChange && onPageChange(pageNumber);
  };

  const renderPagerArrow = (direction: string, disabled: boolean, label: string): ReactNode => (
    <button
      className={[...pagerButtonClasses, ...pagerButtonArrowClasses, `o-buttons-icon--arrow-${direction}`].join(' ')}
      disabled={disabled}
      onClick={(): void => {
        handlePageChange(direction === 'left' ? currentPage - 1 : currentPage + 1);
      }}
    >
      <span className="o-buttons-icon__label">{label}</span>
    </button>
  );

  const renderPageButton = (pageNumber: number): ReactNode => {
    const customAttributes: any = pageNumber === currentPage ? { 'aria-current': 'page' } : {};

    return (
      <button
        {...customAttributes}
        className={pagerButtonClasses.join(' ')}
        onClick={(): void => handlePageChange(pageNumber)}
      >
        {pageNumber}
      </button>
    );
  };

  return (
    <div className={pagerClasses.join(' ')}>
      {renderPagerArrow('left', currentPage === 1, 'Fewer results')}
      {pages.map((pageNumber, index) => (
        <Fragment key={`pager-${index}`}>
          {pageNumber === '...' ? (
            <span className={separatorClasses.join(' ')}>...</span>
          ) : (
            renderPageButton(+pageNumber)
          )}
        </Fragment>
      ))}
      {renderPagerArrow('right', currentPage === totalPages, 'More results')}
    </div>
  );
};

export default Pager;
