import { CheckboxInputOption } from '../Checkbox';

export const DEFAULT_SHOW_ALL_GROUP_KEY = 'all';

export const DEFAULT_SHOW_ALL_FILTER_CONFIG = {
  name: DEFAULT_SHOW_ALL_GROUP_KEY,
  text: 'Show All',
  value: true,
};

export interface FiltersConfig {
  [key: string]: {
    isDropdown?: boolean;
    options: CheckboxInputOption[];
    title?: string;
  };
}
