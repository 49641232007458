import React, { ReactNode } from 'react';

import { TYPOGRAPHY_ITALIC_CLASS_NAME, TypographyVariants } from './Typography.constants';

type TypographyTypes = {
  children?: ReactNode;
  component?: 'p' | 'strong' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span' | 'sub' | 'sup' | 'figcaption';
  className?: string;
  italic?: boolean;
  variant?: TypographyVariants;
};

const Typography: React.FunctionComponent<TypographyTypes> = ({
  children,
  component = 'p',
  className = '',
  italic,
  variant = TypographyVariants.body,
}) => {
  const TypographyComponent = component;
  const classList = `${className} ${variant}`.trim();
  const elementClasses = [classList];

  if (italic) {
    elementClasses.push(TYPOGRAPHY_ITALIC_CLASS_NAME);
  }

  return <TypographyComponent className={elementClasses.join(' ')}>{children}</TypographyComponent>;
};

export default Typography;
