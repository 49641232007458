export enum RadioInputsShape {
  circle = 'circle',
  box = 'box',
}

export interface RadioInputsOption {
  checked?: boolean;
  label: string;
  value: string | number;
}
