import { FormConfig } from '../../../components/Form';
import { PHONE_REGEX } from '../../../shared/constants';

export const EDIT_PROFILE_FORM_CONFIG: FormConfig = {
  name: {
    label: 'Name',
    placeholder: `Enter name`,
    fieldType: 'text',
    validations: {
      required: {
        errorText: `Please enter name!`,
      },
    },
  },
  phone: {
    label: 'Phone',
    placeholder: `Enter phone number`,
    fieldType: 'text',
    validations: {
      regex: {
        errorText: 'Please enter a valid phone number!',
        rule: PHONE_REGEX,
      },
    },
  },
};
