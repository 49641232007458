import { LOAD_TERMS, LoadTermsAction, SET_TERMS, SetTermsAction } from './terms.types';

export const loadTermsAction = (): LoadTermsAction => ({
  type: LOAD_TERMS,
});

export const setTermsAction = (data: string): SetTermsAction => ({
  type: SET_TERMS,
  payload: { data },
});
