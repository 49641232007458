import { HelpFAQDto } from './help.dto';
import { FAQActionTypes } from './help.types';

const initialState: HelpFAQDto[] = [];

export default (state = initialState, action: FAQActionTypes): HelpFAQDto[] => {
  switch (action.type) {
    case 'SET_FAQ_DATA': {
      return [...action.payload];
    }
    default: {
      return state;
    }
  }
};
