import { LOAD_FAQ_DATA, LoadFAQAction, SET_FAQ_DATA, SetFAQAction } from './help.types';
import { HelpFAQDto } from './help.dto';

export const loadFAQAction = (): LoadFAQAction => ({
  type: LOAD_FAQ_DATA,
});

export const setFAQAction = (data: HelpFAQDto[]): SetFAQAction => ({
  type: SET_FAQ_DATA,
  payload: data,
});
