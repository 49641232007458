import { RankingDTO } from './ranking-profile.dto';
import {
  CLEAR_CURRENT_RANKING,
  RankingActionTypes,
  SET_RANKING,
  SET_RANKING_INSTRUCTIONS,
  SET_RANKING_PARTICIPATION_CRITERIA,
  SET_RANKING_TIMELINE,
} from './ranking-profile.types';

const initialState: RankingDTO = {} as RankingDTO;

export default (state = initialState, action: RankingActionTypes): RankingDTO => {
  switch (action.type) {
    case CLEAR_CURRENT_RANKING: {
      return initialState;
    }
    case SET_RANKING: {
      return {
        ...state,
        ...action.payload.data,
      };
    }
    case SET_RANKING_INSTRUCTIONS: {
      return {
        ...state,
        instructions: action.payload.data,
      };
    }
    case SET_RANKING_PARTICIPATION_CRITERIA: {
      return {
        ...state,
        participationCriteria: action.payload.data,
      };
    }
    case SET_RANKING_TIMELINE: {
      return {
        ...state,
        timeline: action.payload.data,
      };
    }
    default: {
      return state;
    }
  }
};
