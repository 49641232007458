import { LOAD_ROLES, LoadRolesAction, SET_ROLES, SetRolesAction } from './roles.types';

export const loadRolesAction = (rolesType: string): LoadRolesAction => ({
  type: LOAD_ROLES,
  payload: { rolesType },
});

export const setRolesAction = (rolesType: string, data: string[]): SetRolesAction => ({
  type: SET_ROLES,
  payload: { data, rolesType },
});
