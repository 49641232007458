import {
  CLEAR_CURRENT_USER,
  CREATE_USER,
  EDIT_USER,
  LOAD_USERS,
  SET_USER,
  SET_USERS,
  SingleUserState,
  UserActionTypes,
  UsersActionTypes,
} from './users.types';
import { getTableListDefaultState } from '../../../shared/helpers';
import { TableListData } from '../../../shared/constants';
import { UserDTO } from './users.dto';

const usersState: TableListData<UserDTO> = getTableListDefaultState<UserDTO>();

export const usersReducer = (state = usersState, action: UsersActionTypes): TableListData<UserDTO> => {
  switch (action.type) {
    case LOAD_USERS: {
      return {
        ...state,
        filter: action.payload.pageConfig?.filter || {},
        search: action.payload.pageConfig?.search || '',
      };
    }
    case SET_USERS: {
      return {
        ...state,
        ...action.payload.tableData,
      };
    }
    default: {
      return state;
    }
  }
};

const singleUserState: SingleUserState = {} as SingleUserState;

export const singleUserReducer = (state = singleUserState, action: UserActionTypes): SingleUserState => {
  switch (action.type) {
    case CLEAR_CURRENT_USER: {
      return singleUserState;
    }
    case CREATE_USER:
    case EDIT_USER:
    case SET_USER: {
      return {
        ...action.payload.data,
      };
    }
    default: {
      return state;
    }
  }
};
