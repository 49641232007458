import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { FORGOTTEN_PASSWORD_FORM_CONFIG } from './ForgottenPassword.constants';
import { resetPasswordAction } from '../../auth.actions';
import { ForgottenPasswordDto } from '../../auth.dto';
import { RESET_PASSWORD } from '../../auth.types';
import Button, { ButtonSizes, ButtonTypes } from '../../../../../components/Button';
import Grid from '../../../../../components/Grid';
import Form from '../../../../../components/Form';
import Typography, { TypographyVariants } from '../../../../../components/Typography';
import {
  selectRequestErrors,
  selectRequestIsLoading,
  setRequestSucceededAction,
} from '../../../../../shared/state/global-request';

const ForgottenPassword: FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const formErrors = useSelector(selectRequestErrors(RESET_PASSWORD, 'email'));
  const isLoading = useSelector(selectRequestIsLoading(RESET_PASSWORD));
  const [isRequestSent, setIsRequestSent] = useState(false);
  const [showResetPasswordForm, setShowResetPasswordForm] = useState(true);

  const handleFormSubmit = (values: ForgottenPasswordDto): void => {
    dispatch(setRequestSucceededAction(RESET_PASSWORD));

    if (values.email && values.email.trim().length > 0) {
      dispatch(resetPasswordAction(values.email));
      setIsRequestSent(true);
    }
  };

  useEffect(() => {
    if (formErrors && Object.keys(formErrors).length === 0 && isRequestSent && !isLoading) {
      setShowResetPasswordForm(false);
    }
  }, [formErrors, isLoading, isRequestSent]);

  return (
    <Grid container className="mba-auth-form-wrapper">
      <Grid item xs={11} sm={9} md={7} lg={5} center className="mba-auth-form">
        <Typography component="p" variant={TypographyVariants.h3} className="mba-auth-heading">
          Reset password
        </Typography>
        {showResetPasswordForm ? (
          <>
            <Form
              id="forgotten-password-form"
              config={FORGOTTEN_PASSWORD_FORM_CONFIG}
              errors={formErrors}
              onSubmit={handleFormSubmit}
              initialValues={{}}
              customActions={
                <div className="mba-auth-submit-button">
                  <Button
                    primary
                    type={ButtonTypes.submit}
                    size={ButtonSizes.big}
                    text="Submit"
                    isLoading={isLoading}
                  />
                </div>
              }
            />
            <Link to="/auth/login">
              <Typography component="span" variant={TypographyVariants.link} className="mba-auth-link">
                Sign in?
              </Typography>
            </Link>
          </>
        ) : (
          <>
            <Typography component="p" variant={TypographyVariants.body}>
              If you have an account for &quot;Business education - Survey administration&quot;, we have sent you an
              email that will enable you to reset your password. The link within the email will be valid for 1 hour.
              <br />
              <br />
              Please be sure to check your junk and spam folders if you haven&apos;t received an email within 5 minutes.
            </Typography>
            <Button primary size={ButtonSizes.big} text="Sign in" onClick={(): void => history.push('/auth/login')} />
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default ForgottenPassword;
