import React, { FunctionComponent } from 'react';
import { MessageType } from './Message.constants';

import styles from './Message.module.scss';

type MessageProps = {
  type: MessageType;
  content: string;
  customClassess?: string;
  onClose?: () => void;
};

const Message: FunctionComponent<MessageProps> = ({ type, content, customClassess, onClose }) => {
  return (
    <div
      className={`o-message o-message--alert o-message--${type} ${customClassess}`}
      data-o-component="o-message"
      data-o-message-close={onClose ? 'true' : 'false'}
    >
      <div className="o-message__container">
        <div className="o-message__content">
          <p className={`o-message__content-main  ${styles.main}`}>{content}</p>
          <div className="o-message__actions"></div>
        </div>
        {onClose && (
          <button
            onClick={onClose}
            className={`o-message__close ${styles.closeButton} ${styles.closeButton}--${type}`}
            aria-label="close"
            title="Close"
          ></button>
        )}
      </div>
    </div>
  );
};

export default Message;
