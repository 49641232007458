import { CreateUserDTO, EditUserDTO, UserDTO } from './users.dto';
import { RootState } from '../../../store/reducers';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';
import { TableListData } from '../../../shared/constants';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const selectCurrentUser = (state: RootState): CreateUserDTO | EditUserDTO | UserDTO => state.user;

const selectUsers = (state: RootState): TableListData<UserDTO> => state.users;

export const selectUsersTableData = createDeepEqualSelector(selectUsers, (users) => {
  return users.data;
});

export const selectUsersPageConfiguration = createDeepEqualSelector(selectUsers, (users) => {
  return {
    filter: users.filter,
    limit: users.pagination.limit,
    order: users.sortOrder.order,
    page: users.pagination.page,
    search: users.search,
    sortBy: users.sortOrder.sortBy,
    totalPages: users.pagination.totalPages,
  };
});
