import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadPrivacyAction } from './privacy.actions';
import { selectPrivacyConditions } from './privacy.selectors';
import { getHtmlFromJSON } from '../../../../../shared/helpers';

import Footer from '../Footer';
import Header from '../Header';
import Typography, { TypographyVariants } from '../../../../../components/Typography';

import styles from './PrivacyPolicy.module.scss';

const PrivacyPolicy: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const privacySelector = useSelector(selectPrivacyConditions);

  const __html = privacySelector.description ? getHtmlFromJSON(privacySelector.description) : '-';

  useEffect(() => {
    dispatch(loadPrivacyAction());
  }, [dispatch]);

  return (
    <div className="o-layout o-layout--landing" data-o-component="o-layout">
      <div className="o-layout__header">
        <Header title="Business education" tagline="Survey administration" />
      </div>
      <div className="o-layout__main o-layout-typography" data-o-component="o-syntax-highlight">
        <Typography component="h1" variant={TypographyVariants.h1}>
          <a href="https://busedsurveys.ft.com/rankingsprivacypolicy">FT Rankings Privacy Policy</a>
        </Typography>
        <div className={styles.container}>
          <div dangerouslySetInnerHTML={{ __html }} />
        </div>
      </div>
      <div className="o-layout__footer">
        <Footer />
      </div>
    </div>
  );
};

export default PrivacyPolicy;
