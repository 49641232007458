import { call, put, CallEffect, PutEffect, takeLatest } from 'redux-saga/effects';
import { FAQActionTypes, LOAD_FAQ_DATA, LoadFAQAction } from './help.types';
import { DefaultResponseDTO } from '../../shared/constants';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../../shared/state/global-request';
import { getFAQRequest } from './help.api';
import { HelpFAQDto } from './help.dto';
import { setFAQAction } from './help.actions';

function* loadFAQSaga(
  action: LoadFAQAction,
): Generator<CallEffect<DefaultResponseDTO<HelpFAQDto[]>> | PutEffect<GlobalRequestActions | FAQActionTypes>> {
  const { type: actionType } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { data } = (yield call(getFAQRequest)) as DefaultResponseDTO<HelpFAQDto[]>;
    yield put(setFAQAction(data));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

export default function* faqSaga(): Generator {
  yield takeLatest(LOAD_FAQ_DATA, loadFAQSaga);
}
