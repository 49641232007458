import { getTableListDefaultState } from '../../shared/helpers';
import { TableListData } from '../../shared/constants';
import { LeadsActionTypes, LOAD_LEADS, SET_LEADS } from './leads.types';
import { LeadsDTO } from './leads.dto';

const initialState: TableListData<LeadsDTO> = getTableListDefaultState<LeadsDTO>();

export default (state = initialState, action: LeadsActionTypes): TableListData<LeadsDTO> => {
  switch (action.type) {
    case LOAD_LEADS: {
      return {
        ...state,
        filter: action.payload.pageConfig?.filter || {},
        search: action.payload.pageConfig?.search || '',
      };
    }
    case SET_LEADS: {
      return {
        ...state,
        ...action.payload.tableData,
      };
    }
    default: {
      return state;
    }
  }
};
