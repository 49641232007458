import React, { FunctionComponent, useEffect, useRef, useState } from 'react';

import { MODAL_ANIMATION_DURATION } from './Modal.constants';

import styles from './Modal.module.scss';

type ModalProps = {
  compact?: boolean;
  fullScreen?: boolean;
  fullWidth?: boolean;
  hideClose?: boolean;
  id?: string;
  isOpen?: boolean;
  onClose?: () => void;
  shaded?: boolean;
  title?: string;
};

const Modal: FunctionComponent<ModalProps> = ({
  children,
  compact = false,
  fullScreen = false,
  fullWidth = false,
  hideClose = false,
  id,
  isOpen = false,
  onClose,
  shaded = true,
  title,
}) => {
  const modalElement = useRef() as React.MutableRefObject<HTMLInputElement>;
  const initialModalClasses = [styles.modal, 'o-overlay', 'o-overlay--overlay', 'o-overlay--modal'];
  const initialModalBackdropClasses = [styles.modalBackdrop, 'o-overlay-shadow'];
  const modalCloseButtonClasses = [styles.modalCloseButton, 'o-overlay__close'];
  const headingClasses = ['o-overlay__heading'];

  const [modalClasses, setModalClasses] = useState([...initialModalClasses]);
  const [modalBackdropClasses, setModalBackdropClasses] = useState([...initialModalBackdropClasses]);

  if (compact) {
    modalClasses.push('o-overlay--compact');
  }

  if (fullWidth) {
    modalClasses.push('o-overlay--full-width');
  }

  if (fullScreen) {
    modalClasses.push('o-overlay--full-screen');
  }

  if (shaded) {
    headingClasses.push('o-overlay__heading--shaded');
  }

  const handleModalClose = (event?: React.MouseEvent<HTMLButtonElement | HTMLDivElement>): void => {
    if (event) {
      event.preventDefault();
    }

    setModalClasses([...initialModalClasses]);
    setModalBackdropClasses([...initialModalBackdropClasses]);

    setTimeout(() => {
      if (onClose) {
        onClose();
      }
    }, MODAL_ANIMATION_DURATION);
  };

  const handleEscPress = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Esc' || event.key === 'Escape') {
      handleModalClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      setModalClasses([...initialModalClasses, styles.show]);
      setModalBackdropClasses([...initialModalBackdropClasses, styles.show]);
      modalElement.current?.focus();
    } else {
      handleModalClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <>
      <div
        className={modalBackdropClasses.join(' ')}
        onClick={handleModalClose}
        role="button"
        tabIndex={0}
        onKeyDown={handleEscPress}
      />
      <div id={id} className={modalClasses.join(' ')} role="dialog">
        <header className={headingClasses.join(' ')}>
          {!hideClose && (
            <button
              className={modalCloseButtonClasses.join(' ')}
              aria-label="Close"
              title="Close"
              tabIndex={0}
              onClick={handleModalClose}
            />
          )}
          {title && (
            <span role="heading" className="o-overlay__title" aria-level={1}>
              {title}
            </span>
          )}
        </header>
        <section className={`o-overlay__content ${styles.modalContent}`}>{children}</section>
      </div>
    </>
  );
};

export default Modal;
