import {
  AccountSetUpDTO,
  ChangeForgottenPasswordDto,
  ChangePasswordDto,
  EditProfileDTO,
  ForgottenPasswordDto,
  LoginDto,
} from './auth.dto';
import { get, post, put } from '../../../shared/api';
import { BasicResponseDTO, TERMS_ACCEPTED_STATUS } from '../../../shared/constants';

export const checkTokenValidity = (): Promise<BasicResponseDTO> => {
  return get<BasicResponseDTO>('/auth/login/token/check');
};

export const logoutUserRequest = (): Promise<BasicResponseDTO> => {
  return get<BasicResponseDTO>('/auth/logout');
};

export const loginSchoolUserRequest = (payload: LoginDto): Promise<BasicResponseDTO> => {
  return post<BasicResponseDTO, LoginDto>('/auth/login/school-user', payload);
};

export const registerSchoolUserRequest = (payload: AccountSetUpDTO): Promise<BasicResponseDTO> => {
  return post<BasicResponseDTO, AccountSetUpDTO>('/auth/register/school-user', payload);
};

export const resetPasswordRequest = (email: string): Promise<BasicResponseDTO> => {
  return post<BasicResponseDTO, ForgottenPasswordDto>('/auth/forgot-password', { email });
};

export const resetPasswordChangeRequest = (payload: ChangeForgottenPasswordDto): Promise<BasicResponseDTO> => {
  return post<BasicResponseDTO, ChangeForgottenPasswordDto>('/auth/forgot-password/change', payload);
};

export const changePasswordRequest = (payload: ChangePasswordDto): Promise<BasicResponseDTO> => {
  return post<BasicResponseDTO, ChangePasswordDto>('/auth/change-password', payload);
};

export const updateProfileRequest = (payload: EditProfileDTO): Promise<BasicResponseDTO> => {
  return post<BasicResponseDTO, EditProfileDTO>('/auth/update-profile', payload);
};

export const refreshTokenRequest = (): Promise<BasicResponseDTO> => {
  return get<BasicResponseDTO>('auth/login/school-user/token/refresh');
};

export const acceptTermsForSchoolRequest = (schoolId: number): Promise<BasicResponseDTO> => {
  return put<BasicResponseDTO, { termsStatus: number }>(`/schools/${schoolId}/terms`, {
    termsStatus: TERMS_ACCEPTED_STATUS,
  });
};
