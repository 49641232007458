import { FormConfig } from '../../../../../components/Form';

export const PASSWORD_SETUP_FORM_CONFIG: FormConfig = {
  email: {
    label: 'E-mail',
    placeholder: 'Enter your e-mail address',
    fieldType: 'text',
    subTitle: 'Fill in the e-mail address, from which you opened this link',
    validations: {
      required: {
        errorText: 'Please enter a valid e-mail address!',
      },
      regex: {
        errorText: 'Please enter a valid e-mail address!',
        rule: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
      },
    },
  },
  password: {
    label: 'Password',
    placeholder: 'Enter your password',
    fieldType: 'password',
    subTitle: 'Use 8 or more characters with a mix of letters, numbers & symbols',
    validations: {
      required: {
        errorText: `Please enter your password!`,
      },
    },
  },
  confirmPassword: {
    label: 'Confirm password',
    placeholder: 'Confirm your password',
    fieldType: 'password',
    validations: {
      required: {
        errorText: 'Please confirm your password!',
      },
    },
  },
};

export const USER_ACTIVATION_CONFIRM_MSG = 'Your account has been activated successfully.';
