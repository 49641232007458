import React, { FunctionComponent } from 'react';

import { CheckboxInputOption, CommonCheckboxProps } from './Checkbox.constants';
import CheckboxGroup from './CheckboxGroup/CheckboxGroup';
import { InputWrapperProps } from '../InputWrapper';

type CheckboxProps = {
  text?: string;
  value?: boolean;
};

type Props = CommonCheckboxProps & CheckboxProps & InputWrapperProps;

const Checkbox: FunctionComponent<Props> = ({ name, text, shrunken, value = false, ...others }) => {
  const options: CheckboxInputOption[] = [
    {
      text,
      name,
      value,
    },
  ];

  return <CheckboxGroup name={name} options={options} shrunken={shrunken} inline={shrunken} {...others} />;
};

export default Checkbox;
