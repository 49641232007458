import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { loadRankingParticipationCriteriaAction } from '../ranking-profile.actions';
import { RankingDTO } from '../ranking-profile.dto';
import { selectCurrentRanking } from '../ranking-profile.selectors';
import { RankingProfileRouteParams } from '../ranking-profile.types';
import { getHtmlFromJSON } from '../../../shared/helpers';

const ParticipationCriteria: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { id: rankingId } = useParams<RankingProfileRouteParams>();
  const currentRanking = useSelector(selectCurrentRanking) as RankingDTO;
  const { participationCriteria } = currentRanking;

  useEffect(() => {
    if (rankingId) {
      dispatch(loadRankingParticipationCriteriaAction(parseInt(rankingId)));
    }
  }, [dispatch, rankingId]);

  const __html = participationCriteria ? getHtmlFromJSON(participationCriteria) : '-';

  return (
    <div className="flexColumnContainer mba-background--white">
      <div dangerouslySetInnerHTML={{ __html }} />
    </div>
  );
};

export default ParticipationCriteria;
