import { RootState } from '../../../store/reducers';
import { SchoolUserDTO } from './auth.dto';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';
import { AuthState } from './auth.types';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const selectAuth = (state: RootState): AuthState => state.auth;
export const selectCurrentSchool = createDeepEqualSelector(selectAuth, (auth) => {
  return auth.currentSchool;
});

export const selectIsTokenVerified = (state: RootState): boolean => state.auth.isTokenVerified;

export const selectUserToken = (state: RootState): string => state.auth.token;

export const selectAuthUser = (state: RootState): SchoolUserDTO => state.auth.data;

export const selectAreTermsAccepted = (state: RootState): boolean | null => {
  if (state.auth.data.id) {
    return state.auth.areTermsAccepted;
  }

  return null;
};
