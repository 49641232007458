import { DashboardRankingsDto } from './dahboards.dto';

export const LOAD_DASHBOARD_DATA = 'LOAD_DASHBOARD_DATA';
export const SET_DASHBOARD_DATA = 'SET_DASHBOARD_DATA';

export interface LoadDashboardDataAction {
  type: typeof LOAD_DASHBOARD_DATA;
  payload: { schoolId: number };
}

export interface SetDashboardDataAction {
  type: typeof SET_DASHBOARD_DATA;
  payload: { data: DashboardRankingsDto };
}

export type DashboardActionTypes = LoadDashboardDataAction | SetDashboardDataAction;
