import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import EditableGroup from '../../../components/EditableGroup';
import Icon from '../../../components/Icon';
import Label, { LabelStatus } from '../../../components/Label';
import Typography, { TypographyVariants } from '../../../components/Typography';
import { LeadProfileRouteParams, LEAD_PROFILE_CONFIG_FIRST, LEAD_PROFILE_CONFIG_SECOND } from './LeadProfile.constants';
import { selectLeadsTableData } from '../../Leads/leads.selectors';
import { LeadDTO } from '../../Leads/leads.dto';
import { loadLeadProfileAction } from '../lead-profile.actions';
import { selectCurrentLead } from '../lead-profile.selectors';
import { convertToLeadProfile, modifiedConfig } from '../../Leads/helpers';
import { FormConfig } from '../../../components/Form';

const LeadProfile: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { id: leadId } = useParams<LeadProfileRouteParams>();
  const selectedData = useSelector(selectLeadsTableData);
  const currentLead = useSelector(selectCurrentLead);
  const { status, user = {} } = currentLead;
  const { answers = [] } = user;

  useEffect(() => {
    dispatch(loadLeadProfileAction(Number(leadId)));
  }, [dispatch, leadId, selectedData]);

  const setSecondConfig = (): FormConfig => {
    return { ...LEAD_PROFILE_CONFIG_SECOND, ...modifiedConfig(answers) };
  };

  return (
    <>
      <Typography component="h1" variant={TypographyVariants.h1} className="mba-center--vertically">
        Leads
      </Typography>
      <div className="mba-background--white">
        <EditableGroup<LeadDTO>
          id="lead-profile"
          columnSize={6}
          data={convertToLeadProfile(currentLead)}
          editButtonLabel="Edit"
          formConfig={LEAD_PROFILE_CONFIG_FIRST}
          headingType="back"
          heading={
            <Link
              className="o-layout__unstyled-element mba-back-arrow mba-center--vertically"
              title="Go back to the leads"
              to="/leads"
            >
              <Icon name="arrow-left" size={30} />
              <span className="mba-section-title mba-text-capitalize mba-margin-right-10">{`${user.firstName} ${user.lastName}`}</span>
              {status && (
                <Label color={status.color} status={LabelStatus.active}>
                  {status.label}
                </Label>
              )}
            </Link>
          }
          isEditable={false}
        />
        <hr className="mba-separator" />
        <EditableGroup<LeadDTO>
          id="lead-profile"
          columnSize={6}
          data={convertToLeadProfile(currentLead)}
          formConfig={setSecondConfig()}
          isEditable={false}
        />
      </div>
    </>
  );
};

export default LeadProfile;
