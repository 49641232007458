import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button, { ButtonSizes } from '../../../components/Button';

import Pager, { INITIAL_PAGE } from '../../../components/Pager';
import Table from '../../../components/Table';
import Typography, { TypographyVariants } from '../../../components/Typography';
import { ASCENDING_ORDER_DIRECTION, DESCENDING_ORDER_DIRECTION } from '../../../shared/constants';
import { convertToLeadsTableData } from '../../../shared/helpers';
import { selectCountriesData } from '../../../shared/state/countries';
import { loadCountriesAction } from '../../../shared/state/countries';
import { downloadLeadsAction, loadLeadsAction } from '../leads.actions';
import { selectLeadsPageConfiguration, selectLeadsTableData } from '../leads.selectors';
import { LEADS_TABLE_COLUMN_DEFINITION } from './Leads.constants';
import LeadsFilters from './LeadsFilters';

const Leads: FunctionComponent = () => {
  const [downloadData, setDownloadData] = useState<string[]>();
  const dispatch = useDispatch();
  const pageConfig = useSelector(selectLeadsPageConfiguration);
  const selectedData = useSelector(selectLeadsTableData);
  const countriesData = useSelector(selectCountriesData);
  const leadsData = convertToLeadsTableData(selectedData ?? []);
  const [allRowsSelected, setAllRowsSelected] = useState(false);

  useEffect(() => {
    dispatch(loadLeadsAction());
    dispatch(loadCountriesAction('country'));
  }, [dispatch]);

  const addSelectedRows = (rowsData: string[]): void => {
    setDownloadData(rowsData);
  };

  const checkAllSelectedRows = (areAllRowsSelected: boolean): void => {
    setDownloadData([]);
    setAllRowsSelected(areAllRowsSelected);
  };

  const handlePageChanged = (page: number): void => {
    dispatch(loadLeadsAction({ ...pageConfig, page }));
  };

  const handleSortByChanged = (fieldName: string): void => {
    const orderDirection =
      pageConfig.sortBy !== fieldName
        ? DESCENDING_ORDER_DIRECTION
        : pageConfig.order === ASCENDING_ORDER_DIRECTION
        ? DESCENDING_ORDER_DIRECTION
        : ASCENDING_ORDER_DIRECTION;
    dispatch(loadLeadsAction({ ...pageConfig, sortBy: fieldName, order: orderDirection }));
  };

  const onDownload = () => {
    return (): void => {
      if (allRowsSelected) {
        dispatch(downloadLeadsAction());
      } else {
        dispatch(downloadLeadsAction(downloadData, pageConfig));
      }
    };
  };

  return (
    <>
      <div className="mba-heading--wrapper">
        <Typography component="p" variant={TypographyVariants.h1} className="mba-heading--title">
          Prospective Students
        </Typography>
        <Button
          text={allRowsSelected ? 'Download All' : 'Download'}
          size={ButtonSizes.normal}
          primary
          onClick={onDownload()}
          disabled={!downloadData?.length && !allRowsSelected}
        />
      </div>
      <div className="mba-table-wrapper">
        <div className="mba-table-filters mba-background--white">
          <LeadsFilters pageConfig={pageConfig} filtersData={{ countriesData }} />
        </div>
        <div className="mba-table-container mba-background--white">
          <Table
            id="leads-list"
            stripes={false}
            columns={LEADS_TABLE_COLUMN_DEFINITION}
            data={leadsData}
            addSelectedRows={addSelectedRows}
            checkAllSelectedRows={checkAllSelectedRows}
            sortingOptions={{
              order: pageConfig.order || DESCENDING_ORDER_DIRECTION,
              sortBy: pageConfig.sortBy || '',
            }}
            onSortByColumn={handleSortByChanged}
          />
          <Pager
            currentPage={pageConfig.page}
            totalPages={pageConfig.totalPages || INITIAL_PAGE}
            onPageChange={handlePageChanged}
          />
        </div>
      </div>
    </>
  );
};

export default Leads;
