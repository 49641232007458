import { FormConfig } from '../../../../../components/Form';

export const FORGOTTEN_PASSWORD_FORM_CONFIG: FormConfig = {
  email: {
    label: 'E-mail',
    placeholder: 'Enter your e-mail address',
    fieldType: 'text',
    subTitle: `Please enter the email address used for accessing the school portal.
               We will email you instructions to reset your password.`,
    validations: {
      required: {
        errorText: 'Please enter a valid e-mail address!',
      },
      regex: {
        errorText: 'Please enter a valid e-mail address!',
        rule: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
      },
    },
  },
};
