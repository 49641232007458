export enum LabelSize {
  standard = '',
  big = 'big',
  small = 'small',
}

export enum LabelTier {
  platinum = 'tier-platinum',
  gold = 'tier-gold',
  silver = 'tier-silver',
  bronze = 'tier-bronze',
}

export enum LabelStatus {
  active = 'support-active',
  maintained = 'support-maintained',
  experimental = 'support-experimental',
  deprecated = 'support-deprecated',
  dead = 'support-dead',
}
