import { NUMBER_OF_CAMPUSES_PER_PAGE } from './components/CampusesList.constants';
import { CampusDTO } from '../CampusProfile';
import { get, put } from '../../../../shared/api';
import { BasicResponseDTO, PagedResponseDTO, PageSearchQueryConfig } from '../../../../shared/constants';
import { generateSearchQuery } from '../../../../shared/helpers';

export const getCampusesRequest = (
  schoolId: number,
  pageConfig: PageSearchQueryConfig = {},
): Promise<PagedResponseDTO<CampusDTO[]>> => {
  const query = generateSearchQuery({
    ...pageConfig,
    limit: NUMBER_OF_CAMPUSES_PER_PAGE,
  });
  return get<PagedResponseDTO<CampusDTO[]>>(`/schools/${schoolId}/campuses${query}`);
};

export const editDefaultCampusRequest = (schoolId: number, campusId: number): Promise<BasicResponseDTO> => {
  return put<BasicResponseDTO, { defaultCampusId: number }>(`/schools/${schoolId}`, {
    defaultCampusId: campusId,
  });
};
