import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import TimelineIndicator from './TimelineIndicator';
import TimelineStepDates from './TimelineStepDates';
import { downloadTimelineSpreadsheetAction } from '../ranking-timeline.actions';
import { TimelineSpreadsheetDTO } from '../ranking-timeline.dto';
import { RankingTimelineStepDTO } from '../../ranking-profile.dto';
import Button from '../../../../components/Button';
import Grid from '../../../../components/Grid';
import { TimelineExpandStepStatus } from '../RankingTimeline.constants';

const EmbargoTableStep: FunctionComponent<RankingTimelineStepDTO> = ({
  rankingId,
  endDate,
  name,
  startDate,
  spreadsheet,
  status,
}) => {
  const dispatch = useDispatch();
  const [expand, setExpand] = useState<boolean>(false);

  useEffect(() => {
    setExpand(TimelineExpandStepStatus[status]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const handleDownload = (): void => {
    const spreadsheetData: TimelineSpreadsheetDTO = { rankingId, type: 'embargo' };
    dispatch(downloadTimelineSpreadsheetAction(spreadsheetData));
  };

  return (
    <Grid container key={name}>
      <Grid item xs={5}>
        <TimelineStepDates
          endDate={endDate}
          endDateLabel="End"
          isEndDateRequired={true}
          startDate={startDate}
          startDateLabel="Start"
          isStartDateRequired={true}
        />
      </Grid>
      <Grid item xs={1} className="mba-no-padding">
        <TimelineIndicator timeIndication={status} />
      </Grid>
      <Grid item xs={6} className="mba-margin-bottom-20 mba-no-padding">
        <button
          className="mba-text--accent mba-no-margin mba-text--bold mba-timeline-button--link mba-margin-bottom-15"
          onClick={(): void => setExpand(!expand)}
        >
          {name}
        </button>
        {expand && (
          <div className="mba-actions mba-margin-top-10">
            <Button disabled={!spreadsheet} primary text="Download embargo table" onClick={handleDownload} />
          </div>
        )}
        <br />
      </Grid>
    </Grid>
  );
};

export default EmbargoTableStep;
