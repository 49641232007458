import React, { FunctionComponent, MouseEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useToasts } from 'react-toast-notifications';

import Typography, { TypographyVariants } from '../../../components/Typography';
import { getHtmlFromJSON } from '../../../shared/helpers';
import { selectRequestStatus } from '../../../shared/state/global-request';
import { DOWNLOAD_STEP_ATTACHMENT, downloadStepAttachmentAction } from '../CurrentTimeline';
import { DOWNLOAD_STEP_ATTACHMENT_ERROR } from '../RankingProfile.constants';
import { loadRankingInstructionsAction, loadRankingTimelineAction } from '../ranking-profile.actions';
import { RankingDTO } from '../ranking-profile.dto';
import { selectCurrentRanking } from '../ranking-profile.selectors';
import { RankingProfileRouteParams } from '../ranking-profile.types';

const Instructions: FunctionComponent = () => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const { id: rankingId } = useParams<RankingProfileRouteParams>();
  const downloadAttachmentStatus = useSelector(selectRequestStatus(DOWNLOAD_STEP_ATTACHMENT));
  const currentRanking = useSelector(selectCurrentRanking) as RankingDTO;
  const { instructions, timeline = [] } = currentRanking;

  useEffect(() => {
    if (!downloadAttachmentStatus.isLoading && downloadAttachmentStatus.isSuccess === false) {
      // delete downloadAttachmentStatus.isSuccess;
      addToast(DOWNLOAD_STEP_ATTACHMENT_ERROR, { appearance: 'error' });
    }
  }, [addToast, downloadAttachmentStatus]);

  useEffect(() => {
    if (rankingId) {
      dispatch(loadRankingInstructionsAction(parseInt(rankingId)));
      dispatch(loadRankingTimelineAction(parseInt(rankingId)));
    }
  }, [dispatch, rankingId]);

  const __html = instructions ? getHtmlFromJSON(instructions) : '-';

  const downloadAttachment = (event: MouseEvent): void => {
    event.preventDefault();
    dispatch(
      downloadStepAttachmentAction({ rankingId: +rankingId, stepId: 1, attachmentName: timeline[0]?.attachment?.name }),
    );
  };

  return (
    <div className="flexColumnContainer mba-background--white">
      <Typography className="mba-no-margin" component="p" variant={TypographyVariants.h5}>
        Attachment
      </Typography>
      <div className="mba-margin-bottom-15">
        {timeline[0] && timeline[0].attachment ? (
          <div className="mba-attachment-item">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <a type="button" className="mba-mr-10" onClick={downloadAttachment}>
              {timeline[0].attachment.name}
            </a>
          </div>
        ) : (
          '-'
        )}
      </div>
      <Typography className="mba-no-margin" component="p" variant={TypographyVariants.h5}>
        Instructions
      </Typography>
      <div dangerouslySetInnerHTML={{ __html }} />
    </div>
  );
};

export default Instructions;
