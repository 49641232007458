import React, { FunctionComponent } from 'react';

import styles from './TableRowNoRecords.module.scss';

type TableNoRecordsRowProps = {
  columnsCount?: number;
};

const TableRowNoRecords: FunctionComponent<TableNoRecordsRowProps> = ({ columnsCount = 1 }) => (
  <tr className={styles.noContentRow}>
    <td colSpan={columnsCount} className={styles.noContentCell}>
      No records.
    </td>
  </tr>
);

export default TableRowNoRecords;
