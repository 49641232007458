import React, { ChangeEventHandler, FocusEventHandler, FunctionComponent } from 'react';

import InputWrapper, { InputWrapperProps } from '../InputWrapper';

type TextAreaProps = {
  disabled?: boolean;
  error?: string;
  id?: string;
  name?: string;
  onBlur?: FocusEventHandler;
  onChange?: ChangeEventHandler;
  optional?: boolean;
  placeholder?: string;
  required?: boolean;
  rows?: number;
  touched?: boolean;
  valid?: boolean;
  value?: string | number;
};

type Props = TextAreaProps & InputWrapperProps;

const TextArea: FunctionComponent<Props> = ({
  disabled = false,
  error,
  id,
  name,
  onBlur,
  onChange,
  optional = false,
  placeholder,
  required = false,
  rows,
  shrunken = false,
  subTitle,
  title,
  touched,
  valid,
  value,
  verticallyCentered,
  ...others
}) => {
  const textAreaClasses = ['o-forms-input', 'o-forms-input--textarea'];

  if (error || (touched && !valid)) {
    textAreaClasses.push('o-forms-input--invalid');
  } else if (touched && valid) {
    textAreaClasses.push('o-forms-input--valid');
  }

  return (
    <InputWrapper
      id={id}
      inline={shrunken}
      optional={optional}
      required={required}
      shrunken={shrunken}
      subTitle={subTitle}
      title={title}
      verticallyCentered={verticallyCentered}
    >
      <div className={textAreaClasses.join(' ')}>
        <textarea
          id={id}
          name={name}
          disabled={disabled}
          required={required}
          aria-invalid={!!error}
          placeholder={placeholder || ''}
          rows={rows}
          value={value || ''}
          onChange={onChange}
          onBlur={onBlur}
          {...others}
        />
        {error && <span className="o-forms-input__error">{error}</span>}
      </div>
    </InputWrapper>
  );
};

export default TextArea;
