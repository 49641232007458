import { RootState } from '../../store/reducers';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';
import { TableListData } from '../../shared/constants';
import { RankingDTO } from '../RankingProfile/ranking-profile.dto';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const selectRankings = (state: RootState): TableListData<RankingDTO> => state.rankings;

export const selectRankingsTableData = createDeepEqualSelector(selectRankings, (rankings) => {
  return rankings.data;
});

export const selectRankingsPageConfiguration = createDeepEqualSelector(selectRankings, (rankings) => {
  return {
    filter: rankings.filter,
    limit: rankings.pagination.limit,
    order: rankings.sortOrder.order,
    page: rankings.pagination.page,
    search: rankings.search,
    sortBy: rankings.sortOrder.sortBy,
    totalPages: rankings.pagination.totalPages,
  };
});
