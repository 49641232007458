import { RankingDTO } from '../RankingProfile';
import { PageSearchQueryConfig, TableListData } from '../../shared/constants';

export const LOAD_RANKINGS = 'LOAD_RANKINGS';
export const SET_RANKINGS = 'SET_RANKINGS';

export interface LoadRankingsAction {
  type: typeof LOAD_RANKINGS;
  payload: { pageConfig?: PageSearchQueryConfig };
}

export interface SetRankingsAction {
  type: typeof SET_RANKINGS;
  payload: { tableData: TableListData<RankingDTO> };
}

export type RankingsActionTypes = LoadRankingsAction | SetRankingsAction;
