import { SchoolActionTypes, SchoolProfileState, SET_SCHOOL } from './school-profile.types';

const initialState: SchoolProfileState = {} as SchoolProfileState;

export default (state = initialState, action: SchoolActionTypes): SchoolProfileState => {
  switch (action.type) {
    case SET_SCHOOL: {
      return {
        ...action.payload.data,
      };
    }
    default: {
      return state;
    }
  }
};
