import React, { PropsWithChildren, ReactNode, useEffect, useState } from 'react';

import { calculateGroupColumnSize, getColumnDetailClassName } from './EditableGroup.helpers';
import Button, { ButtonSizes } from '../Button';
import {
  FormConfig,
  FormConfigProps,
  FormControlProps,
  FormDataValues,
  FormErrors,
  FormValue,
  getErrorMessage,
  getFormValues,
  initializeFormFields,
} from '../Form';
import Grid, { DEFAULT_GRID_COLSPAN_SIZE, GridColSpanSize } from '../Grid';
import ViewInput from '../ViewInput';
import { formatDateString, getFormErrors, getPropertyValue } from '../../shared/helpers';
import { DATETIME_FORMAT_REGEX } from '../../shared/constants';
import { useObjectEqualityEffect } from '../../shared/helpers.hooks';
import styles from './EditableGroup.module.scss';

interface PrefixColumn {
  columnSize?: {
    xs?: boolean | GridColSpanSize;
    sm?: boolean | GridColSpanSize;
    md?: boolean | GridColSpanSize;
    lg?: boolean | GridColSpanSize;
    xl?: boolean | GridColSpanSize;
  };
  content: ReactNode;
}

interface EditableGroupProps<T extends { [key: string]: any }> {
  id?: string;
  columnSize?: GridColSpanSize;
  data: T;
  editButtonLabel?: string;
  formConfig: FormConfig;
  formErrors?: FormErrors;
  heading?: ReactNode;
  headingType?: 'normal' | 'back';
  isEditable?: boolean;
  onEditCancel?: () => void;
  onEditSubmit?: (data: FormDataValues) => void;
  prefixColumn?: PrefixColumn;
}

function EditableGroup<T extends { [key: string]: any }>({
  id,
  columnSize = DEFAULT_GRID_COLSPAN_SIZE as GridColSpanSize,
  data,
  editButtonLabel = 'Edit',
  formConfig,
  formErrors = {} as FormErrors,
  heading,
  headingType = 'normal',
  isEditable = true,
  onEditCancel,
  onEditSubmit,
  prefixColumn,
}: PropsWithChildren<EditableGroupProps<T>>): JSX.Element {
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [controls, setControls] = useState({} as FormConfigProps);

  useObjectEqualityEffect(() => {
    if (Object.keys(data).length) {
      const initialValues = {} as FormDataValues;

      Object.keys(data).forEach((key: string) => {
        const currentValue = getPropertyValue(data, key as keyof T);

        if (DATETIME_FORMAT_REGEX.test(currentValue.toString())) {
          initialValues[key] = formatDateString(currentValue.toString());
        } else {
          initialValues[key] = (controls[key]?.value as FormValue) || getPropertyValue(data, key as keyof T);
        }
      });

      const controlsData = initializeFormFields(controls, formConfig, initialValues, formErrors);
      setControls(controlsData);
    }
  }, [formConfig, data, formErrors]);

  const handleChange = (name: string, value: FormValue): void => {
    const oldControls: FormConfigProps = controls ? { ...controls } : {};
    const error = getErrorMessage(
      value,
      (controls[name] as FormControlProps).validations,
      (controls[name] as FormControlProps).fieldType,
    );

    const currentControl = {
      [name]: {
        ...(oldControls[name] as FormControlProps),
        error,
        touched: true,
        valid: !error,
        value,
      },
    };

    const updatedControls: FormConfigProps = {
      ...oldControls,
      ...currentControl,
    };

    setControls(updatedControls);
  };

  const handleEditCancel = (): void => {
    setIsEditOpen(false);
    onEditCancel && onEditCancel();

    if (Object.keys(data).length) {
      const initialValues = {} as FormDataValues;

      Object.keys(data).forEach((key: string) => {
        initialValues[key] = getPropertyValue(data, key as keyof T);
      });

      const controlsData = initializeFormFields(controls, formConfig, initialValues, formErrors);
      setControls(controlsData);
    }
  };

  const handleEditSubmit = (event: React.FormEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    onEditSubmit && onEditSubmit(getFormValues(controls));
  };

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      handleEditCancel();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const editButtons: ReactNode = isEditable && (
    <>
      {isEditOpen ? (
        <div className="mba-actions">
          <Button primary text="Save" size={ButtonSizes.big} onClick={handleEditSubmit} />
          <Button text="Cancel" size={ButtonSizes.big} onClick={handleEditCancel} />
        </div>
      ) : (
        <Button primary text={editButtonLabel} size={ButtonSizes.big} onClick={(): void => setIsEditOpen(true)} />
      )}
    </>
  );

  const headingBlock =
    headingType === 'back' ? (
      <div className="mba-space-between">
        {heading}
        {editButtons}
      </div>
    ) : (
      <div className="mba-heading--wrapper mba-heading--table">
        {heading}
        {editButtons}
      </div>
    );

  const groupColumns = Object.entries(controls).map(([key, control], index) => {
    const fieldErrors = getFormErrors(formErrors, key);
    const columnClasses = ['mba-details-column'];

    if (control.error) {
      fieldErrors.push(control.error as string);
    }

    const customColumnClass = getColumnDetailClassName(columnSize, index);
    if (customColumnClass) {
      columnClasses.push(customColumnClass);
    }
    if (id === 'lead-profile') {
      columnClasses.push(styles.changeBorder);
    }

    return (
      <Grid item xs={DEFAULT_GRID_COLSPAN_SIZE} sm={columnSize} key={key} className={columnClasses.join(' ')}>
        <ViewInput
          id={id}
          {...(control as FormControlProps)}
          name={key}
          isEdit={isEditOpen}
          onChange={handleChange}
          error={fieldErrors[0]}
        />
      </Grid>
    );
  });

  let gridContent: ReactNode;
  if (prefixColumn) {
    const { columnSize: prefixSize, content } = prefixColumn;

    gridContent = (
      <>
        <Grid item {...prefixSize} className="mba-details-column mba-details-column--first">
          {content}
        </Grid>
        <Grid
          item
          className="mba-details-column  mba-details-column--last"
          xs={calculateGroupColumnSize(prefixSize?.xs || DEFAULT_GRID_COLSPAN_SIZE, DEFAULT_GRID_COLSPAN_SIZE)}
          sm={calculateGroupColumnSize(prefixSize?.sm)}
          md={calculateGroupColumnSize(prefixSize?.md)}
          lg={calculateGroupColumnSize(prefixSize?.lg)}
          xl={calculateGroupColumnSize(prefixSize?.xl)}
        >
          <Grid container compact className="mba-column-group">
            {groupColumns}
          </Grid>
        </Grid>
      </>
    );
  } else {
    gridContent = groupColumns;
  }

  return (
    <>
      {headingBlock}
      <Grid container compact className="mba-column-group">
        {gridContent}
      </Grid>
    </>
  );
}

export default EditableGroup;
