import { call, CallEffect, put, PutEffect, takeEvery } from 'redux-saga/effects';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../global-request';
import { DefaultResponseDTO } from '../../constants';
import { CountriesActionTypes, LoadCountriesAction, LOAD_COUNTRIES } from './countries.types';
import { getCountriesRequest } from './countries.api';
import { setCountriesAction } from './countries.actions';
import { CountryDTO } from './countries.dto';

function* loadCountriesData(
  action: LoadCountriesAction,
): Generator<
  CallEffect<DefaultResponseDTO<CountryDTO[]>> | PutEffect<CountriesActionTypes> | PutEffect<GlobalRequestActions>
> {
  const { type: actionType } = action;
  const { country } = action.payload;

  try {
    yield put(setRequestStartedAction(actionType));
    const { data } = (yield call(getCountriesRequest, country)) as DefaultResponseDTO<CountryDTO[]>;
    yield put(setCountriesAction(data));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

export default function* countriesSaga(): Generator {
  yield takeEvery(LOAD_COUNTRIES, loadCountriesData);
}
