import { EditSchoolDTO, EditSchoolLogoDTO, SchoolDTO } from './school-profile.dto';
import {
  EDIT_SCHOOL_ACCREDITATIONS,
  EDIT_SCHOOL_LOGO,
  EDIT_SCHOOL_PROFILE,
  EditSchoolAccreditationsAction,
  EditSchoolLogoAction,
  EditSchoolProfileAction,
  LOAD_SCHOOL,
  LoadSchoolAction,
  SET_SCHOOL,
  SetSchoolAction,
} from './school-profile.types';

export const editSchoolLogoAction = (data: EditSchoolLogoDTO): EditSchoolLogoAction => ({
  type: EDIT_SCHOOL_LOGO,
  payload: { data },
});

export const loadSchoolAction = (id: number): LoadSchoolAction => ({
  type: LOAD_SCHOOL,
  payload: { id },
});

export const editSchoolProfileAction = (data: EditSchoolDTO): EditSchoolProfileAction => ({
  type: EDIT_SCHOOL_PROFILE,
  payload: { data },
});

export const editSchoolAccreditationsAction = (
  schoolId: number,
  accreditationIds: number[],
): EditSchoolAccreditationsAction => ({
  type: EDIT_SCHOOL_ACCREDITATIONS,
  payload: { accreditationIds, schoolId },
});

export const setSchoolAction = (data: SchoolDTO): SetSchoolAction => ({
  type: SET_SCHOOL,
  payload: { data },
});
