import { RootState } from '../../../../store/reducers';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';
import { TableListData } from '../../../../shared/constants';
import { CampusDTO } from '../CampusProfile/campus-profile.dto';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const selectCampuses = (state: RootState): TableListData<CampusDTO> => state.campuses;

export const selectCampusesTableData = createDeepEqualSelector(selectCampuses, (campuses) => {
  return campuses.data;
});

export const selectCampusesPageConfiguration = createDeepEqualSelector(selectCampuses, (campuses) => {
  return {
    filter: campuses.filter,
    limit: campuses.pagination.limit,
    order: campuses.sortOrder.order,
    page: campuses.pagination.page,
    search: campuses.search,
    sortBy: campuses.sortOrder.sortBy,
    totalPages: campuses.pagination.totalPages,
  };
});
