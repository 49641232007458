import React, { FunctionComponent } from 'react';

import { CAMPUS_CONFIRMATION_MESSAGE, DEFAULT_CAMPUS_CONFIRMATION_MESSAGE } from './RemoveCampusPopup.constants';
import Button, { ButtonSizes } from '../../../../../../components/Button';
import Typography from '../../../../../../components/Typography';

type RemoveCampusPopupProps = {
  onCancel: () => void;
  onConfirm: (id: number) => void;
  campusId: number;
  isDefaultCampus: boolean;
};

const getConfirmationMessage = (isDefaultCampus: boolean): string => {
  return isDefaultCampus ? DEFAULT_CAMPUS_CONFIRMATION_MESSAGE : CAMPUS_CONFIRMATION_MESSAGE;
};

const RemoveCampusPopup: FunctionComponent<RemoveCampusPopupProps> = ({
  campusId,
  isDefaultCampus,
  onCancel,
  onConfirm,
}) => (
  <>
    <Typography>{getConfirmationMessage(isDefaultCampus)}</Typography>
    <div className="mba-actions">
      <Button onClick={onCancel} text="Cancel" size={ButtonSizes.big} />
      <Button onClick={(): void => onConfirm(campusId)} primary text="Yes" size={ButtonSizes.big} />
    </div>
  </>
);

export default RemoveCampusPopup;
