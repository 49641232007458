import React, { FunctionComponent, useRef } from 'react';
import { Link } from 'react-router-dom';

import styles from '../UserNavigation/UserNavigation.module.scss';

type UserNavigationLinksProps = {
  logout: () => void;
  handleNavElementClick?: () => void;
};

const UserNavigationLinks: FunctionComponent<UserNavigationLinksProps> = ({ logout, handleNavElementClick }) => {
  const handleLogout = (e: React.MouseEvent<HTMLButtonElement>): void => {
    logout();
    if (handleNavElementClick) {
      handleNavElementClick();
    }
  };

  return (
    <>
      <li>
        <Link onClick={handleNavElementClick} to="/profile">
          Profile
        </Link>
      </li>
      {/* <li>
        <Link onClick={handleNavElementClick} to="/settings">
          Settings
        </Link>
      </li> */}
      <li>
        <Link onClick={handleNavElementClick} to="/terms-and-conditions">
          Terms & Conditions
        </Link>
      </li>
      <li>
        <button className={styles.logoutButton} onClick={handleLogout}>
          Log out
        </button>
      </li>
    </>
  );
};

export default UserNavigationLinks;
