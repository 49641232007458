import { ChangeEventHandler, FocusEventHandler } from 'react';

export type DatePickerProps = {
  autofocus?: boolean;
  disabled?: boolean;
  error?: string | boolean;
  fullWidth?: boolean;
  id?: string;
  name?: string;
  onBlur?: FocusEventHandler<HTMLElement>;
  onChange?: ChangeEventHandler<HTMLElement>;
  placeholder?: string;
  required?: boolean;
  small?: boolean;
  title?: string;
  touched?: boolean;
  valid?: boolean;
  value?: string;
};

export const DEFAULT_DATE_FORMAT = 'dd/MM/yyyy';
