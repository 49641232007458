import React, { FunctionComponent } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Header from '../Header';
import Footer from '../Footer';
import AccountSetUp from '../../../Auth/components/AccountSetUp';
import ForgottenPassword from '../../../Auth/components/ForgottenPassword';
import Login from '../../../Auth/components/Login';
import Forbidden from '../../../Forbidden';
import ChangeForgottenPassword from '../../../Auth/components/ChangeForgottenPassword';

const AuthLayout: FunctionComponent = () => (
  <div className="o-layout o-layout--landing" data-o-component="o-layout">
    <div className="o-layout__header">
      <Header title="Business education" tagline="Survey administration" />
    </div>
    <div className="o-layout__main o-layout-typography" data-o-component="o-syntax-highlight">
      <Switch>
        <Route path="/auth/login" component={Login} />
        <Route path="/auth/forgotten-password/change/:token" component={ChangeForgottenPassword} />
        <Route path="/auth/forgotten-password" component={ForgottenPassword} />
        <Route path="/auth/account-setup/:registerToken" component={AccountSetUp} />
        <Route path="/auth/forbidden" component={Forbidden} />
        <Redirect from="/" to="/auth/login" />
      </Switch>
    </div>
    <div className="o-layout__footer">
      <Footer />
    </div>
  </div>
);

export default AuthLayout;
