export enum ButtonColors {
  primary = 'primary',
  secondary = 'secondary',
}

export enum ButtonIcons {
  arrowLeft = 'arrow-left',
  arrowRight = 'arrow-right',
  upload = 'upload',
  download = 'download',
  warning = 'warning',
  arrowDown = 'arrow-down',
  arrowUp = 'arrow-up',
  tick = 'tick',
  plus = 'plus',
  grid = 'grid',
  list = 'list',
  edit = 'edit',
  search = 'search',
}

export enum ButtonSizes {
  normal = '',
  big = 'big',
}

export enum ButtonThemes {
  none = 'none',
  inverse = 'inverse',
  monochrome = 'mono',
  basic = 'basic',
}

export enum ButtonTypes {
  button = 'button',
  submit = 'submit',
  reset = 'reset',
}
