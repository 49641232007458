import React, { FunctionComponent, useState } from 'react';

import Typography, { TypographyVariants } from '../../components/Typography';
import { HelpFaq } from './components/HelpFaq';
import SearchInput from '../../components/SearchInput';
import { PageSearchQueryConfig } from '../../shared/constants';

export const Help: FunctionComponent = () => {
  const [searchTerm, setSearchTerm] = useState<string | undefined>('');

  const handleSearchChange = (searchData: PageSearchQueryConfig): void => {
    const searchString = searchData.search ? searchData.search.trim() : '';
    setSearchTerm(searchString);
  };

  return (
    <>
      <div className="mba-margin-y-20">
        <div className="mba-heading--wrapper">
          <Typography component="p" variant={TypographyVariants.h1} className="mba-heading--title">
            FAQs
          </Typography>
          <SearchInput
            id="faq-search"
            pageConfig={{}}
            placeholder="Search by keyword"
            className="faq-search"
            onSearchChanged={handleSearchChange}
          />
        </div>
        <div className="mba-background--white mba-no-padding mba-padding-x-28">
          <HelpFaq searchTerm={searchTerm} />
        </div>
      </div>
      <div className="mba-margin-y-20">
        <div className="mba-heading--wrapper">
          <Typography component="p" variant={TypographyVariants.h1} className="mba-heading--title">
            Contacts
          </Typography>
        </div>
        <div className="mba-background--white">
          <h4 className="mba-font--20">
            For any other issues or questions feel free to contact us at the following email:
          </h4>
          <a href="mailto:business.education@ft.com" target="_blank" rel="noopener noreferrer">
            business.education@ft.com
          </a>
        </div>
      </div>
    </>
  );
};
