import { UpdateLead } from '../Leads/components/Leads.constants';
import { LeadsDTO } from '../Leads/leads.dto';

export const SET_LEAD = 'SET_LEAD';
export const CLEAR_CURRENT_LEAD = 'CLEAR_CURRENT_LEAD';
export const EDIT_LEAD = 'EDIT_LEAD';
export const LOAD_LEAD = 'LOAD_LEAD';

export type LeadProfileState = LeadsDTO;

export interface SetLeadProfileAction {
  type: typeof SET_LEAD;
  payload: { data: LeadsDTO };
}

export interface EditLeadProfileAction {
  type: typeof EDIT_LEAD;
  payload: { data: UpdateLead };
}

export interface LoadLeadProfileAction {
  type: typeof LOAD_LEAD;
  payload: { id: number };
}

export interface ClearCurrentLeadAction {
  type: typeof CLEAR_CURRENT_LEAD;
}

export type LeadActionTypes =
  | SetLeadProfileAction
  | ClearCurrentLeadAction
  | EditLeadProfileAction
  | LoadLeadProfileAction;
