import React, { FunctionComponent } from 'react';
import { Link, NavLink } from 'react-router-dom';

import { PageConfiguration } from '../../PortalLayout.constants';

import styles from './SecondaryNavigation.module.scss';

type SecondaryNavigationProps = PageConfiguration & {
  pageName: string;
};

const SecondaryNavigation: FunctionComponent<SecondaryNavigationProps> = ({ pageName, label, navigation, route }) => {
  const navigationStyles = [styles.secondaryNavigation, 'o-header-services__secondary-nav'];

  return (
    <nav className={navigationStyles.join(' ')} aria-label="secondary" data-o-header-services-nav={pageName}>
      <div className="o-header-services__secondary-nav-content" data-o-header-services-nav-list={pageName}>
        <ol
          className="o-header-services__secondary-nav-list o-header-services__secondary-nav-list--ancestors"
          aria-label={label}
        >
          <li>
            <Link to={route}>{label}</Link>
          </li>
        </ol>
        <ul
          className="o-header-services__secondary-nav-list o-header-services__secondary-nav-list--children"
          aria-label={`${label} sections`}
        >
          {navigation &&
            Object.entries(navigation).map(([key, { label, route: subRoute }]) => (
              <li key={key}>
                <NavLink exact={route === '/'} to={`${route}${subRoute}`}>
                  {label}
                </NavLink>
              </li>
            ))}
        </ul>
      </div>
      <button
        className="o-header-services__scroll-button o-header-services__scroll-button--left"
        title="scroll left"
        aria-hidden="true"
        disabled={true}
      />
      <button
        className="o-header-services__scroll-button o-header-services__scroll-button--right"
        title="scroll right"
        aria-hidden="true"
        disabled={true}
      />
    </nav>
  );
};

export default SecondaryNavigation;
