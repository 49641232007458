import { FormConfig } from '../../../components/Form';

export const SCHOOL_TYPE_SINGLE = {
  label: 'Single',
  key: 1,
};

export const SCHOOL_TYPE_JOINED = {
  label: 'Joined',
  key: 2,
};

export const SCHOOL_PROFILE_CONFIG: FormConfig = {
  name: {
    fieldType: 'text',
    placeholder: `Enter school's name`,
    required: true,
    title: 'School name',
    validations: {
      required: {
        errorText: `Please enter a name for the school!`,
      },
    },
  },
  email: {
    fieldType: 'email-phone',
    title: 'E-mail',
    placeholder: `Enter school's main e-mail address`,
  },
  phone: {
    fieldType: 'email-phone',
    title: 'Phone',
    placeholder: `Enter phone number`,
  },
  website: {
    fieldType: 'text',
    title: 'Website',
    placeholder: `Enter school's website URL`,
  },
  address: {
    fieldType: 'textarea',
    title: 'Address',
    placeholder: `Enter school's main address`,
  },
};
