import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { acceptTermsAction, logOutUserAction, selectAreTermsAccepted, selectAuthUser } from '../App/Auth';
import { loadTermsAction } from './terms.actions';
import { selectTermsAndConditions } from './terms.selectors';
import Button, { ButtonSizes } from '../../components/Button';
import Typography, { TypographyVariants } from '../../components/Typography';
import { getHtmlFromJSON } from '../../shared/helpers';

import styles from './TermsAndConditions.module.scss';

const TermsAndConditions: FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentUser = useSelector(selectAuthUser);
  const areTermsAccepted = useSelector(selectAreTermsAccepted);
  const termsAndConditions = useSelector(selectTermsAndConditions);
  const { schoolId } = currentUser;

  useEffect(() => {
    dispatch(loadTermsAction());
  }, [dispatch]);

  const handleTermsAccept = (): void => {
    dispatch(acceptTermsAction(schoolId));
  };

  const handleTermsReject = (): void => {
    dispatch(logOutUserAction());
  };

  const handleDashboardRedirect = (): void => {
    history.push('/');
  };

  const __html = termsAndConditions.description ? getHtmlFromJSON(termsAndConditions.description) : '-';

  return (
    <>
      <Typography component="h1" variant={TypographyVariants.h1}>
        Terms and Conditions
      </Typography>
      <div className={styles.container}>
        <div dangerouslySetInnerHTML={{ __html }} />
        <div className={`mba-actions ${styles.actionButtons}`}>
          {areTermsAccepted ? (
            <Button text="Go to dashboard" size={ButtonSizes.big} primary onClick={handleDashboardRedirect} />
          ) : (
            <>
              <Button text="Log out" size={ButtonSizes.big} onClick={handleTermsReject} />
              <Button text="Accept" size={ButtonSizes.big} primary onClick={handleTermsAccept} />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
