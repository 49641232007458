import { call, CallEffect, put, PutEffect, select, SelectEffect, takeLatest } from 'redux-saga/effects';
//import { SchoolUserRole, selectCurrentSchool } from '../App/Auth';
import { BasicResponseDTO, PagedResponseDTO } from '../../shared/constants';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../../shared/state/global-request';
import {
  DownloadSpreadsheetAction,
  DOWNLOAD_LEADS,
  LeadsActionTypes,
  LoadLeadsAction,
  LOAD_LEADS,
} from './leads.types';
import { setLeadsAction } from './leads.actions';
import { downloadLeadsSpreadsheetRequest, getLeadsRequest } from './leads.api';
import { LeadsDTO } from './leads.dto';
import { SchoolUserRole, selectCurrentSchool } from '../App/Auth';

function* loadLeadsTableData(
  action: LoadLeadsAction,
): Generator<
  | CallEffect<PagedResponseDTO<LeadsDTO[]>>
  | PutEffect<LeadsActionTypes>
  | PutEffect<GlobalRequestActions>
  | SelectEffect
> {
  const { type: actionType } = action;
  const { schoolId } = (yield select(selectCurrentSchool)) as SchoolUserRole;

  try {
    yield put(setRequestStartedAction(actionType));
    const { pageConfig } = action.payload;
    const { data, pagination, sortOrder } = (yield call(getLeadsRequest, schoolId, pageConfig)) as PagedResponseDTO<
      LeadsDTO[]
    >;
    yield put(setLeadsAction({ data, pagination, sortOrder }));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* downloadLeadsSpreadsheet(
  action: DownloadSpreadsheetAction,
): Generator<
  CallEffect<BasicResponseDTO> | PutEffect<LeadsActionTypes> | PutEffect<GlobalRequestActions> | SelectEffect
> {
  const { type: actionType } = action;
  const { schoolId } = (yield select(selectCurrentSchool)) as SchoolUserRole;

  try {
    yield put(setRequestStartedAction(actionType));
    const { pageConfig, selectedData } = action.payload;
    yield call(downloadLeadsSpreadsheetRequest, pageConfig, selectedData, schoolId);
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

export default function* leadsTableDataSaga(): Generator {
  yield takeLatest(LOAD_LEADS, loadLeadsTableData);
  yield takeLatest(DOWNLOAD_LEADS, downloadLeadsSpreadsheet);
}
