import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import EditProfile from '../EditProfile';
import { SchoolUserDTO, selectAuthUser } from '../../App/Auth';
import Button, { ButtonSizes } from '../../../components/Button';
import Grid from '../../../components/Grid';
import InputWrapper from '../../../components/InputWrapper';
import Modal from '../../../components/Modal';
import Typography, { TypographyVariants } from '../../../components/Typography';

const PersonalDetails: React.FunctionComponent = () => {
  const [showEditProfile, setShowEditProfile] = useState(false);
  const userData = useSelector(selectAuthUser) as SchoolUserDTO;
  const { email, name, phone } = userData;

  useEffect(() => {
    setShowEditProfile(false);
  }, [userData]);

  return (
    <>
      <div className="mba-heading--wrapper mba-heading--table">
        <Typography component="h3" variant={TypographyVariants.h3}>
          Personal & contact details
        </Typography>
        <Button primary text="Edit info" size={ButtonSizes.big} onClick={(): void => setShowEditProfile(true)} />
      </div>
      <Grid container>
        <Grid item>
          <InputWrapper title="Name">{name || '-'}</InputWrapper>
        </Grid>
        <Grid item>
          <InputWrapper title="E-mail">{email || '-'}</InputWrapper>
        </Grid>
        <Grid item>
          <InputWrapper title="Phone">{phone || '-'}</InputWrapper>
        </Grid>
      </Grid>
      <Modal title="Edit info" isOpen={showEditProfile} onClose={(): void => setShowEditProfile(false)}>
        <EditProfile />
      </Modal>
    </>
  );
};

export default PersonalDetails;
