import { EditSchoolDTO, EditSchoolLogoDTO, SchoolDTO } from './school-profile.dto';
import { get, put } from '../../shared/api';
import { BasicResponseDTO, DefaultResponseDTO } from '../../shared/constants';

export const getSchoolRequest = (schoolId: number): Promise<DefaultResponseDTO<SchoolDTO>> => {
  return get<DefaultResponseDTO<SchoolDTO>>(`/schools/${schoolId}`);
};

export const updateSchoolRequest = (schoolData: EditSchoolDTO): Promise<BasicResponseDTO> => {
  const { id } = schoolData;
  return put<BasicResponseDTO, EditSchoolDTO>(`/schools/${id}`, schoolData);
};

export const updateSchoolAccreditations = (schoolId: number, accreditationIds: number[]): Promise<BasicResponseDTO> => {
  return put<BasicResponseDTO, { accreditationIds: number[] }>(`/schools/${schoolId}`, { accreditationIds });
};

export const updateSchoolLogoRequest = (data: EditSchoolLogoDTO): Promise<BasicResponseDTO> => {
  const { id, logo } = data;
  const logoData = logo ? logo : data;

  return put<BasicResponseDTO, FormData | EditSchoolLogoDTO>(`/schools/${id}`, logoData);
};
