import { call, CallEffect, put, PutEffect, takeEvery, takeLatest } from 'redux-saga/effects';

import { setCampusesAction } from './campuses.actions';
import { editDefaultCampusRequest, getCampusesRequest } from './campuses.api';
import {
  CampusesActionTypes,
  CHANGE_DEFAULT_CAMPUS,
  ChangeDefaultCampusAction,
  LOAD_CAMPUSES,
  LoadCampusesAction,
} from './campuses.types';
import { CampusDTO } from '../CampusProfile';
import { loadSchoolAction } from '../../school-profile.actions';
import { SchoolActionTypes } from '../../school-profile.types';
import { BasicResponseDTO, PagedResponseDTO } from '../../../../shared/constants';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../../../../shared/state/global-request';

function* loadCampusesTableData(
  action: LoadCampusesAction,
): Generator<
  CallEffect<PagedResponseDTO<CampusDTO[]>> | PutEffect<CampusesActionTypes> | PutEffect<GlobalRequestActions>
> {
  const { type: actionType } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { pageConfig, schoolId } = action.payload;
    const { data, pagination, sortOrder } = (yield call(getCampusesRequest, schoolId, pageConfig)) as PagedResponseDTO<
      CampusDTO[]
    >;
    yield put(setCampusesAction({ data, pagination, sortOrder }));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* changeDefaultCampus(
  action: ChangeDefaultCampusAction,
): Generator<CallEffect<BasicResponseDTO> | PutEffect<SchoolActionTypes> | PutEffect<GlobalRequestActions>> {
  const {
    type: actionType,
    payload: { campusId, schoolId },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(editDefaultCampusRequest, schoolId, campusId);
    yield put(loadSchoolAction(schoolId));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

export default function* campusesSaga(): Generator {
  yield takeEvery(CHANGE_DEFAULT_CAMPUS, changeDefaultCampus);
  yield takeLatest(LOAD_CAMPUSES, loadCampusesTableData);
}
