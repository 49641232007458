import { CountryDTO } from './countries.dto';
import { LoadCountriesAction, LOAD_COUNTRIES, SetCountriesAction, SET_COUNTRIES } from './countries.types';
export const loadCountriesAction = (country: string): LoadCountriesAction => ({
  type: LOAD_COUNTRIES,
  payload: { country },
});
export const setCountriesAction = (data: CountryDTO[]): SetCountriesAction => ({
  type: SET_COUNTRIES,
  payload: data,
});
