import { RootState } from '../../store/reducers';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';
import { RankingDTO } from './ranking-profile.dto';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const selectCurrentRanking = (state: RootState): RankingDTO => state.rankingProfile;

export const selectCurrentTimelineSteps = createDeepEqualSelector(selectCurrentRanking, (rankingProfile) => {
  return rankingProfile.timeline || [];
});
