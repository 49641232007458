import { FormConfig, FormControlFieldType } from '../../../../../components/Form';
import { ColumnDefinition, TableHeaderColumnTypes } from '../../../../../components/Table';

export const NUMBER_OF_CAMPUSES_PER_PAGE = 5;

export const CAMPUS_CREATE_FORM_CONFIG: FormConfig = {
  name: {
    label: 'Name',
    placeholder: 'Enter campus name',
    fieldType: 'text',
    validations: {
      required: {
        errorText: `Please enter the campus name!`,
      },
    },
  },
  address: {
    label: 'Address',
    placeholder: 'Enter campus address',
    fieldType: 'textarea',
  },
  contact: {
    label: 'Contact name',
    placeholder: 'Enter contact details',
    fieldType: 'text',
  },
  email: {
    label: 'E-mail',
    placeholder: 'Enter e-mail address',
    fieldType: 'text',
    validations: {
      regex: {
        errorText: 'Please enter a valid e-mail address!',
        rule: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
      },
    },
  },
  phone: {
    label: 'Phone',
    placeholder: `Enter phone number`,
    fieldType: 'text',
  },
};

export const CAMPUS_EDIT_FORM_CONFIG: FormConfig = {
  ...CAMPUS_CREATE_FORM_CONFIG,
  status: {
    label: 'Status',
    placeholder: `Select the campus status`,
    fieldType: 'select' as FormControlFieldType,
    options: [],
    required: true,
  },
};

export const CAMPUSES_TABLE_DEFAULT_CAMPUS_COLUMN_DEFINITION: ColumnDefinition = {
  id: 'defaultCampusId',
  title: 'Def.',
  tooltip: 'Default campus location',
  type: TableHeaderColumnTypes.radio,
  width: '60px',
};

export const CAMPUSES_TABLE_STATUS_COLUMN_DEFINITION: ColumnDefinition = {
  id: 'status',
  title: 'Status',
  type: TableHeaderColumnTypes.status,
  sorting: true,
  width: '95px',
};

export const CAMPUSES_TABLE_NAME_COLUMN_DEFINITION: ColumnDefinition = {
  id: 'name',
  title: 'Name',
  sorting: true,
  width: '300px',
};

export const CAMPUSES_TABLE_ADDRESS_COLUMN_DEFINITION: ColumnDefinition = {
  id: 'address',
  title: 'Address',
  sorting: true,
};
