import {
  ParticipationStatusDTO,
  RankingSpreadsheetFileDTO,
  SurveyLinkDTO,
  SurveyResponseRateDTO,
} from './ranking-timeline.dto';
import { ParticipationStatus } from './RankingTimeline.constants';
import { get, post, put } from '../../../shared/api';
import { BasicResponseDTO } from '../../../shared/constants';

export const downloadSpreadsheetTemplateRequest = (rankingId: number, type: string): Promise<BasicResponseDTO> => {
  return get<BasicResponseDTO>(`/rankings/${rankingId}/spreadsheets/templates/${type}`, {
    responseType: 'blob',
  });
};

export const downloadSpreadsheetRequest = (
  rankingId: number,
  schoolId: number,
  type: string,
): Promise<BasicResponseDTO> => {
  return get<BasicResponseDTO>(
    `/rankings/${rankingId}/schools/spreadsheets/download?filter[schoolId]=${schoolId}&type=${type}`,
    {
      responseType: 'blob',
    },
  );
};

export const updateRankingParticipationStatusRequest = (
  rankingId: number,
  schoolId: number,
  status: ParticipationStatus,
): Promise<BasicResponseDTO> => {
  return put<BasicResponseDTO, ParticipationStatusDTO>(`rankings/${rankingId}/schools/${schoolId}/participation`, {
    participationStatus: status,
  });
};

export const uploadSpreadsheetRequest = (data: RankingSpreadsheetFileDTO): Promise<BasicResponseDTO> => {
  const { rankingId, schoolId, spreadsheetType, spreadsheet } = data;
  const spreadsheetData = spreadsheet ? spreadsheet : data;

  return post<BasicResponseDTO, FormData | RankingSpreadsheetFileDTO>(
    `/rankings/${rankingId}/schools/${schoolId}/spreadsheets?type=${spreadsheetType}`,
    spreadsheetData,
  );
};

export const downloadTimelineSpreadsheetRequest = (rankingId: number, type: string): Promise<BasicResponseDTO> => {
  return get<BasicResponseDTO>(`/rankings/${rankingId}/timeline/${type}/spreadsheet`, {
    responseType: 'blob',
  });
};

export const requestRankingParticipationRequest = (rankingId: number, schoolId: number): Promise<BasicResponseDTO> => {
  return post<BasicResponseDTO, ParticipationStatusDTO>(`rankings/${rankingId}/schools/${schoolId}/participation`);
};

export const downloadAttachmentRequest = (rankingId: number, stepId: number): Promise<Blob> => {
  return get<Blob>(`/rankings/${rankingId}/timeline/${stepId}/attachment`, {
    responseType: 'blob',
  });
};

export const getSurveyResponseRateRequest = (rankingId: number, schoolId: number): Promise<SurveyResponseRateDTO> => {
  return get<SurveyResponseRateDTO>(`/school-survey/${rankingId}/${schoolId}/response-rate`);
};

export const getSurveyLinkRequest = (rankingId: number, schoolId: number): Promise<SurveyLinkDTO> => {
  return get<SurveyLinkDTO>(`/school-survey/${rankingId}/${schoolId}/link`);
};
