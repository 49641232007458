import jwtDecode from 'jwt-decode';

import { SchoolUserDTO, SchoolUserRole } from './auth.dto';
import {
  AuthActionTypes,
  AuthState,
  CHANGE_SCHOOL_ACCOUNT,
  LOG_OUT_USER,
  SET_TERMS_STATUS,
  SET_USER_TOKEN,
} from './auth.types';

const initialState: AuthState = {
  areTermsAccepted: null,
  currentSchool: {} as SchoolUserRole,
  data: {} as SchoolUserDTO,
  isTokenVerified: false,
  token: '',
  timezone: '',
};

export default (state = initialState, action: AuthActionTypes): AuthState => {
  switch (action.type) {
    case SET_USER_TOKEN: {
      const decodedPayload = jwtDecode(action.payload.token) as AuthState;

      return {
        ...state,
        isTokenVerified: true,
        token: action.payload.token,
        data: decodedPayload.data,
      };
    }
    case CHANGE_SCHOOL_ACCOUNT: {
      return {
        ...state,
        currentSchool: action.payload,
      };
    }
    case SET_TERMS_STATUS: {
      return {
        ...state,
        areTermsAccepted: action.payload.status,
      };
    }
    case LOG_OUT_USER: {
      return {
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
};
