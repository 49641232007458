import React, { FunctionComponent } from 'react';

import { ButtonColors, ButtonIcons, ButtonSizes, ButtonThemes, ButtonTypes } from './Button.constants';
import LoadingSpinner from '../LoadingSpinner';

import styles from './Button.module.scss';

type ButtonProps = {
  disabled?: boolean;
  fullWidth?: boolean;
  icon?: ButtonIcons;
  isLoading?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  primary?: boolean;
  danger?: boolean;
  size?: ButtonSizes;
  text?: string;
  theme?: ButtonThemes;
  type?: ButtonTypes;
  iconPosition?: 'left' | 'right';
  wrapperClass?: string;
};

const Button: FunctionComponent<ButtonProps> = ({
  danger = false,
  disabled = false,
  fullWidth = false,
  icon,
  isLoading = false,
  onClick,
  primary = false,
  size,
  text = '',
  theme = ButtonThemes.none,
  type = ButtonTypes.button,
  iconPosition = 'left',
  wrapperClass,
}) => {
  const classes = ['o-buttons', styles.button];
  const wrapperClasses = [styles.buttonWrapper];
  const loaderClasses = [styles.loader];

  if (primary) {
    classes.push(`o-buttons--${ButtonColors.primary}`);
  } else {
    classes.push(`o-buttons--${ButtonColors.secondary}`);
    loaderClasses.push(styles.loaderGreen);
  }

  if (danger) {
    classes.push(primary ? styles.dangerPrimary : styles.dangerOutlined);
  }

  if (size === ButtonSizes.big) {
    classes.push('o-buttons--big');
    loaderClasses.push(styles.big);
  }

  if (theme !== ButtonThemes.none) {
    classes.push(`o-buttons--${theme}`);
  }

  if (icon) {
    classes.push(`o-buttons-icon o-buttons-icon--${icon}`);
    if (iconPosition === 'right') {
      classes.push(size === ButtonSizes.big ? styles.rightIconBig : styles.rightIcon);
    }
  }

  if (isLoading) {
    wrapperClasses.push(styles.loading);
  }

  if (fullWidth) {
    wrapperClasses.push(styles.fullWidth);
  }

  if (wrapperClass) {
    wrapperClasses.push(wrapperClass);
  }

  return (
    <div className={wrapperClasses.join(' ')}>
      <button className={classes.join(' ')} disabled={isLoading || disabled} type={type} onClick={onClick}>
        <span className={styles.buttonText}>{text}</span>
      </button>
      {isLoading && (
        <LoadingSpinner
          className={loaderClasses.join(' ')}
          theme={primary ? 'light' : 'dark'}
          size={size === ButtonSizes.big ? 'small' : 'mini'}
        />
      )}
    </div>
  );
};

export default Button;
