import { combineReducers } from 'redux';

import { usersReducer, singleUserReducer } from '../containers/Admin/Users';
import { authReducer } from '../containers/App/Auth';
import { rankingProfileReducer } from '../containers/RankingProfile';
import { rankingsHistoryReducer } from '../containers/RankingsHistory';
import { attachmentsReducer } from '../shared/state/attachments';
import { currentRequestReducer } from '../shared/state/global-request';
import { resourcesReducer } from '../shared/state/resources';
import { rolesReducer } from '../shared/state/roles';
import { statusesReducer } from '../shared/state/statuses';
import { schoolProfileReducer } from '../containers/SchoolProfile';
import { campusesReducer } from '../containers/SchoolProfile/components/CampusesList';
import { campusProfileReducer } from '../containers/SchoolProfile/components/CampusProfile';
import { termsReducer } from '../containers/TermsAndConditions';
import { dashboardsResucer } from '../containers/Home/components/ActiveRankings';
import { privacyReducer } from '../containers/App/Main/components/PrivacyPolicy';
import { faqReducer } from '../containers/Help';
import { leadsReducer } from '../containers/Leads';
import { countriesReducer } from '../shared/state/countries';
import leadProfileReducer from '../containers/LeadProfile/lead-profile.reducer';

export const rootReducer = combineReducers({
  attachments: attachmentsReducer,
  auth: authReducer,
  currentRequest: currentRequestReducer,
  rankingProfile: rankingProfileReducer,
  rankings: rankingsHistoryReducer,
  resources: resourcesReducer,
  roles: rolesReducer,
  statuses: statusesReducer,
  users: usersReducer,
  user: singleUserReducer,
  terms: termsReducer,
  privacy: privacyReducer,
  faq: faqReducer,
  schoolProfile: schoolProfileReducer,
  campuses: campusesReducer,
  campusProfile: campusProfileReducer,
  dashboard: dashboardsResucer,
  leads: leadsReducer,
  countries: countriesReducer,
  leadProfile: leadProfileReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
