import React, { FunctionComponent } from 'react';

import Button, { ButtonSizes } from '../../../../components/Button';
import Typography from '../../../../components/Typography';

type UserDeleteConfirmationProps = {
  error: string;
  id: number;
  name: string;
  onCancel: () => void;
  onConfirm: () => void;
  action?: string;
};

const UserDeleteConfirmation: FunctionComponent<UserDeleteConfirmationProps> = ({
  error,
  id,
  name,
  onCancel,
  onConfirm,
  action = 'delete',
}) => (
  <>
    <Typography>
      {error ? (
        <span className="mba-text--error">
          {error}
          <br />
        </span>
      ) : (
        <>
          {`Are you sure you want to ${action} the following user:`}
          <br />
          <strong>&quot;{name}&quot;</strong>
        </>
      )}
    </Typography>
    <div className="mba-actions">
      <Button onClick={onCancel} danger text="Cancel" size={ButtonSizes.big} />
      {!error && <Button onClick={onConfirm} primary danger text="Confirm" size={ButtonSizes.big} />}
    </div>
  </>
);

export default UserDeleteConfirmation;
