import React, { ReactNode } from 'react';

import { CheckboxInputOption } from '../Checkbox';
import { FormControl, FormControlElementProps, FormValue } from '../Form';
import {
  renderCheckboxField,
  renderDatePickerInputField,
  renderEmailPhoneInputField,
  renderFormGroupTitle,
  renderRadioButtons,
  renderSelectField,
  renderTextAreaField,
  renderTextInputField,
} from '../Form/FormFields';
import InputWrapper from '../InputWrapper';
import { SelectOption } from '../Select';
import { formatDateString } from '../../shared/helpers';
import { TextInputTypes } from '../TextInput';

export interface ViewInputProps extends FormControl {
  isEdit: boolean;
  onChange: (name: string, value: FormValue) => void;
  id?: string;
}

const ViewInput: React.FunctionComponent<ViewInputProps> = ({
  id,
  fieldType,
  isEdit,
  onChange,
  options,
  previewFormat,
  title,
  value,
  name,
  ...control
}) => {
  const currentControl = {
    ...control,
    fieldType,
    label: title,
    options,
    value,
    name,
  } as FormControlElementProps;

  const getSelectLabel = (value?: FormValue): string | ReactNode => {
    let label = value || '-';
    if (options && options.length > 0) {
      const selectedOption = options.find((option) => option.value === value);
      if (selectedOption) {
        label = (selectedOption as SelectOption).label || (selectedOption as CheckboxInputOption).text || '-';
      }
    }

    if (previewFormat && typeof value === 'string') {
      label = formatDateString(value, previewFormat) || '-';
    }

    if (fieldType === TextInputTypes.emailPhone) {
      const values = currentControl.value ? currentControl.value.toString().split(',') : [];
      let __html = '-';
      if (values.length > 0) {
        __html = '';
        values.forEach((item) => (__html += `<span>${item}</span><br>`));
      }
      return <div dangerouslySetInnerHTML={{ __html }} />;
    }

    return label.toString();
  };

  let field: ReactNode;

  switch (fieldType) {
    case 'select':
      field = renderSelectField({ control: currentControl, inputChangedHandler: onChange });
      break;
    case 'text':
    case 'email':
      field = renderTextInputField({ control: currentControl, inputChangedHandler: onChange });
      break;
    case 'textarea':
      field = renderTextAreaField({ control: currentControl, inputChangedHandler: onChange });
      break;
    case 'formGroupTitle':
      field = renderFormGroupTitle({ control: currentControl });
      break;
    case 'radio':
      field = renderRadioButtons({ control: currentControl, inputChangedHandler: onChange });
      break;
    case 'checkbox':
      field = renderCheckboxField({ control: currentControl, inputChangedHandler: onChange });
      break;
    case 'date':
      field = renderDatePickerInputField({ control: currentControl, inputChangedHandler: onChange });
      break;
    case 'email-phone':
      field = renderEmailPhoneInputField({ control: currentControl, inputChangedHandler: onChange });
      break;
    default:
      field = null;
  }

  return isEdit ? (
    <div>{field}</div>
  ) : (
    <InputWrapper id={id} title={title}>
      {getSelectLabel(value)}
    </InputWrapper>
  );
};

export default ViewInput;
