import { FormConfig } from '../../components/Form/Form.constants';
import { Answer } from './leads.dto';

type Item = 'Years of managerial experience' | 'Programme of interest' | 'Specify your role';

type UserAnswers = {
  [k in Item]?: string | number | string[];
};

const convertDate = (date: string): string => {
  return date.split('T')[0];
};

const getUserAnswers = (answers: Answer[]): UserAnswers => {
  const interestProgramValues: string[] = [];
  const getAnswers = answers.reduce((curr, answer) => {
    // eslint-disable-next-line prefer-const
    let { question: { title = '' } = {}, value } = answer;
    if (title === 'Programme of interest') {
      interestProgramValues.push(value);
    }
    if (title === 'Specify your role') {
      title = 'Specify role';
    }
    return { ...curr, [title]: value };
  }, {});
  return { ...getAnswers, 'Programme of interest': interestProgramValues.join(', ') };
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const convertToLeadProfile = (currentLead: any = {}): any => {
  const { user = {} } = currentLead;
  const { role = {}, answers = [], country = {}, nationality = {} } = user;
  return {
    status: currentLead.status?.label,
    email: user.email,
    name: `${user.firstName} ${user.lastName}`,
    country: country.name,
    createdAt: convertDate(user.createdAt ?? ''),
    nationality: nationality.name,
    role: role.name,
    ...getUserAnswers(answers),
  };
};

export const modifiedConfig = (answers: Answer[]): FormConfig => {
  return answers.reduce((curr, answer) => {
    let { question: { title = '' } = {} } = answer;
    if (title === 'Specify your role') {
      title = 'Specify role';
    }
    return { ...curr, [title]: { title } };
  }, {});
};
