import { HelpFAQDto } from './help.dto';

export const LOAD_FAQ_DATA = 'LOAD_FAQ_DATA';
export const SET_FAQ_DATA = 'SET_FAQ_DATA';

export interface LoadFAQAction {
  type: typeof LOAD_FAQ_DATA;
}

export interface SetFAQAction {
  type: typeof SET_FAQ_DATA;
  payload: HelpFAQDto[];
}

export type FAQActionTypes = LoadFAQAction | SetFAQAction;
