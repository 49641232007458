import { call, CallEffect, put, PutEffect, select, SelectEffect, takeEvery } from 'redux-saga/effects';

import {
  setRankingAction,
  setRankingInstructionsAction,
  setRankingParticipationCriteriaAction,
  setRankingTimelineAction,
} from './ranking-profile.actions';
import {
  getRankingInstructionsRequest,
  getRankingParticipationCriteriaRequest,
  getRankingRequest,
  getRankingTimelineStepsRequest,
} from './ranking-profile.api';
import { RankingDTO, RankingTimelineStepDTO } from './ranking-profile.dto';
import {
  LOAD_RANKING,
  LOAD_RANKING_INSTRUCTIONS,
  LOAD_RANKING_PARTICIPATION_CRITERIA,
  LOAD_RANKING_TIMELINE,
  LoadRankingAction,
  LoadRankingInstructionsAction,
  LoadRankingParticipationCriteriaAction,
  LoadRankingTimelineAction,
  RankingActionTypes,
} from './ranking-profile.types';
import { selectCurrentSchool } from '../App/Auth';
import { SchoolUserRole } from '../App/Auth';
import { DefaultResponseDTO } from '../../shared/constants';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../../shared/state/global-request';

function* loadRankingSaga(
  action: LoadRankingAction,
): Generator<CallEffect<DefaultResponseDTO<RankingDTO>> | PutEffect<GlobalRequestActions | RankingActionTypes>> {
  const {
    type: actionType,
    payload: { id },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { data } = (yield call(getRankingRequest, id)) as DefaultResponseDTO<RankingDTO>;
    yield put(setRankingAction(data));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* loadRankingParticipationCriteriaSaga(
  action: LoadRankingParticipationCriteriaAction,
): Generator<CallEffect<DefaultResponseDTO<string>> | PutEffect<GlobalRequestActions | RankingActionTypes>> {
  const {
    type: actionType,
    payload: { id },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { data } = (yield call(getRankingParticipationCriteriaRequest, id)) as DefaultResponseDTO<string>;
    yield put(setRankingParticipationCriteriaAction(data));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* loadRankingInstructionsSaga(
  action: LoadRankingInstructionsAction,
): Generator<CallEffect<DefaultResponseDTO<string>> | PutEffect<GlobalRequestActions | RankingActionTypes>> {
  const {
    type: actionType,
    payload: { id },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { data } = (yield call(getRankingInstructionsRequest, id)) as DefaultResponseDTO<string>;
    yield put(setRankingInstructionsAction(data));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* loadRankingTimelineStepsSaga(
  action: LoadRankingTimelineAction,
): Generator<
  | CallEffect<DefaultResponseDTO<RankingTimelineStepDTO[]>>
  | PutEffect<GlobalRequestActions | RankingActionTypes>
  | SelectEffect
> {
  const {
    type: actionType,
    payload: { id },
  } = action;
  const { schoolId } = (yield select(selectCurrentSchool)) as SchoolUserRole;

  try {
    yield put(setRequestStartedAction(actionType));
    const { data } = (yield call(getRankingTimelineStepsRequest, schoolId, id)) as DefaultResponseDTO<
      RankingTimelineStepDTO[]
    >;
    yield put(setRankingTimelineAction(data));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

export default function* rankingProfileSaga(): Generator {
  yield takeEvery(LOAD_RANKING, loadRankingSaga);
  yield takeEvery(LOAD_RANKING_PARTICIPATION_CRITERIA, loadRankingParticipationCriteriaSaga);
  yield takeEvery(LOAD_RANKING_INSTRUCTIONS, loadRankingInstructionsSaga);
  yield takeEvery(LOAD_RANKING_TIMELINE, loadRankingTimelineStepsSaga);
}
