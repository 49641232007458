import React, { FunctionComponent } from 'react';

import { TextInputProps, TextInputTypes } from './TextInput.constants';
import InputWrapper from '../InputWrapper';

const TextInput: FunctionComponent<TextInputProps> = ({
  disabled = false,
  error,
  id,
  name,
  onBlur,
  onChange,
  optional = false,
  placeholder,
  required = false,
  shrunken = false,
  small = false,
  subTitle,
  suffix,
  title,
  touched,
  type = TextInputTypes.text,
  valid,
  value,
  verticallyCentered,
  ...others
}) => {
  const inputClasses = ['o-forms-input', 'o-forms-input--text'];

  if (error || (touched && !valid)) {
    inputClasses.push('o-forms-input--invalid');
  } else if (touched && valid) {
    inputClasses.push('o-forms-input--valid');
  }

  if (small) {
    inputClasses.push('o-forms-input--small');
  }

  if (type === TextInputTypes.password) {
    inputClasses.push('o-forms-input--password');
  }

  if (suffix) {
    inputClasses.push('o-forms-input--suffix');
  }

  return (
    <InputWrapper
      id={id}
      inline={shrunken}
      optional={optional}
      required={required}
      shrunken={shrunken}
      subTitle={subTitle}
      title={title}
      verticallyCentered={verticallyCentered}
    >
      <div className={inputClasses.join(' ')}>
        <input
          id={id}
          type={type}
          name={name}
          disabled={disabled}
          required={required}
          aria-invalid={!!error}
          placeholder={placeholder || ''}
          value={value || ''}
          onChange={onChange}
          onBlur={onBlur}
          {...others}
        />
        {suffix}
        {error && <span className="o-forms-input__error">{error}</span>}
      </div>
    </InputWrapper>
  );
};

export default TextInput;
