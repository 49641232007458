import { AccountSetUpDTO, EditProfileDTO, LoginDto, SchoolUserDTO, SchoolUserRole } from './auth.dto';

export const LOGIN_USER = 'LOGIN_USER';
export const REGISTER_USER = 'REGISTER_USER';
export const LOG_OUT_USER = 'LOG_OUT_USER';
export const SET_USER_TOKEN = 'SET_USER_TOKEN';
export const VALIDATE_USER_TOKEN = 'VALIDATE_USER_TOKEN';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const CHANGE_FORGOTTEN_PASSWORD = 'CHANGE_FORGOTTEN_PASSWORD';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const EDIT_PROFILE = 'EDIT_PROFILE';
export const CHANGE_SCHOOL_ACCOUNT = 'CHANGE_SCHOOL_ACCOUNT';
export const ACCEPT_TERMS = 'ACCEPT_TERMS';
export const SET_TERMS_STATUS = 'SET_TERMS_STATUS';
export const REFRESH_SCHOOL_USER = 'REFRESH_SCHOOL_USER';

export interface AuthState {
  areTermsAccepted: null | boolean;
  isTokenVerified: boolean;
  token: string;
  data: SchoolUserDTO;
  currentSchool: SchoolUserRole;
  timezone?: string;
}

export interface LogOutUserAction {
  type: typeof LOG_OUT_USER;
}

export interface SetUserTokenAction {
  type: typeof SET_USER_TOKEN;
  payload: { token: string };
}

export interface ValidateUserTokenAction {
  type: typeof VALIDATE_USER_TOKEN;
  payload: { token: string };
}

export interface ResetPasswordAction {
  type: typeof RESET_PASSWORD;
  payload: { email: string };
}

export interface ChangeForgottenPasswordAction {
  type: typeof CHANGE_FORGOTTEN_PASSWORD;
  payload: { password: string; token: string };
}

export interface ChangePasswordAction {
  type: typeof CHANGE_PASSWORD;
  payload: { password: string; confirmPassword: string };
}

export interface EditProfileAction {
  type: typeof EDIT_PROFILE;
  payload: EditProfileDTO;
}

export interface LoginUserAction {
  type: typeof LOGIN_USER;
  payload: LoginDto;
}

export interface RegisterUserAction {
  type: typeof REGISTER_USER;
  payload: AccountSetUpDTO;
}

export interface ChangeSchoolAccountAction {
  type: typeof CHANGE_SCHOOL_ACCOUNT;
  payload: SchoolUserRole;
}

export interface AcceptTermsForSchoolAction {
  type: typeof ACCEPT_TERMS;
  payload: {
    schoolId: number;
  };
}

export interface SetTermsStatusAction {
  type: typeof SET_TERMS_STATUS;
  payload: {
    status: boolean;
  };
}

export interface RefreshSchoolUserAction {
  type: typeof REFRESH_SCHOOL_USER;
}

export type AuthActionTypes =
  | AcceptTermsForSchoolAction
  | LogOutUserAction
  | ResetPasswordAction
  | ChangeForgottenPasswordAction
  | ChangeSchoolAccountAction
  | ChangePasswordAction
  | EditProfileAction
  | SetTermsStatusAction
  | SetUserTokenAction
  | ValidateUserTokenAction
  | LoginUserAction
  | RegisterUserAction
  | RefreshSchoolUserAction;
